import type { Contact, IIdentity } from "@somewear/model";
import {
	getColorFromString,
	getHexForStandardColor,
	isStandardColor,
	Sentry,
	SWL_COLOR_BLUE,
} from "@somewear/model";

import type { IWorkspace } from "./workspaces.slice";

export const getColorForWorkspace = (workspace: IWorkspace) => {
	const standardColor = workspace.styleSettings?.standardColor;
	if (isStandardColor(standardColor)) return getHexForStandardColor(standardColor) ?? "";
	return getColorFromString(workspace.name);
};

export const getColorForAsset = (contact: Contact, identity?: IIdentity) => {
	if (contact === undefined) {
		Sentry.captureException("getColorForAsset: contact is undefined - defaulting to blue");
		return SWL_COLOR_BLUE;
	}
	const standardColor = identity?.styleSettings?.standardColor;
	if (isStandardColor(standardColor)) {
		const color = getHexForStandardColor(standardColor);
		if (color !== undefined) return color;
	}
	return getColorFromString("SALT" + contact.name + "PEPPER");
};
