import * as SentryBrowser from "@sentry/browser";
import type { CaptureContext } from "@sentry/types";

import { Configuration } from "../config";

export const Sentry = {
	Integrations: SentryBrowser.Integrations,
	configureScope: (callback: (scope: SentryBrowser.Scope) => void): void => {
		if (!Configuration.config.sentry.enable) return;
		SentryBrowser.configureScope(callback);
	},
	withScope: (callback: (scope: SentryBrowser.Scope) => void): void => {
		if (!Configuration.config.sentry.enable) return;
		SentryBrowser.withScope(callback);
	},
	init: (options?: SentryBrowser.BrowserOptions): void => {
		if (!Configuration.config.sentry.enable) return;
		SentryBrowser.init(options);
	},
	captureException: (exception: any, captureContext?: CaptureContext): string => {
		console.error(exception);
		if (!Configuration.config.sentry.enable) return "";
		return SentryBrowser.captureException(exception, captureContext);
	},
};
