import { AbstractStore, StoreController } from "@somewear/storage";

import type { MapLayers } from "./layers.model";

class Store extends AbstractStore<MapLayers> {
	migrate() {
		// no migration needed
	}

	clean() {
		// clear anything that was previously in local storage
		localStorage.removeItem(this.key);
	}
}

export const MapLayersStore = new Store(StoreController.STORE_MAP_LAYERS);
