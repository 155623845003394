import localforage from "localforage";
import { firstValueFrom, Observable, type Subscriber } from "rxjs";
import { take } from "rxjs/operators";

// todo: the shape of stored entity should change to support arrays. (note: this will require a migration)
type StoredEntity<T> = T & {
	timestamp?: number;
};

type Store<T> = {
	read(): Promise<T | undefined>;
	write$(model: T | undefined): Observable<T | undefined>;
	remove: (subscriber: Subscriber<T | undefined>) => void;
	clean: () => void;
};

abstract class AbstractStore<T> implements Store<T> {
	protected key: string;
	protected ttlInMs?: number;
	abstract migrate(): void;
	abstract clean(): void;

	constructor(key: string, ttlInMs?: number) {
		this.key = key;
		this.ttlInMs = ttlInMs;
		this.init();
	}

	private init() {
		this.migrate();
		this.clean();
	}

	// Read the stored data.
	async read(): Promise<T | undefined> {
		const data: string | null = await localforage.getItem<string>(this.key);

		if (data === undefined || data === null) return undefined;

		const storedValue = JSON.parse(data) as StoredEntity<T>;
		return this.expire(storedValue);
	}

	private async expire(storedValue: StoredEntity<T>) {
		if (this.ttlInMs === undefined) return storedValue;

		// expire the view after one hour of inactivity
		const oneHourInMs = this.ttlInMs;
		if (storedValue.timestamp ?? 0 < Date.now() - oneHourInMs) {
			const obs$ = this.write$(undefined).pipe(take(1));
			return await firstValueFrom(obs$);
		}

		if (storedValue) {
			return storedValue;
		}

		return undefined;
	}

	// Update the stored date.
	write$(item: T | undefined): Observable<T | undefined> {
		// update the local forage store
		return new Observable((subscriber) => {
			if (item === undefined || (Array.isArray(item) && item.length === 0)) {
				this.remove(subscriber);
			} else {
				this.insert(item, subscriber);
			}
		});
	}

	private insert(item: T, subscriber: Subscriber<T | undefined>) {
		// this approach to stored entities does not support arrays
		const storedEntity = Array.isArray(item)
			? item
			: ({
					...item,
					timestamp: Date.now(),
				} as StoredEntity<T>);
		localforage
			.setItem<string>(this.key, JSON.stringify(storedEntity))
			.then((json) => {
				const entity = JSON.parse(json);
				subscriber.next(entity);
			})
			.catch((e) => {
				console.error("Unable to save entity. The uploaded entity may be too large");
				subscriber.error(e);
			})
			.finally(() => {
				subscriber.complete();
			});
	}
	remove(subscriber: Subscriber<T | undefined>) {
		localforage
			.removeItem(this.key)
			.then(() => {
				subscriber.next(undefined);
			})
			.catch((e) => {
				console.error("Unable to remove entity.");
				subscriber.error(e);
			})
			.finally(() => {
				subscriber.complete();
			});
	}
}

export { AbstractStore, type StoredEntity };
