import type { Timestamp } from "@somewear/api";
import type { FileMetadataWithState } from "@somewear/model";

import type { MessageOrFile } from "./messaging.model";

export function isFile(entity: MessageOrFile): entity is FileMetadataWithState {
	return (entity as FileMetadataWithState).createdTimestamp !== undefined;
}

export function getMessageTimestamp(entity: MessageOrFile): Timestamp.AsObject {
	return isFile(entity) ? entity.createdTimestamp! : entity.timestamp!;
}

export function getMessageSenderId(entity: MessageOrFile): string {
	return isFile(entity) ? entity.userId : entity.senderId;
}

export function messageHasTimestamp(message?: MessageOrFile): boolean {
	return Boolean(message && getMessageTimestamp(message));
}
