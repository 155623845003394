import type { IConfig } from "@somewear/model";
import { Configuration } from "@somewear/model";
import type { Observable } from "rxjs";
import { iif, of, Subject } from "rxjs";

interface IPasswordArgs {
	password: string;
}

interface IEmailArgs {
	email: string;
}

export type EmailSignInArgs = IPasswordArgs & IEmailArgs;

export type UpdatePasswordArgs = {
	newPassword: string;
	newPasswordConfirm: string;
	currentPassword: string;
};

export interface IAuthService {
	initialize: (config: IConfig) => void;
	signInWithEmailAndPassword$: (args: EmailSignInArgs) => Observable<IAuthUser | undefined>;
	createUserWithEmailAndPassword$: (args: EmailSignInArgs) => Observable<IAuthUser | undefined>;
	signInAnonymously$: () => Observable<IAuthUser | undefined>;
	sendPasswordResetEmail$: (email: string) => Observable<void>;
	sendEmailVerification$: () => Observable<void>;
	updateProfile$: (displayName: string) => Observable<void>;
	signOut$: () => Observable<void>;
	reauthenticate$: (currentPassword: string) => Observable<boolean>;
	getUserIdToken$: () => Observable<string | undefined>;
	getTokenString$: () => Observable<string | undefined>;
	getCurrentAuthUser: () => IAuthUser | undefined;
	reloadUser: () => void;
	onAuthStateChanged: (next: Subject<IAuthUser | undefined>) => () => void;
}

export class AbstractAuthController {
	constructor(service: IAuthService) {
		this._service = service;
		this.serviceSubject.next(service);
		service.initialize(Configuration.config);
	}

	private serviceSubject = new Subject<IAuthService>();
	private _service: IAuthService | undefined;

	get isServiceLoaded(): boolean {
		return this._service !== undefined;
	}

	get service$(): Observable<IAuthService> {
		return iif(() => this._service !== undefined, of(this._service!), this.serviceSubject);
	}

	get service(): IAuthService {
		if (this._service === undefined) throw Error("Service has not been loaded yet");
		return this._service;
	}

	tokenString$(omitAuth?: boolean): Observable<string | undefined> {
		return iif(() => omitAuth === true, of(""), this.service.getTokenString$());
	}
}

export interface IAuthUser {
	id: string;
	isAnonymous: boolean;
	email?: string;
	username?: string;
	displayName?: string;
	phoneNumber?: string;
}

export async function loadAuthService(config: IConfig): Promise<IAuthService> {
	// lazily execute dynamic import to load implemented auth service
	if (config.firebase.enable) {
		const util = await import("@somewear/firebase");
		return util.getFirebaseAuthService(config);
	} else {
		const util = await import(`./SomewearAuthUtil`);

		return util.SomewearAuthService;
	}
}
