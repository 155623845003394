import type { AuthState } from "./auth.state";

export const selectActiveOrganizationId = (state: AuthState) => {
	return state.auth.activeOrganizationId;
};

export const selectActiveIdentityId = (state: AuthState) => {
	return state.auth.activeIdentityId;
};

export const selectActiveUserAccountId = (state: AuthState) => {
	return state.auth.activeUserAccountId;
};

export const selectActiveWorkspaceId = (state: AuthState) => {
	return state.auth.activeWorkspaceId;
};
