import type { IAuthUser } from "./AuthUtil";
import { IdentityStorage } from "./IdentityStorage";

export class UserSource {
	private static instance = new UserSource();

	private user?: IAuthUser;
	private activeOrganizationStore = IdentityStorage.withString("activeOrganizationId");
	private activeWorkspaceStore = IdentityStorage.withString("activeWorkspaceId");

	getStoredWorkspaceId(userId: string) {
		return this.activeWorkspaceStore.getValue(userId);
	}
	getStoredOrganizationId(userId: string) {
		const organizationId = this.activeOrganizationStore.getValue(userId);
		if (organizationId === "-1") return null;
		else return organizationId;
	}
	getStoredOrganizationIdForCurrentUser() {
		if (this.user !== undefined) {
			return this.getStoredOrganizationId(this.user.id);
		}
		return undefined;
	}

	static getInstance(): UserSource {
		return this.instance;
	}

	setup(user?: IAuthUser): void {
		this.user = user;
		if (!user) return;
	}

	getCurrentUser(): IAuthUser | undefined {
		return this.user;
	}

	clear() {
		const uid = this.user?.id;
		if (uid !== undefined) {
			this.user = undefined;
			this.activeWorkspaceStore.removeValue(uid);
			this.activeOrganizationStore.removeValue(uid);
		}
	}

	updateActiveOrganization(orgId: string) {
		this.updateActiveOrganizationId(orgId);
	}

	updateActiveWorkspaceId(id: string) {
		const uid = this.user?.id;
		if (!uid) return;
		const workspaceId = id.isEmpty() ? "-1" : id;
		console.log(`updateActiveWorkspaceId: start; uid: ${uid}; workspaceId: ${workspaceId}`);
		this.activeWorkspaceStore.putValue(uid, workspaceId);
	}

	clearActiveWorkspaceId() {
		const uid = this.user?.id;
		if (!uid) return;
		this.activeWorkspaceStore.removeValue(uid);
	}

	private updateActiveOrganizationId(id: string) {
		const uid = this.user?.id;
		if (!uid) return;
		this.activeOrganizationStore.putValue(uid, id);
	}
}
