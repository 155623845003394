import type { ApiKeysState } from "@somewear/api-keys";
import { apiKeysReducerMap } from "@somewear/api-keys";
import type { AssetsState, ContactState, IdentityState } from "@somewear/asset";
import { assetReducerMap, contactReducerMap, identityReducerMap } from "@somewear/asset";
import type { AuthState } from "@somewear/auth";
import { authReducerMap } from "@somewear/auth";
import type { BiometricState } from "@somewear/biometric";
import { biometricReducerMap } from "@somewear/biometric";
import type { ClusterState } from "@somewear/cluster";
import { clusterReducerMap } from "@somewear/cluster";
import type {
	DeviceState,
	DeviceTransferState,
	QueuedDeviceNameChangesState,
} from "@somewear/device";
import {
	deviceReducerMap,
	deviceTransferReducerMap,
	queuedDeviceNameChangeReducerMap,
} from "@somewear/device";
import type { FeatureState } from "@somewear/feature";
import { featureReducerMap } from "@somewear/feature";
import type { FilesState } from "@somewear/files";
import { filesReducerMap } from "@somewear/files";
import type { LayerState } from "@somewear/layers";
import { layerReducerMap } from "@somewear/layers";
import type { MessagesState, MessagingState } from "@somewear/messaging";
import { conversationsSlice, messagesReducerMap, messagingReducerMap } from "@somewear/messaging";
import type { ModelState } from "@somewear/model";
import { modelReducerMap, resetState, Sentry } from "@somewear/model";
import type { NotificationState } from "@somewear/notification";
import { notificationReducerMap, notificationsSlice } from "@somewear/notification";
import type { OrganizationState } from "@somewear/organization";
import { organizationReducerMap } from "@somewear/organization";
import type { SensorState } from "@somewear/sensors";
import { sensorReducerMap } from "@somewear/sensors";
import type { SettingsState } from "@somewear/settings";
import { settingsReducerMap } from "@somewear/settings";
import type { ShapeState } from "@somewear/shapes";
import { shapeReducerMap } from "@somewear/shapes";
import type { SosState } from "@somewear/sos";
import { sosReducerMap } from "@somewear/sos";
import type { SubscriptionState } from "@somewear/subscription";
import { subscriptionReducerMap } from "@somewear/subscription";
import type { WaypointState } from "@somewear/waypoint";
import { waypointReducerMap } from "@somewear/waypoint";
import type { WorkspaceState } from "@somewear/workspace";
import { workspaceReducerMap, workspacesSlice } from "@somewear/workspace";
import locationSlice from "@web/tracking/locations/location.slice";
import trackingRoutesSlice from "@web/tracking/routes/trackingRoutesSlice";
import trackingReducer from "@web/tracking/trackingSlice";
import type { Action } from "redux";
import { combineReducers } from "redux";

import workspaceDeviceSlice from "../settings/workspace/workspaceDevicesSlice";
import appSlice, { setGlobalError } from "./appSlice";

const appReducerMap = {
	app: appSlice.reducer,
	tracking: trackingReducer,

	conversations: conversationsSlice.reducer,
	workspaceDevices: workspaceDeviceSlice.reducer,
	notifications: notificationsSlice.reducer,
	workspaces: workspacesSlice.reducer,
	trackingRoutes: trackingRoutesSlice.reducer,
	locations: locationSlice.reducer,
};

const appReducers = combineReducers(appReducerMap);

export type AppState = ReturnType<typeof appReducers>;

export const mainReducer = combineReducers<
	ModelState &
		ShapeState &
		AuthState &
		AppState &
		ApiKeysState &
		SosState &
		WaypointState &
		BiometricState &
		LayerState &
		OrganizationState &
		WorkspaceState &
		MessagingState &
		MessagesState &
		FilesState &
		DeviceState &
		DeviceTransferState &
		QueuedDeviceNameChangesState &
		IdentityState &
		AssetsState &
		ContactState &
		SubscriptionState &
		FeatureState &
		SettingsState &
		ClusterState &
		SensorState &
		NotificationState
>({
	...modelReducerMap,
	...appReducerMap,
	...apiKeysReducerMap,
	...shapeReducerMap,
	...authReducerMap,
	...sosReducerMap,
	...waypointReducerMap,
	...biometricReducerMap,
	...layerReducerMap,
	...organizationReducerMap,
	...workspaceReducerMap,
	...queuedDeviceNameChangeReducerMap,
	...messagingReducerMap,
	...messagesReducerMap,
	...filesReducerMap,
	...deviceReducerMap,
	...deviceTransferReducerMap,
	...queuedDeviceNameChangeReducerMap,
	...identityReducerMap,
	...assetReducerMap,
	...contactReducerMap,
	...subscriptionReducerMap,
	...featureReducerMap,
	...settingsReducerMap,
	...clusterReducerMap,
	...sensorReducerMap,
	...notificationReducerMap,
});

/*
Define the RootState that can be accessed throughout the app
 */
export type RootState = ReturnType<typeof mainReducer>;

const rootReducer = (state: any, action: Action<string>): RootState => {
	if (action.type === resetState.type) {
		state = { redirect: state.redirect };
	}
	try {
		return mainReducer(state, action);
	} catch (e) {
		console.log(e);
		Sentry.captureException(e);
		return mainReducer(state, setGlobalError);
	}
};

export default rootReducer;
