import { AbstractStore, StoreController } from "@somewear/storage";
import type { MapView } from "@web/tracking/trackingSlice";

class Store extends AbstractStore<MapView> {
	migrate() {
		// no migration needed
	}

	clean() {
		// nothing to clean
	}
}

const oneHourInMs = 60 * 60 * 1000;

export const MapViewStore = new Store(StoreController.STORE_MAP_VIEW, oneHourInMs);
