import * as React from "react";

interface IProps {
	condition: boolean;
	children: React.ReactNode;
}

export const RenderIf: React.FunctionComponent<IProps> = (props) => {
	return <>{props.condition ? props.children : <></>}</>;
};
