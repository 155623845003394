import { apiKeysEpics } from "@somewear/api-keys";
import { biometricEpics } from "@somewear/biometric";
import { deviceEpics, deviceTransfersEpics } from "@somewear/device";
import { mapLayerEpics } from "@somewear/layers";
import { conversationEpics } from "@somewear/messaging";
import {
	organizationDeviceEpics,
	organizationEpics,
	organizationMemberEpics,
} from "@somewear/organization";
import { sensorsEpics } from "@somewear/sensors";
import { shapeEpics } from "@somewear/shapes";
import { sosEpics } from "@somewear/sos";
import { waypointEpics } from "@somewear/waypoint";
import { workspaceEpics, workspaceKeyEpics, workspaceSettingsEpics } from "@somewear/workspace";
import appEpics from "@web/app/appEpics";
import trackingRouteEpics from "@web/tracking/routes/trackingRouteEpics";
import { trackingEpics } from "@web/tracking/trackingEpic";
import type { Epic } from "redux-observable";
import { combineEpics } from "redux-observable";
import { BehaviorSubject } from "rxjs";
import { mergeMap } from "rxjs/operators";

import {
	apiMessagesEpic,
	downloadFileEpic,
	getReadTimestampsEpic,
	getWorkspaceFilesEpic,
	sendMessageEpic,
	setConversationTimestampEpic,
	setWorkspaceTimestampEpic,
	uploadFileEpic,
} from "../messaging/messagesEpic";
import settingsEpics from "../settings/settingsEpics";
import workspaceMemberEpics from "../settings/workspace/members/workspaceMemberEpics";
import notificationEpics from "./notifications/notificationEpics";
import type { RootState } from "./rootReducer";

export const epic$ = new BehaviorSubject(
	combineEpics<any, any, RootState>(
		appEpics,
		apiMessagesEpic,
		apiKeysEpics,
		sendMessageEpic,
		downloadFileEpic,
		uploadFileEpic,
		getWorkspaceFilesEpic,
		getReadTimestampsEpic,
		setConversationTimestampEpic,
		setWorkspaceTimestampEpic,
		trackingEpics,
		// apiDeviceSettingsEpic,
		deviceTransfersEpics,
		notificationEpics,
		workspaceMemberEpics,
		workspaceEpics,
		workspaceKeyEpics,
		workspaceSettingsEpics,
		organizationEpics,
		organizationDeviceEpics,
		organizationMemberEpics,
		sosEpics,
		biometricEpics,
		conversationEpics,
		waypointEpics,
		deviceEpics,
		sensorsEpics,
		shapeEpics,
		settingsEpics,
		// ideally we would lazy load these, but we suspect they are causing issues with app load
		mapLayerEpics,
		trackingRouteEpics,
	),
);

export const rootEpic: Epic = (action$, state$, dependencies) =>
	epic$.pipe(mergeMap((epic) => epic(action$, state$, dependencies)));
