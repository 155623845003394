import { OptionalBool } from "@somewear/api";
import type { ApplyDefaultSettingsRequest } from "@somewear/api/src/proto/api/workspace_pb";
import { RadioBandwidth, RadioSpreadFactor } from "@somewear/api/src/proto/command_public_proto_pb";
import type { OptionalBoolValue } from "@somewear/model";
import {
	MeshDataRateIds,
	optionalBoolEnabled,
	protoToZodEnum,
	RadioFrequencyIds,
	toOptionalBool,
	TrackingIntervalIds,
} from "@somewear/model";
import { z } from "zod";

export const PERSONAL_WORKSPACE_ID = "-1";
export const PERSONAL_WORKSPACE_NAME = "Personal";

export interface WorkspacePickerOption {
	color: string;
	name: string;
	id: string;
	organizationId?: string;
	hideIcon?: boolean;
}

type EnableableField<T> = {
	enabled: boolean;
	value: T;
};

function enableableField<T extends z.ZodType & { _def: { defaultValue: () => any } }>(schema: T) {
	return z
		.object({
			value: schema,
			enabled: z.boolean(),
		})
		.default({
			value: schema._def.defaultValue(),
			enabled: true,
		});
}

export function enableableFieldToOptionalBool(field: EnableableField<boolean>) {
	return field.enabled ? toOptionalBool(field.value) : OptionalBool.OPTIONALBOOLNONE;
}

export function toEnableableBooleanField(value: OptionalBoolValue): EnableableField<boolean> {
	return {
		enabled: value !== OptionalBool.OPTIONALBOOLNONE,
		value: optionalBoolEnabled(value),
	};
}

export const DEFAULT_WORKSPACE_SETTINGS_VALUES = {
	trackingInterval: TrackingIntervalIds.ATAK30,
	radioChannel: RadioFrequencyIds.AMERICAS1,
	meshDataRate: MeshDataRateIds.Low,
	backhaul: true,
	altitude: false,
	speedAndCourse: false,
};

export const workspaceDefaultsFieldsSchema = z.object({
	backhaul: enableableField(z.boolean().default(DEFAULT_WORKSPACE_SETTINGS_VALUES.backhaul)),
	altitude: enableableField(z.boolean().default(DEFAULT_WORKSPACE_SETTINGS_VALUES.altitude)),
	speedAndCourse: enableableField(
		z.boolean().default(DEFAULT_WORKSPACE_SETTINGS_VALUES.speedAndCourse),
	),
	trackingInterval: enableableField(
		z
			.nativeEnum(TrackingIntervalIds)
			.default(DEFAULT_WORKSPACE_SETTINGS_VALUES.trackingInterval),
	),
	radioChannel: enableableField(
		z.nativeEnum(RadioFrequencyIds).default(DEFAULT_WORKSPACE_SETTINGS_VALUES.radioChannel),
	),
	meshDataRate: enableableField(
		z.nativeEnum(MeshDataRateIds).default(DEFAULT_WORKSPACE_SETTINGS_VALUES.meshDataRate),
	),
});

export const workspaceDefaultsSchema = workspaceDefaultsFieldsSchema.extend({
	workspaceId: z.string(),
	applyChangesOnSave: z.boolean().default(true),
});

const optionalBoolSchema = protoToZodEnum(OptionalBool);

export const workspaceDefaultsRequestSchema = z.object({
	// Request settings
	workspaceId: z.string(),
	applyChangesOnSave: z.boolean(),
	// Optional Bools
	backhaul: optionalBoolSchema,
	altitude: optionalBoolSchema,
	speedAndCourse: optionalBoolSchema,
	// Tracking Interval = trackingInterval + gpsInterval
	trackingInterval: z.number(),
	gpsInterval: z.number(),
	// Radio Channel = lowSpeedFrequency + highSpeedFrequency
	lowSpeedFrequency: z.number(),
	highSpeedFrequency: z.number(),
	// Radio Data Rate = bandwidth + spreadFactor
	bandwidth: protoToZodEnum(RadioBandwidth),
	spreadFactor: protoToZodEnum(RadioSpreadFactor),
});

export type WorkspaceDefaultsSchema = z.infer<typeof workspaceDefaultsSchema>;
export type WorkspaceDefaultsFieldsSchema = z.infer<typeof workspaceDefaultsFieldsSchema>;
export type WorkspaceDefaultsRequestSchema = z.infer<typeof workspaceDefaultsRequestSchema>;

export function isWorkspaceDefaultsField(key: string): key is keyof WorkspaceDefaultsFieldsSchema {
	return key in workspaceDefaultsFieldsSchema.shape;
}

export interface ApplyWorkspaceDefaultSettingsRequest extends ApplyDefaultSettingsRequest.AsObject {
	devicesCount: number;
}
