import type { Dictionary } from "@reduxjs/toolkit";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import {
	type DevicePlanResponse,
	type DeviceRecord,
	DeviceSettings,
	DeviceSettingsEventTypeMap,
	type DeviceSettingsResponse,
	DeviceStatusResponse,
	IdentityRecord,
} from "@somewear/api";
import { selectIdentityEntities, selectWorkspaceAssetEntities } from "@somewear/asset";
import { selectActiveOrganizationId, selectActiveWorkspaceId } from "@somewear/auth";
import {
	isBorrowedDevice,
	selectDeviceEntities,
	selectDeviceTransferEntities,
	selectQueuedDeviceNameChangesEntities,
} from "@somewear/device";
import type { DeviceActivationChange, IIdentity, IWorkspaceAsset } from "@somewear/model";
import { getDeviceModelName, getDictionaryValue } from "@somewear/model";
import { selectWorkspaceEntities } from "@somewear/workspace";
import { isEqual } from "lodash";
import moment from "moment";
import { createSelector } from "reselect";

import { selectQueuedDeviceActivationChangeEntities } from "./queuedDeviceActivationChangeSlice";
import type { SettingsState } from "./settings.state";
import { getOrCreateSettings } from "./settingsSlice";

const deviceStatusSelector = (state: SettingsState) => {
	return state.settings.deviceStatus;
};

const deviceSettingsSelector = (state: SettingsState) => {
	return state.settings.deviceSettings;
};

export const selectDeviceSettingsPending = (state: SettingsState) => {
	return state.settings.deviceSettingsPending;
};

const selectDeviceUserIds = (state: SettingsState) => state.settings.deviceUserIds;

export const selectShowArchived = (state: SettingsState) => state.settings.showArchived;

export const selectShowBorrowed = (state: SettingsState) => state.settings.showBorrowed;

export interface DeviceStatusSummary {
	original: DeviceState;
	pending: DeviceState;
	hasChanges: boolean;
	lastActive: DeviceStatusResponse.AsObject["lastHeartbeat"];
	lastLocation: DeviceStatusResponse.AsObject["timestamp"];
	lastSettingsUpdate?: {
		timestamp: DeviceSettingsResponse.AsObject["timestamp"];
		type: DeviceSettingsEventTypeMap[keyof DeviceSettingsEventTypeMap];
	};
	battery?: DeviceStatusResponse.AsObject["battery"];
	isBorrowed: boolean;
	organizationId?: string;
	identityId: string;
	serial: string;
	imei: string;
	type: string;
	gateway: DeviceRecord.AsObject["gateway"];
	model: DeviceRecord.AsObject["model"];
	kbUsed?: number;
	kbIncluded?: number;
	hasDataUsage?: boolean;
	plan?: DevicePlanResponse.AsObject;
}

interface DeviceState {
	assignedAsset?: IIdentity;
	activationState?: DeviceActivationChange;
	assetName?: string;
	displayName: string;
	workspaceId: string;
	workspaceName?: string;
	settings?: DeviceSettings.AsObject;
}

export const selectDeviceAssetsDict = createSelector(
	[selectDeviceUserIds, selectWorkspaceAssetEntities],
	(deviceAssetIds, assetDict) => {
		const deviceAssetDict: Dictionary<IWorkspaceAsset> = {};
		Object.values(deviceAssetIds)
			.mapNotNull((it) => it)
			.forEach((id) => {
				deviceAssetDict[id] = getDictionaryValue(assetDict, id);
			});
		return deviceAssetDict;
	},
);

export const selectDeviceSummary = createSelector(
	[
		selectDeviceEntities,
		deviceStatusSelector,
		deviceSettingsSelector,
		selectDeviceSettingsPending,
		selectQueuedDeviceNameChangesEntities,
		selectQueuedDeviceActivationChangeEntities,
		selectDeviceTransferEntities,
		selectDeviceAssetsDict,
		selectIdentityEntities,
		selectWorkspaceEntities,
		selectActiveOrganizationId,
	],
	(
		deviceDict,
		deviceStatus,
		deviceSettings,
		deviceSettingsPending,
		queuedDeviceNameChangesDict,
		queuedDeviceActivationChangesDict,
		deviceTransferDict,
		assetsDict,
		identityDict,
		workspaceDict,
		activeOrganizationId,
	) => {
		if (Object.keys(deviceDict).isEmpty()) return [];

		return Object.values(deviceDict)
			.mapNotNull((it) => it)
			.map((device): DeviceStatusSummary => {
				const {
					serial,
					workspaceId: originalWorkspaceId,
					identityId,
					userAccountId,
				} = device;
				const transfer = deviceTransferDict[serial];
				const nameChange = queuedDeviceNameChangesDict[serial];
				const status = deviceStatus[serial] || {};

				const originalWorkspace = workspaceDict[originalWorkspaceId];
				const pendingWorkspaceId = transfer?.targetWorkspaceId ?? originalWorkspaceId;
				const pendingWorkspace = workspaceDict[pendingWorkspaceId];

				const originalAssetAccount = assetsDict[userAccountId];
				const originalAsset = identityDict[originalAssetAccount?.identityId ?? ""];
				const pendingAsset = getPendingAsset();

				const deviceIdentity = identityDict[identityId];
				const originalDisplayName = deviceIdentity?.fullName ?? serial;
				const pendingDisplayName = nameChange?.name ?? originalDisplayName;

				const pendingActivationChange = queuedDeviceActivationChangesDict[serial];
				const pendingActivationState = pendingActivationChange?.change;

				const lastSettingsUpdate = deviceSettings[serial];
				const originalSettings = getOrCreateSettings(deviceSettings, serial).settings ?? {};
				const pendingSettings = {
					...originalSettings,
					...(deviceSettingsPending[serial]?.settings ?? {}),
				} as DeviceSettingsResponse.AsObject["settings"];

				function getPendingAsset(): IIdentity | undefined {
					if (transfer && transfer.targetIdentityId) {
						return identityDict[transfer.targetIdentityId];
					}

					// If a transfer exists but the target identity id is not set, it has been unassigned
					if (transfer && !transfer.targetIdentityId) {
						return identityDict[identityId];
					}

					return originalAsset;
				}

				function createDeviceState(isOriginal: boolean): DeviceState {
					const asset = isOriginal ? originalAsset : pendingAsset;
					return {
						assignedAsset: asset,
						activationState: isOriginal ? undefined : pendingActivationState,
						assetName:
							asset?.type !== IdentityRecord.Type.DEVICE
								? asset?.fullName
								: undefined,
						displayName: isOriginal ? originalDisplayName : pendingDisplayName,
						workspaceId: isOriginal ? originalWorkspaceId : pendingWorkspaceId,
						workspaceName: isOriginal
							? originalWorkspace?.name
							: pendingWorkspace?.name,
						settings: (isOriginal
							? originalSettings
							: pendingSettings) as DeviceSettings.AsObject,
					};
				}

				const showBattery = status?.timestamp
					? moment(Date.DateFromTimestamp(status.timestamp)).isAfter(
							moment().subtract(1, "day"),
						)
					: false;

				const original = createDeviceState(true);
				const pending = createDeviceState(false);

				const hasChanges = (Object.keys(original) as (keyof DeviceState)[]).some((key) => {
					if (key === "activationState") {
						return Boolean(pending.activationState);
					}

					const originalValue = original[key as keyof DeviceState];
					const pendingValue = pending[key as keyof DeviceState];
					return !isEqual(originalValue, pendingValue);
				});

				return {
					original,
					pending,
					hasChanges,
					plan: device.plan,
					lastActive: status?.lastHeartbeat,
					lastLocation: status?.timestamp,
					lastSettingsUpdate: lastSettingsUpdate
						? {
								timestamp: lastSettingsUpdate.timestamp,
								type: lastSettingsUpdate.type,
							}
						: undefined,
					battery: showBattery ? status?.battery : undefined,
					isBorrowed: isBorrowedDevice(
						device.organizationId,
						pendingWorkspace?.organizationId,
						activeOrganizationId,
					),
					organizationId: device.organizationId,
					identityId,
					serial,
					imei: device.imei,
					type: getDeviceModelName(device),
					gateway: device.gateway,
					model: device.model,
					kbUsed: device.kbUsed,
					kbIncluded: device.kbIncluded,
					hasDataUsage: device.hasDataUsage,
				};
			})
			.sort((a, b) => {
				if (a.isBorrowed !== b.isBorrowed) return a.isBorrowed ? 1 : -1;
				const timeDiff = (b.lastActive?.seconds ?? 0) - (a.lastActive?.seconds ?? 0);
				return timeDiff !== 0 ? timeDiff : a.serial.localeCompare(b.serial);
			});
	},
);

export const selectDeviceSummaryForActiveWorkspace = createSelector(
	[selectDeviceSummary, selectActiveWorkspaceId],
	(summaryList, workspaceId) => {
		return summaryList.filter((it) => it.original.workspaceId === workspaceId);
	},
);

export const selectDeviceSummaryForActiveOrganization = createSelector(
	[selectDeviceSummary, selectActiveOrganizationId, selectShowBorrowed],
	(summaryList, activeOrganizationId, showBorrowed) => {
		return summaryList.filter((it) => {
			return it.organizationId === activeOrganizationId || (showBorrowed && it.isBorrowed);
		});
	},
);

export const selectSelectedDeviceDict = (state: SettingsState) => {
	return state.settings.selectedDeviceDict;
};

export const selectDeviceDictEnabledSerials = createSelector(
	[selectSelectedDeviceDict],
	(selectedDeviceDict) => {
		return Object.entries(selectedDeviceDict).reduce(
			(acc, [key, value]) => (value ? [...acc, key] : acc),
			[] as string[],
		);
	},
);

export const selectAllDevicesSelected = createSelector(
	[selectDeviceEntities, selectDeviceDictEnabledSerials],
	(deviceDict, enabledSerials) => {
		return enabledSerials.length === Object.keys(deviceDict).length;
	},
);

export const selectBulkEditingDevices = createSelector(
	[selectDeviceDictEnabledSerials],
	(enabledSerials) => {
		return enabledSerials.length > 0;
	},
);
