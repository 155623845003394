import type { FileMetadata } from "@somewear/api";

export interface FileMetadataWithState extends FileMetadata.AsObject {
	state: FileState;
	requestId?: string;
	file?: File;
}

export enum FileState {
	Pending = "pending",
	Error = "error",
	Success = "success",
}

export interface UploadFileRequest {
	senderId: string;
	workspaceId: string;
	file: File;
}

export type UploadFileResponse = {
	signedUploadUrl: string;
	fileMetadata: FileMetadata.AsObject;
};

export type GetWorkspaceFilesResponse = FileMetadata.AsObject[];

export interface GetWorkspaceFilesRequestPayload {
	workspaceId: string;
}

export interface DownloadFileRequestPayload {
	fileId: string;
	workspaceId: string;
}

export type DownloadFileResponse = string;
