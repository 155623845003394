import { combineReducers } from "redux";

import { assetSlice } from "./asset.slice";

export const assetReducerMap = {
	workspaceAssets: assetSlice.reducer,
};

const reducers = combineReducers(assetReducerMap);

export type AssetsState = ReturnType<typeof reducers>;
