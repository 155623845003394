import { combineReducers } from "redux";

import sosSlice from "./sos.slice";

export const sosReducerMap = {
	sosEvents: sosSlice.reducer,
};

const reducers = combineReducers(sosReducerMap);

export type SosState = ReturnType<typeof reducers>;
