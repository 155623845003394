/*
	Interfaces for provide redux-thunk like behavior with redux-observable
 */
import type { PayloadAction, PayloadActionCreator } from "@reduxjs/toolkit";
import { createAction } from "@reduxjs/toolkit";
import type { IfVoid } from "@reduxjs/toolkit/dist/tsHelpers";
import type { BaseActionCreator } from "@reduxjs/toolkit/src/createAction";
import { Message } from "google-protobuf";
import { StatusCode } from "grpc-web";
import moment from "moment";
import type { Action } from "redux";
import type { Epic, StateObservable } from "redux-observable";
import { ofType } from "redux-observable";
import type { Observable, OperatorFunction } from "rxjs";
import { mergeMap, of } from "rxjs";
import {
	catchError,
	filter,
	map,
	startWith,
	switchMap,
	takeUntil,
	tap,
	throttleTime,
	timeout,
} from "rxjs/operators";
import { v4 as uuid } from "uuid";

import type { IError } from "./errorReducer";
import type { ModelState } from "./modelState";

const DEFAULT_REQUEST_TIMEOUT = 20000;

const EPIC_ACTION_REQUEST = "/request";
export const EPIC_ACTION_PENDING = "/pending";
export const EPIC_ACTION_REJECTED = "/rejected";
export const EPIC_ACTION_FULFILLED = "/fulfilled";

export const haltAll = createAction("haltAll");

export const resetState = createAction("resetState");

export const resetEpics = createAction("app/resetEpics");

export type FunctionSubmitter<D = void> = {
	// returning boolean false indicates it has not completed, otherwise it has completed
	// this is helpful for cases where validation fails or the operation needs to be confirmed
	onSubmit: (state: D) => boolean | void;
};

export type ActionSubmitter<T = unknown> = {
	submitAction: PayloadAction<OptionalRequestPayload<T>>;
};

export type PayloadActionFactory<T = unknown, D = unknown> = (
	data: D
) => PayloadAction<OptionalRequestPayload<T>>;
export type ActionFactorySubmitter<T = unknown, D = unknown> = {
	submitActionFactory: PayloadActionFactory<T, D>;
};

export type Submitter<T = unknown, D = unknown> =
	| FunctionSubmitter<D>
	| ActionSubmitter<T>
	| ActionFactorySubmitter<T, D>;

export const isFunctionSubmitter = <T = unknown, D = unknown>(
	submitter: Submitter<T, D> | undefined
): submitter is FunctionSubmitter<D> => {
	if (submitter === undefined) return false;
	return (submitter as FunctionSubmitter).onSubmit !== undefined;
};

export const isActionSubmitter = <T = unknown, D = unknown>(
	submitter: Submitter<T, D>
): submitter is ActionSubmitter<T> => {
	return (submitter as ActionSubmitter<T>).submitAction !== undefined;
};

export function getSubmitAction<T = unknown, D = unknown>(
	submitter: Submitter<T, D> | undefined,
	state: D | undefined
): PayloadAction<OptionalRequestPayload<T>> | undefined {
	if (submitter === undefined || isFunctionSubmitter(submitter)) return undefined;

	let action: PayloadAction<OptionalRequestPayload<T>> | undefined = undefined;
	if (isActionSubmitter(submitter)) {
		action = submitter.submitAction;
	} else {
		// if T is undefined here, props were incorrectly passed
		action = submitter.submitActionFactory(state as D);
	}

	// apply the timestamp to the request id for each submission
	const requestId = action.payload?.requestId ?? "";
	return {
		...action,
		payload: {
			...action.payload,
			requestId: requestId + "-" + moment.now().valueOf(),
		},
	};
}

export interface IRequestPayload<T = undefined> {
	requestId?: string;
	data: T;
	noTimeout?: boolean;
}
export interface IRequestPayloadAction<T> extends PayloadAction<T> {}

export interface IResponsePayload<T = unknown> extends IRequestPayload<T> {
	requestId: string;
	message?: string;
	details?: string;
	autoRemoveNotification?: boolean;
}

export interface IPendingPayload<T> extends IResponsePayload<T> {}
export interface IPendingPayloadAction<T> extends IRequestPayloadAction<IPendingPayload<T>> {}

export interface IFulfilledPayload<RES, REQ = void> extends IResponsePayload<RES> {
	requestData?: REQ;
}
export interface IFulfilledPayloadAction<RES, REQ = unknown>
	extends IRequestPayloadAction<IFulfilledPayload<RES, REQ>> {}

export interface IRejectedPayload<T> extends IResponsePayload<IError> {
	request: IRequestPayload<T>;
}
export interface IRejectedPayloadAction<T> extends IRequestPayloadAction<IRejectedPayload<T>> {}

export type IRequestPayloadActionSet<T, R> = IRequestPayloadAction<IRequestPayload<T | R | IError>>;

export type IResponsePayloadActionSet<T, R> =
	| IRequestPayloadActionSet<T, R>
	| IFulfilledPayloadAction<R>
	| IPendingPayloadAction<T>
	| IRejectedPayloadAction<IError>;

// used to avoid typing issues when combining epics
export type VoidableRequestPayload<T = void> = IfVoid<T, any, IRequestPayload<T>>;

export type OptionalRequestPayload<T = void> = IfVoid<T, undefined, IRequestPayload<T>>;
export type OptionalPendingPayload<T = void> = IfVoid<T, undefined, IPendingPayload<T>>;
export type OptionalRejectedPayload<T = void> = IfVoid<T, undefined, IRejectedPayload<T>>;

export interface IActionSet<R, T = void> {
	request: PayloadActionCreator<OptionalRequestPayload<T>>;
	pending: PayloadActionCreator<OptionalPendingPayload<T>>;
	fulfilled: PayloadActionCreator<IFulfilledPayload<R, T>>;
	rejected: PayloadActionCreator<OptionalRejectedPayload<T>>;
	noTimeout?: boolean;
}

export interface INotificationMessageSet {
	onFulfilled?: string;
	onRejected?: string | ((error: any) => string);
	onPending?: string;
}

export interface ActionSetEpic<T, R>
	extends Epic<IRequestPayloadActionSet<T, R>, IResponsePayloadActionSet<T, R>> {}

/*
	This provides a simplified way of generating an action set. By providing the request type (T),
	the response type (R), and an action key an action set will be produced with all of the
	thunk-like actions including request, pending, fulfilled, and rejected.
 */
export function createActionSet<T, R>(key: string, noTimeout?: boolean): IActionSet<R, T> {
	return {
		request: createAction<IfVoid<T, undefined, IRequestPayload<T>>>(key + EPIC_ACTION_REQUEST),
		pending: createAction<IfVoid<T, undefined, IPendingPayload<T>>>(key + EPIC_ACTION_PENDING),
		fulfilled: createAction<IFulfilledPayload<R, T>>(key + EPIC_ACTION_FULFILLED),
		rejected: createAction<IfVoid<T, undefined, IRejectedPayload<T>>>(
			key + EPIC_ACTION_REJECTED
		),
		noTimeout: noTimeout,
	};
}

type ActionSetRequester<T, R, S = ModelState> = (
	payload: IRequestPayload<T>,
	state$: StateObservable<S>
) => Observable<Message | R | undefined>;

type ActionSetMessageBuilder<T, S = ModelState> = (
	payload: IRequestPayload<T>,
	state$: StateObservable<S>
) => INotificationMessageSet;

export const actionSetEpicHandlerBuilder = <R, T = void, S = ModelState>(
	actionSet: IActionSet<R, T>,
	requester: ActionSetRequester<T, R, S>,
	messageBuilder?: ActionSetMessageBuilder<T, S> | INotificationMessageSet
) => {
	return (action$: Observable<IRequestPayloadActionSet<T, R>>, state$: StateObservable<S>) => {
		return createActionSetEpicHandler<T, R, S>(
			action$,
			state$,
			actionSet,
			requester,
			messageBuilder
		);
	};
};

type ResultObservable<T, R> = Observable<
	IPendingPayloadAction<T> | IFulfilledPayloadAction<R> | IRejectedPayloadAction<T>
>;

/*
	This is meant to be a more universal way to handle the inbound flow of request/trigger actions
	and emits back a stream of thunk-like actions (pending, fulfilled, rejected).

	It accepts a request builder that accepts a request action and generates an observable that
	handles executing the API or gRPC call for the given action.

	The epic is defined as an ActionSet which is a thunk-like set of actions that follow the
	signature of request, pending, fulfilled, and rejected.

	It also accepts a notification message set builder that provides a mechanisms for showing
	and updating generic notifications as the various ActionSet actions occur.
 */
export function createActionSetEpicHandler<T, R, S = ModelState>(
	action$: Observable<IRequestPayloadActionSet<T, R>>,
	state$: StateObservable<S>,
	actionSet: IActionSet<R, T>,
	buildRequest: ActionSetRequester<T, R, S>,
	buildMessageSet?: ActionSetMessageBuilder<T, S> | INotificationMessageSet
): ResultObservable<T, R> {
	return action$.pipe(
		filter(actionSet.request.match),
		switchMap((action) => {
			const payload = action.payload as IRequestPayload<T>;
			return handleActionSetRequest<T, R>(
				payload,
				actionSet,
				action$,
				buildRequest(payload, state$),
				typeof buildMessageSet === "function"
					? buildMessageSet(payload, state$)
					: buildMessageSet
			);
		})
	);
}

/**
 * Creates an epic that listens for a specific action and then based on the result of a handler function dispatches a
 * set of resulting actions. This is useful when trying to normalize multiple actions (from say an API) into a single
 * state modifying action. The is also helpful to taking an API response and converting it into the respective side
 * effects based on the state of the application or the contents of the response.
 *
 * Note: be sure to add the resulting epic to the respective `combineEpics` to ensure it is hooked in.
 *
 * @typeParam P `payload` type
 * @typeParam T `type` name extends `string`
 * @typeParam S optional `state` type
 *
 * @param actionCreator - The action creator that produces the action this epic will listen for
 * @param handler - The function that will be called when the action is dispatched and converts it into other action(s)
 *
 * @returns An epic that produces the resulting actions when the source action is dispatched
 *
 * @public
 */
export function transformerEpicBuilder<P, T extends string, S = any>(
	actionCreator: BaseActionCreator<P, T>,
	handler: (action: { payload: P; type: T }, state: S) => Action<unknown>[]
) {
	const epic: Epic<Action<unknown>, Action<unknown>, S> = (action$, state$) => {
		return action$.pipe(
			filter(actionCreator.match),
			mergeMap((action) => {
				return handler(action, state$.value);
			})
		);
	};
	return epic;
}

/*
	A rxjs operator function that consumes a stream of proto Messages from an API or gRPC response.

	Offers a universal way of handing the responses from a request and converting them into a common
	signature and pattern.

	It handles the emitting of the ActionSet's pending action when a request fires off and the
	ActionSet's rejected action when a request fails.

	It also handles the severing of the stream when a log out occurs.

 */
function handleActionSetRequest<T, R>(
	request: IRequestPayload<T> | undefined,
	actionSet: IActionSet<R, T>,
	action$: Observable<IRequestPayloadActionSet<T, R>>,
	obs$: Observable<Message | R | undefined>,
	messageSet?: INotificationMessageSet
): ResultObservable<T, R> {
	const requestId: string = request?.requestId ?? uuid();
	return obs$.pipe(
		filter((response) => response !== undefined) as OperatorFunction<
			Message | R | undefined,
			Message | R
		>,
		map((response) => {
			// handle response
			return actionSet.fulfilled({
				requestId: requestId,
				data: response instanceof Message ? (response.toObject() as R) : (response as R),
				message: messageSet?.onFulfilled,
				requestData: request?.data,
			});
		}),
		startWith(
			actionSet.pending({
				requestId: requestId,
				data: request?.data,
				message: messageSet?.onPending,
			})
		), // announce pending
		takeUntil(action$.pipe(ofType(haltAll.type))), // until signout

		request?.noTimeout || actionSet.noTimeout ? tap() : timeout(DEFAULT_REQUEST_TIMEOUT),
		catchError((error) => {
			// announce error

			if (error.name === "TimeoutError") {
				console.warn("request timed out", error);
			} else if (error.name === "ValidationError") {
				console.warn("validation error", error);
			} else if (error.code === StatusCode.PERMISSION_DENIED) {
				console.warn("permission denied", error);
			} else if (error.message === "Http response at 400 or 500 level") {
				error.message = "";
				console.warn("400 or 500 error", error);
			} else {
				console.warn("request error", error);
			}

			return of(
				actionSet.rejected({
					requestId: requestId,
					request: request,
					data: { message: error.message },
					message:
						typeof messageSet?.onRejected === "function"
							? messageSet?.onRejected(error)
							: messageSet?.onRejected,
					details: error.message,
				})
			).pipe(throttleTime(100));
		})
	);
}
