import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { type UserResponse } from "@somewear/api";
import {
	assetActions,
	emitAddUserAccountFromServer,
	emitUserAccountChangeFromServer,
} from "@somewear/asset";
import type { Contact, IWorkspaceAsset } from "@somewear/model";
import { clearWorkspace, PERSONAL_WORKSPACE_ID } from "@somewear/workspace";

import type { ContactState } from "./contact.state";

export const getDisplayNameForContact = (asset: Contact, isSelf: boolean) => {
	if (isSelf) return "Me";
	return asset !== undefined
		? asset.name
				.replaceIfEmpty(asset.email)
				.replaceIfEmpty(asset.username)
				.replaceIfEmpty(asset.phone)
		: "";
};

const adapter = createEntityAdapter<Contact>({
	selectId: (entity) => entity.id,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllContacts,
	selectById: selectContactById,
	selectIds: selectContactIds,
	selectEntities: selectContactEntities,
} = adapter.getSelectors((state: ContactState) => state.contacts);

const buildWorkspaceContact = (userResponse: UserResponse.AsObject | IWorkspaceAsset): Contact => {
	let workspaceId = userResponse.workspaceId ?? -1;
	if (workspaceId === "null" || workspaceId.isEmpty()) workspaceId = PERSONAL_WORKSPACE_ID;
	return {
		id: userResponse.id,
		email: userResponse.email,
		username: userResponse.username ?? "",
		phone: "",
		name: userResponse.fullname,
		// displayName: userResponse.fullname.replaceIfEmpty(userResponse.email),
		workspace: workspaceId !== PERSONAL_WORKSPACE_ID,
		workspaceId: workspaceId,
		isArchived: userResponse.isArchived,
		type: userResponse.type,
		identityId: userResponse.identityId,
	};
};

export const addContactsFromUserAccounts = createAction<UserResponse.AsObject[]>(
	"contactsSlice/addContactsFromUserAccount",
);
export const addContacts = createAction<Contact[]>("contacts/add");

export const contactsSlice = createSlice({
	name: "contacts",
	initialState: adapter.getInitialState(),
	reducers: {
		upsertAsset: (state, action: PayloadAction<UserResponse.AsObject>) => {
			adapter.upsertOne(state, buildWorkspaceContact(action.payload));
		},
		upsertAssets: (state, action: PayloadAction<UserResponse.AsObject[]>) => {
			const contacts = action.payload.map((asset) => buildWorkspaceContact(asset));
			adapter.upsertMany(state, contacts);
		},
		upsertContact: (state, action: PayloadAction<Contact>) => {
			adapter.upsertOne(state, action.payload);
		},
		upsertContacts: (state, action: PayloadAction<Contact[]>) => {
			adapter.upsertMany(state, action.payload);
		},
		removeContactById: (state, action: PayloadAction<string>) => {
			adapter.removeOne(state, action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(addContactsFromUserAccounts, (state, action) => {
			const contacts = action.payload.map((user) => {
				return {
					id: user.id,
					name: user.fullname,
					email: user.email,
					username: user.username,
					phone: user.phonenumber,
					displayName: user.fullname
						.replaceIfEmpty(user.email)
						.replaceIfEmpty(user.phonenumber),
					type: user.type,
				};
			});
			adapter.upsertMany(state, contacts);
		});
		builder.addCase(addContacts, (state, action) => {
			adapter.upsertMany(state, action.payload);
		});
		builder.addCase(emitUserAccountChangeFromServer, (state, action) => {
			adapter.upsertOne(state, buildWorkspaceContact(action.payload));
		});
		builder.addCase(emitAddUserAccountFromServer, (state, action) => {
			adapter.upsertOne(state, buildWorkspaceContact(action.payload));
		});

		builder.addCase(assetActions.fetchAssets.fulfilled, (state, action) => {
			const workspaceMembers = action.payload.data.usersList;
			/*
			// only do this if we get workspace members back
			const workspaceMembers = action.payload.filter(
				(asset) => asset.type === IdentityType.USER
			);
			 */
			if (workspaceMembers.length > 0) {
				// const ids = workspaceMembers.map((user) => user.id);
				// const removedUserIds = _.difference(state.ids, ids);
				const contacts = workspaceMembers.map((user) => buildWorkspaceContact(user));
				adapter.upsertMany(state, contacts);
				// todo: figure out if contact is a workspace

				// ensure that any workspace users that aren't found are removed
				// update: don't remove these users anymore
				// adapter.removeMany(state, removedUserIds);
			}
		});
		builder.addCase(assetActions.add.fulfilled, (state, action) => {
			if (action.payload.data.usersList !== undefined) {
				const contacts = action.payload.data.usersList.map((user) =>
					buildWorkspaceContact(user),
				);
				adapter.upsertMany(state, contacts);
			}
		});
		builder.addCase(clearWorkspace, (state, action) => {
			const workspaceAssetIds = adapter
				.getSelectors()
				.selectAll(state)
				.filter((it) => it.workspaceId === action.payload)
				.mapNotNull((it) => it.id);
			adapter.removeMany(state, workspaceAssetIds);
		});
	},
});
