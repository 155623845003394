import type { DeviceActivationStateTypeMap, DevicePlanResponse } from "@somewear/api";
import { DeviceRecord } from "@somewear/api";
import type {
	RadioBandwidthMap,
	RadioSpreadFactorMap,
} from "@somewear/api/src/proto/command_public_proto_pb";

import type { ValueOf } from "../common/valueOf";

export interface IDevice extends DeviceRecord.AsObject {
	plan?: DevicePlanResponse.AsObject;
	hasDataUsage?: boolean;
	kbIncluded?: number;
	kbUsed?: number;
	isBorrowed?: boolean;
}

export enum DeviceActivationChange {
	NONE,
	ACTIVATE,
	SUSPEND,
}

export type DeviceActivationState =
	DeviceActivationStateTypeMap[keyof DeviceActivationStateTypeMap];

export interface IQueuedDeviceActivationChange {
	serial: string;
	currentState: DeviceActivationState;
	change: DeviceActivationChange;
}

const NODE_SERIAL_PREFIXES = ["PC0", "RC", "N"];

export function deviceHasNodeSerial(serial?: string) {
	if (!serial) return false;

	return NODE_SERIAL_PREFIXES.some((prefix) => serial.startsWith(prefix));
}

export const getDeviceModelName = (device: Pick<IDevice, "model"> | undefined) => {
	let modelName = "Other";

	switch (device?.model) {
		case DeviceRecord.Model.SWL1: {
			modelName = "Hotspot";
			break;
		}
		case DeviceRecord.Model.SHOUTNANO: {
			modelName = "Shout";
			break;
		}
		case DeviceRecord.Model.NODE: {
			modelName = "Node";
			break;
		}
	}
	return modelName;
};

export enum TrackingIntervalIds {
	ATAK5 = "atak5s",
	ATAK15 = "atak15s",
	ATAK30 = "atak30s",
	ATAK45 = "atak45s",
	ATAK1Min = "atak1min",
	ATAK2Min = "atak2min",
	ATAK5Min = "atak5min",
	ATAK10Min = "atak10min",
	ATAK30Min = "atak30min",
	ATAK1Hr = "atak1hr",
	OnFoot = "onFoot",
	Vehicle = "vehicle",
	Jump = "jump",
	OneMin = "oneMin",
	Custom1 = "custom1",
	Custom2 = "custom2",
}

export enum RadioFrequencyIds {
	AMERICAS1 = "AMERICAS1",
	AMERICAS2 = "AMERICAS2",
	AMERICAS3 = "AMERICAS3",
	EUROPE1 = "EUROPE1",
}

export enum MeshDataRateIds {
	Low = "low",
	High = "high",
}

export type MeshDataRateParams = {
	spreadFactor: ValueOf<RadioSpreadFactorMap>;
	bandwidth: ValueOf<RadioBandwidthMap>;
};
