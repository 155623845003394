import { SomewearNotificationStatus, useSelectNotificationById } from "@somewear/notification";
import { LoadingDots, RenderIf } from "@somewear/ui";
import React from "react";
import styled from "styled-components";

type IProps = {
	requestId: string;
	theme?: "light" | "dark";
};

/**
 * The notifications panel handles both firebase notifications as well as in-app notifications.
 * This was moved into the panel to avoid the full App repainting every time a conversation was selected.
 */
export const InlineProgressComponent: React.FC<IProps> = (props: IProps) => {
	const notification = useSelectNotificationById(props.requestId);

	return (
		<RenderIf condition={notification?.status === SomewearNotificationStatus.pending}>
			<Panel>
				<LoadingDots />
			</Panel>
		</RenderIf>
	);
};

const Panel = styled.div`
	width: 36px;
`;
