import type { IConfig } from "@somewear/model";

import defaultConfig from "./default.json";

const config = defaultConfig as IConfig;

const env = import.meta.env;

config.firebase.enable = env.VITE_FIREBASE_ENABLE
	? env.VITE_FIREBASE_ENABLE === "true"
	: config.firebase.enable;
config.recurly.url = env.VITE_RECURLY_URL ?? config.recurly.url;
config.splitIO.enable = env.VITE_SPLIT_IO_ENABLE
	? env.VITE_SPLIT_IO_ENABLE === "true"
	: config.splitIO.enable;
config.sentry.enable = env.VITE_SENTRY_ENABLE
	? env.VITE_SENTRY_ENABLE === "true"
	: config.sentry.enable;
config.sentry.environment = env.VITE_SENTRY_ENVIRONMENT ?? config.sentry.environment;
config.somewear.baseUrl = env.VITE_SOMEWEAR_BASEURL ?? config.somewear.baseUrl;
config.somewear.cors = env.VITE_SOMEWEAR_CORS ?? config.somewear.cors;
config.somewear.publicUrl = env.VITE_SOMEWEAR_PUBLIC_URL ?? config.somewear.publicUrl;
config.somewear.proxyUrl = env.VITE_SOMEWEAR_PROXY_URL ?? config.somewear.proxyUrl;
config.somewear.gRPCDomain = env.VITE_SOMEWEAR_GRPC_DOMAIN ?? config.somewear.gRPCDomain;
config.somewear.map.enable = env.VITE_SOMEWEAR_MAP_ENABLE
	? env.VITE_SOMEWEAR_MAP_ENABLE === "true"
	: config.somewear.map.enable;
config.somewear.messages.enable = env.VITE_SOMEWEAR_MESSAGES_ENABLE
	? env.VITE_SOMEWEAR_MESSAGES_ENABLE === "true"
	: config.somewear.messages.enable;

export default config;
