import { grpc, someGrpc } from "@somewear/grpc";
import { actionSetEpicHandlerBuilder } from "@somewear/model";
import { combineEpics } from "redux-observable";

import { apiKeysActions } from "./apiKeys.actions";

const getOrganizationApiKeysEpic = actionSetEpicHandlerBuilder(
	apiKeysActions.getOrganizationApiKeys,
	() => grpc.prepareRequest(someGrpc.getOrganizationApiKeys),
);

const createOrganizationApiKeyEpic = actionSetEpicHandlerBuilder(
	apiKeysActions.createOrganizationApiKey,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.createOrganizationApiKey, payload.data),
);

const deleteOrganizationApiKeyEpic = actionSetEpicHandlerBuilder(
	apiKeysActions.deleteOrganizationApiKey,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.deleteOrganizationApiKey, payload.data),
);

export const apiKeysEpics = combineEpics<any, any, any>(
	getOrganizationApiKeysEpic,
	createOrganizationApiKeyEpic,
	deleteOrganizationApiKeyEpic,
);
