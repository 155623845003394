import { combineReducers } from "redux";

import { messagesSlice } from "./messagesSlice";

export const messagesReducerMap = {
	messages: messagesSlice.reducer,
};

const reducers = combineReducers(messagesReducerMap);

export type MessagesState = ReturnType<typeof reducers>;
