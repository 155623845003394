import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { emitAssetAccountsDeleted } from "@somewear/asset";

import { sensorsActions } from "./sensors.actions";
import type { ISensor } from "./sensors.model";
import type { SensorState } from "./sensors.state";

const adapter = createEntityAdapter<ISensor>({
	selectId: (event) => event.id,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllSensors,
	selectById: selectSensorsById,
	selectEntities: selectSensorsEntities,
} = adapter.getSelectors((state: SensorState) => state.sensors);

export const sensorsSlice = createSlice({
	name: "sensors",
	initialState: adapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(sensorsActions.getSensors.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.sensorsList);
		});
		builder.addCase(emitAssetAccountsDeleted, (state, action) => {
			// when an account is deleted, delete the related data
			const deletedAccountIds = action.payload.map((it) => it.id);
			const deletedItems = adapter
				.getSelectors()
				.selectAll(state)
				.filter((it) => deletedAccountIds.includes(it.userId.toString()));
			adapter.removeMany(
				state,
				deletedItems.map((it) => it.id),
			);
		});
	},
});
