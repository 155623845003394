import { createAction } from "@reduxjs/toolkit";
import type { UpdateStyleSettingsRequest, UserIdentityResponse } from "@somewear/api";
import type { IntegrationConnection } from "@somewear/api/src/proto/integration_proto_pb";
import { createActionSet } from "@somewear/model";

export const identityActions = {
	updateIdentityStyle: createActionSet<
		UpdateStyleSettingsRequest.AsObject,
		UserIdentityResponse.AsObject
	>("identity/style/update"),
	updateIntegrationConnectedState: createAction<IntegrationConnection.AsObject>(
		"identity/integration/updateConnection",
	),
};
