import type { Dictionary, EntityState } from "@reduxjs/toolkit";
import type { LocationResponse, RouteResponse, WorkspaceResponse } from "@somewear/api";
import type { IBiometrics } from "@somewear/biometric";
import type { MapLayer } from "@somewear/layers";
import type {
	AssetState,
	DateRange,
	Dict,
	IClientLocation,
	IContactForDisplayWithWorkspace,
	ITrackingFilters,
	ITrackingRoute,
	ITrackingSettings,
} from "@somewear/model";
import type { IWaypoint } from "@somewear/waypoint";
import type { Units } from "@turf/turf";
import type { Feature } from "geojson";
import type { Dispatch } from "redux";

import type { IWorkspaceDevice } from "../settings/workspace/workspaceDevicesSlice";
import type { ActiveUser, Location, MapView, Timer } from "./trackingSlice";

export const satelliteStyle = "satellite-streets-v11";
export const outdoorsStyle = "outdoors-v11";
export const styleBase = "mapbox://styles/mapbox/";

export const PointOutlineSuffix = "-point-outline";
export const SelectedPointSuffix = "-selected-point";
export const SelectedPointOutlineSuffix = "-selected-point-outline";
export const PathOutlineSuffix = "-path-outline";

export interface TrackingControllerStateProps {
	activeIdentityId: string;
	locations: Dictionary<LocationResponse.AsObject[]>;
	waypoints: Dictionary<RouteResponse.AsObject>;
	workspaces: Dictionary<WorkspaceResponse.AsObject>;
	headPointLocations: LocationResponse.AsObject[];
	hiddenUsers: string[];
	shownUsers: string[];
	expandedUsers: Dict<boolean>;
	userStates?: Dict<AssetState>;
	biometrics: Dictionary<IBiometrics>;
	contacts: EntityState<IContactForDisplayWithWorkspace>;
	idsForUsersWithLocations: string[];
	selectedUserIds: string[];
	focusedUserId?: string;
	activeTrackingUser?: ActiveUser;
	activeWorkspace?: WorkspaceResponse.AsObject;
	isPathSelected: boolean;
	dateFilters: Dict<DateRange>;
	timer: Timer;
	mapView?: MapView;
	userInView?: string[];
	uploadedMapLayers: MapLayer[];
	isLayersModalVisible: boolean;
	isMarkersModalVisible: boolean;
	geolocation?: GeolocationPosition;
	useMgrs?: boolean;
	assetDeviceMap: Dictionary<IWorkspaceDevice>;
	isLiveTracking: boolean;
	trackingRoutes: ITrackingRoute[];
	shareLocationOnLongPress: boolean;
	trackingFilters: ITrackingFilters;
	trackingSettings: ITrackingSettings;
	customTileProviderUrl?: string;
	pendingWaypointLocation?: IClientLocation;
	pendingShape?: Feature;
	selectedLocation?: Location;
	isCreateWaypointOn: boolean;
	clearMeasurements?: boolean;
}

interface DispatchProps {
	dispatch: Dispatch;
}

export type TrackingControllerProps = TrackingControllerStateProps & DispatchProps;

export interface IPropsStyleControl {
	style: string;
	className: string;
	pathColor?: string;
	willSetStyle?: () => void;
}

export interface IPropsLayersControl {
	onClick(): void;
}

export enum EngagementSidebarTabs {
	Messages = 0,
	Metrics = 1,
}

export interface IMeasurement {
	unit: Units;
	distance: number;
}

export interface ISelectedWaypoint extends IWaypoint {
	workspaceName?: string;
	workspaceColor?: string;
}
