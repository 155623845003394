import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { UpdateWorkspaceAccessResponse, WorkspaceResponse } from "@somewear/api";
import { WorkspaceAccess } from "@somewear/api";

import {
	emitOrganizationWorkspacesReceived,
	emitWorkspaceChangeFromServer,
	emitWorkspacesReceived,
} from "./workspace.actions";
import { PERSONAL_WORKSPACE_ID, PERSONAL_WORKSPACE_NAME } from "./workspace.model";
import type { WorkspaceState } from "./workspace.state";

export interface IWorkspace extends Omit<WorkspaceResponse.AsObject, "meshKey" | "externalId"> {
	userIsMember?: boolean;
	isNew?: boolean;
}

const adapter = createEntityAdapter<IWorkspace>({
	selectId: (workspace) => workspace.id,
	sortComparer: (a, b) => {
		return a.name.localeCompare(b.name);
	},
});

export const updateWorkspaceAccess =
	createAction<UpdateWorkspaceAccessResponse.AsObject>("workspace/access/update");

export const clearWorkspace = createAction<string>("workspace/clear");
export const clearActiveWorkspace = createAction("workspace/clear/active");

// Rename the exports for readability in component usage

export const {
	selectAll: selectAllWorkspaces,
	selectById: selectWorkspaceById,
	selectEntities: selectWorkspaceEntities,
} = adapter.getSelectors((state: WorkspaceState) => state.workspaces);

export const workspacesSlice = createSlice({
	name: "workspaces",
	initialState: adapter.getInitialState(),
	reducers: {
		emitWorkspaceUpdate(state, action: PayloadAction<WorkspaceResponse.AsObject>) {
			adapter.upsertOne(state, action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(emitOrganizationWorkspacesReceived, (state, action) => {
			adapter.upsertMany(state, action.payload);
		});
		builder.addCase(clearWorkspace, (state, action) => {
			adapter.removeOne(state, action.payload);
		});
		builder.addCase(emitWorkspacesReceived, (state, action) => {
			const workspaces: IWorkspace[] = action.payload.map((workspace: IWorkspace) => {
				const editedWorkspace = { ...workspace };
				if (workspace.name.isEmpty()) editedWorkspace.name = PERSONAL_WORKSPACE_NAME;
				if (workspace.id.isEmpty()) editedWorkspace.id = PERSONAL_WORKSPACE_ID;
				editedWorkspace.userIsMember = true;
				return editedWorkspace;
			});
			adapter.upsertMany(state, workspaces);
		});

		builder.addCase(emitWorkspaceChangeFromServer, (state, action) => {
			const access = action.payload.access;
			const id = action.payload.id;
			if (
				access === WorkspaceAccess.WORKSPACEACCESSCLOSED ||
				access == WorkspaceAccess.WORKSPACEACCESSDELETED
			) {
				adapter.removeOne(state, id);
			} else {
				adapter.upsertOne(state, action.payload);
			}
		});
	},
});
