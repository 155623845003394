import type { DefaultTheme } from "styled-components";

export const SomewearTheme: DefaultTheme = {
	colors: {
		main: "#4270E5",
		alert: "#f45a14",
		secondary: "",
		dark: "#212528",
		light: "#FFFFFF",
	},
	fonts: {
		title: "Roboto Condensed",
		body: "Roboto",
	},
	layout: {
		gutterSize: 35,
		gutterUnit: "px",
	},
};
