import type { Timestamp } from "@somewear/api";
import * as React from "react";

import type { Dict } from "./dict";

export {};
// require("es7-object-polyfill");
// require("flat-map-polyfill");

declare global {
	interface String {
		replaceIfEmpty(replacement: string): string;
		isEmpty(): boolean;
		isNotEmpty(): boolean;
	}
}

if (!String.prototype.includes) {
	// eslint-disable-next-line
	String.prototype.includes = function (search, start) {
		if (typeof start !== "number") {
			start = 0;
		}

		if (start + search.length > this.length) {
			return false;
		} else {
			return this.indexOf(search, start) !== -1;
		}
	};
}

if (!String.prototype.isEmpty) {
	// eslint-disable-next-line
	String.prototype.isEmpty = function (): boolean {
		return this === "";
	};
}

if (!String.prototype.isNotEmpty) {
	// eslint-disable-next-line no-extend-native
	String.prototype.isNotEmpty = function (): boolean {
		return !this.isEmpty();
	};
}

if (!String.prototype.replaceIfEmpty) {
	// eslint-disable-next-line no-extend-native
	String.prototype.replaceIfEmpty = function (replacement: string): string {
		return this.isEmpty() ? replacement : this.toString();
	};
}

declare global {
	interface Array<T> {
		last(): T;
		first(): T;
		second(): T;
		firstOrUndefined(): T | undefined;
		secondOrUndefined(): T | undefined;
		lastOrUndefined(): T | undefined;
		isEmpty(): boolean;
		isNotEmpty(): boolean;
		diff(other: Array<T>): Array<T>;
		mapNotNull<U>(callback: (value: T, idx: number) => U | undefined): Array<U>;
		associateBy(keyMapper: (value: T) => string | undefined): Dict<T>;
	}
}

if (!Array.prototype.mapNotNull) {
	// eslint-disable-next-line
	Array.prototype.mapNotNull = function (callback) {
		const dest = Array<any>();
		this.forEach((element, idx) => {
			const t = callback(element, idx);
			if (t) dest.push(t);
		});
		return dest;
	};
}

if (!Array.prototype.diff) {
	// eslint-disable-next-line
	Array.prototype.diff = function (a) {
		return this.filter(function (i) {
			return a.indexOf(i) < 0;
		});
	};
}

if (!Array.prototype.last) {
	//eslint-disable-next-line
	Array.prototype.last = function () {
		return this[this.length - 1];
	};
}

if (!Array.prototype.first) {
	//eslint-disable-next-line
	Array.prototype.first = function () {
		return this[0];
	};
}

if (!Array.prototype.firstOrUndefined) {
	//eslint-disable-next-line
	Array.prototype.firstOrUndefined = function () {
		return this.length ? this[0] : undefined;
	};
}

if (!Array.prototype.second) {
	//eslint-disable-next-line
	Array.prototype.second = function () {
		return this[1];
	};
}

if (!Array.prototype.secondOrUndefined) {
	//eslint-disable-next-line
	Array.prototype.secondOrUndefined = function () {
		return this.length ? this[1] : undefined;
	};
}

if (!Array.prototype.lastOrUndefined) {
	//eslint-disable-next-line
	Array.prototype.lastOrUndefined = function () {
		return this.length ? this.last() : undefined;
	};
}

if (!Array.prototype.isEmpty) {
	//eslint-disable-next-line
	Array.prototype.isEmpty = function () {
		return this.length === 0;
	};
}

if (!Array.prototype.isNotEmpty) {
	//eslint-disable-next-line
	Array.prototype.isNotEmpty = function () {
		return !this.isEmpty();
	};
}

if (!Array.prototype.associateBy) {
	// eslint-disable-next-line
	Array.prototype.associateBy = function <T>(keyMapper: (element: T) => string | undefined) {
		const result: Dict<T> = {};
		this.forEach((e) => {
			const key = keyMapper(e);
			if (key) {
				result[key] = e;
			}
		});
		return result;
	};
}

declare global {
	interface DateConstructor {
		DateFromTimestamp(timestamp: Timestamp.AsObject): Date;
	}
}

if (!Date.DateFromTimestamp) {
	Date.DateFromTimestamp = function (timestamp: Timestamp.AsObject): Date {
		const seconds = timestamp?.seconds ?? 0;
		const nanos = timestamp?.nanos ?? 0;
		const mills = seconds * 1000 + nanos / 1000000;
		return new Date(mills);
	};
}

export class MutableComponent<P = object, S = object> extends React.Component<P, S> {
	modifyState(next: Partial<S>) {
		this.setState(Object.assign({}, this.state, next));
	}
}

declare global {
	interface Number {
		toFt(): number;
	}
}

if (!Number.prototype.toFt) {
	// eslint-disable-next-line no-extend-native
	Number.prototype.toFt = function () {
		// @ts-ignore
		return this * 3.2808;
	};
}
