import type { Dictionary, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { LocationResponse, RouteResponse, StyleSettings } from "@somewear/api";
import { emitAssetAccountsDeleted, emitUserAccountChangeFromServer } from "@somewear/asset";
import { MapModalTypes, showMapModal } from "@somewear/layers";
import type {
	AssetState,
	DateRange,
	Dict,
	IClientLocation,
	IError,
	ITrackingFilters,
	ITrackingSettings,
	MapStyle,
	WaypointEcosystem,
} from "@somewear/model";
import { Sentry, TRACKING_FILTER_DEFAULTS } from "@somewear/model";
import { UserDataSyncClient } from "@somewear/user-data-sync";
import { waypointActions } from "@somewear/waypoint";
import { clearWorkspace } from "@somewear/workspace";
import type { DataMap } from "@web/app/appSlice";
import type { Feature } from "geojson";
import { original } from "immer";
import { cloneDeep } from "lodash";
import { LngLatBounds } from "mapbox-gl";
import moment from "moment";
import type { ViewState } from "react-map-gl";

import { trackingLocationActions } from "./locations/trackingLocationActions";
import { getNextMapStyle } from "./map/controls/utils/getNextMapStyle";
import { MapViewStore } from "./mapViewStore";
import { trackingRouteActions } from "./routes/trackingRouteActions";
import { setDateFilter, startGeolocating, stopGeolocating } from "./trackingActions";
import type { IMeasurement } from "./trackingModel";
import { EngagementSidebarTabs } from "./trackingModel";

export interface IClusterModel {
	count: number;
	coordinates: [number, number];
	id: number;
	state: AssetState;
}

export const PreviewLocationCount = 10;

export const DangerZone = 39.7222;
export const WarningZone = 38.8889;

export const ACTIVE_TRACKING_TIMEOUT = 10;
export const BIOMETRIC_TIMEOUT_IN_HOURS = 12;

export interface Locations<T> extends DataMap<T> {
	mostRecent: number;
}

export type ActiveUser = {
	userId: string;
	jump: boolean;
};

export type ActiveShape = {
	shapeId: string;
	jump: boolean;
};

export interface Timer {
	timeout: number;
	timestamp: number;
}

export type MapView = Pick<ViewState, "bearing" | "latitude" | "longitude" | "pitch" | "zoom">;

export type Location = LocationResponse.AsObject | RouteResponse.AsObject;

export enum MapMode {
	Select = "select",
	Draw = "draw",
}

export enum DrawMode {
	Rectangle = "draw_rectangle",
	Circle = "draw_circle",
	Free = "draw_polygon",
	SimpleSelect = "simple_select",
}

type TrackingState = {
	mode: MapMode;
	drawMode?: DrawMode;
	// locations indexed by user id
	// routes (waypoints) indexed by user id
	//waypoints: Dict<DataMap<RouteResponse.AsObject>>;
	// health data indexed by user id
	// healthActivity: Dict<DataMap<HealthActivityResponse.AsObject>>;
	activeTrackingUser?: ActiveUser;
	activeTrackingShape?: ActiveShape;
	dateFilter?: DateRange;
	// assetVisibilityOverrides: Dict<boolean | undefined>;
	shownUsers: Dict<boolean>;
	// userStates?: Dict<AssetState>;
	expandedUsers: Dict<boolean>;
	selectedUserIds: string[];
	focusedUserId?: string;
	// selectedLocation?: Location;
	selectedLocationId?: string;
	selectedWaypointId?: string;
	selectedEngagementTab: EngagementSidebarTabs;
	sidebarVisible: boolean;
	userListVisible: boolean;
	trackingIntervalsLoaded: boolean;
	isPathSelected: boolean;
	selfUserId?: string;
	updateTimestamp: number;
	sosDismissed: boolean;
	mapView?: MapView;
	userInView?: string[];
	geolocation?: GeolocationPosition;
	liveTracking: boolean;
	showGlobalDateFilter: boolean;
	showWorkspacePicker: boolean;
	shareLocation: boolean;
	emitSos: boolean;
	shareLocationOnLongPress: boolean;
	workspaceFilters?: Dictionary<boolean>;
	filters?: ITrackingFilters;
	settings?: ITrackingSettings;
	closeAllFilters: boolean;
	pendingWaypointLocation?: IClientLocation;
	pendingShape?: Feature;
	totalMeasurement?: IMeasurement;
	clearMeasurements?: boolean;
	/** REMOVE WITH FF: web_clusterUpdates **/
	unclusteredUserIds: string[];
	clusterModels: IClusterModel[];
	/** */
	fullBounds: LngLatBounds; // The bounds enclosing all assets on the map
	showMarkerNames?: boolean;
	showMarkerIcons?: boolean;
	updatedAt: number;
};

const LIVE_TRACKING_DEFAULT = false;

export const TRACKING_FILTER_SHOW_ALL: ITrackingFilters = {
	showActive: true,
	showInactive: true,
	showUnassignedResources: true,
	showAssigned: true,
	onlyShowLatest: false,
};

const initialState: TrackingState = {
	mode: MapMode.Select,
	drawMode: undefined,
	// waypoints: {},
	// healthActivity: {},
	activeTrackingUser: undefined,
	activeTrackingShape: undefined,
	selectedUserIds: [],
	selectedEngagementTab: EngagementSidebarTabs.Messages,
	// assetVisibilityOverrides: {},
	shownUsers: {},
	expandedUsers: {},
	sidebarVisible: false,
	userListVisible: false,
	trackingIntervalsLoaded: false,
	isPathSelected: false,
	updateTimestamp: moment().valueOf(),
	sosDismissed: false,
	liveTracking: LIVE_TRACKING_DEFAULT,
	showWorkspacePicker: false,
	showGlobalDateFilter: false,
	shareLocation: false,
	shareLocationOnLongPress: false,
	emitSos: false,
	closeAllFilters: false,
	totalMeasurement: undefined,
	/** REMOVE WITH FF: web_clusterUpdates **/
	unclusteredUserIds: [],
	clusterModels: [],
	/** */
	fullBounds: new LngLatBounds(),
	updatedAt: new Date().getTime(),
};

export interface DateFilterPayload {
	id: string;
	filter?: DateRange;
}

const trackingSlice = createSlice({
	name: "tracking",
	initialState,
	reducers: {
		setShareLocationOnLongPress(state, action: PayloadAction<boolean>) {
			state.shareLocationOnLongPress = action.payload;
		},
		toggleSosSession(state, action: PayloadAction<boolean>) {
			state.emitSos = action.payload;
		},
		/*apiTrackingIntervalRequest() {},
		apiTrackingIntervalSuccess(
			state,
			action: PayloadAction<TrackingIntervalResponseWithLocation.AsObject[]>
		) {
			action.payload.forEach((intervalResponse) => {
				const userId = intervalResponse.interval!.userId;

				if (intervalResponse.interval!.isSelf) {
					state.selfUserId = userId;
				}

				upsetLocation(state, intervalResponse.mostRecentLocation);
			});

			state.trackingIntervalsLoaded = true;
		},
		apiTrackingIntervalError(state, action: PayloadAction<IError>) {},*/
		// apiRoutesRequest() {},
		apiRoutesError(state, action: PayloadAction<IError>) {},
		// apiLocationsRequest(state, action: PayloadAction<string>) {},
		apiLocationsSuccess(state, action: PayloadAction<LocationResponse.AsObject[]>) {
			// handleLocations(state, action);
		},
		apiLocationsError(state, action: PayloadAction<IError>) {},

		/*apiLocationStreamOpen() {},
		apiLocationStreamClose() {},
		apiLocationStreamDisconnected() {},*/
		toggleEngagementSidebar(state) {
			state.sidebarVisible = !state.sidebarVisible;
		},
		setEngagementSidebar(state, action: PayloadAction<boolean>) {
			state.sidebarVisible = action.payload;
		},
		setUserList(state, action: PayloadAction<boolean>) {
			state.userListVisible = action.payload;
		},
		selectTrackingUserId(state, action: PayloadAction<string>) {
			if (state.selectedUserIds.includes(action.payload)) {
				state.selectedUserIds.splice(state.selectedUserIds.indexOf(action.payload), 1);
			}
			state.selectedUserIds.push(action.payload);
		},
		setFocusedUserId(state, action: PayloadAction<string>) {
			state.focusedUserId = action.payload;
			if (state.selectedUserIds.includes(action.payload)) {
				state.selectedUserIds.splice(state.selectedUserIds.indexOf(action.payload), 1);
			}
			state.selectedUserIds.push(action.payload);
			// state.sidebarVisible = false;
			// state.userListVisible = false;
		},
		clearFocusedUser(state) {
			state.focusedUserId = undefined;
		},
		setActiveTrackingUser(state, action: PayloadAction<ActiveUser>) {
			state.activeTrackingUser = action.payload;
			state.focusedUserId = action.payload.userId;
			if (state.selectedUserIds.includes(action.payload.userId)) {
				state.selectedUserIds.splice(
					state.selectedUserIds.indexOf(action.payload.userId),
					1,
				);
			}
			state.selectedUserIds.push(action.payload.userId);
			/*if (state.assetVisibilityOverrides[action.payload.userId] === false) {
				delete state.assetVisibilityOverrides[action.payload.userId];
			}*/
			state.sidebarVisible = true;
			state.userListVisible = false;
		},
		clearActiveUser(state) {
			state.activeTrackingUser = undefined;
			// state.sidebarVisible = false;
		},
		setActiveShape(state, action: PayloadAction<ActiveShape>) {
			state.activeTrackingShape = action.payload;
		},
		clearActiveShape(state) {
			state.activeTrackingShape = undefined;
		},
		fitUsers(state, action: PayloadAction<string[] | undefined>) {
			state.userInView = action.payload;
		},
		setActiveUserWaypoint(state, action: PayloadAction<string>) {
			state.activeTrackingUser = { userId: action.payload, jump: false };
			// state.sidebarVisible = true;
		},

		resetDateFilter(state) {
			state.dateFilter = undefined;
			state.liveTracking = LIVE_TRACKING_DEFAULT;
		},
		setWorkspaceFilter(
			state,
			action: PayloadAction<{ workspaceId: string; visible: boolean }>,
		) {
			if (state.workspaceFilters === undefined) state.workspaceFilters = {};
			state.workspaceFilters[action.payload.workspaceId] = action.payload.visible;
			UserDataSyncClient.setWorkspaceFilters(state.workspaceFilters);
		},
		setWorkspaceFilters(state, action: PayloadAction<Dictionary<boolean>>) {
			state.workspaceFilters = action.payload;
			UserDataSyncClient.setWorkspaceFilters(state.workspaceFilters);
		},
		setWorkspaceFilterOnly(
			state,
			action: PayloadAction<{ enableWorkspaceId: string; allWorkspaceIds: string[] }>,
		) {
			state.workspaceFilters = {};
			action.payload.allWorkspaceIds.forEach((workspaceId) => {
				state.workspaceFilters![workspaceId] = false;
			});
			state.workspaceFilters[action.payload.enableWorkspaceId] = true;
			UserDataSyncClient.setWorkspaceFilters(state.workspaceFilters);
		},
		setAllWorkspaceFilters(
			state,
			action: PayloadAction<{ value: boolean; allWorkspaceIds: string[] }>,
		) {
			state.workspaceFilters = {};
			action.payload.allWorkspaceIds.forEach((workspaceId) => {
				state.workspaceFilters![workspaceId] = action.payload.value;
			});
			UserDataSyncClient.setWorkspaceFilters(state.workspaceFilters);
		},
		resetWorkspaceFilters(state) {
			state.workspaceFilters = {};
			UserDataSyncClient.setWorkspaceFilters(state.workspaceFilters);
		},
		/*setSelectedLocation(state, action: PayloadAction<Location | undefined>) {
			state.selectedLocation = action.payload;

			if (action.payload && "type" in action.payload) {
				state.activeTrackingUser = undefined;
			}
		},*/
		setSelectedLocationId(state, action: PayloadAction<string | undefined>) {
			state.selectedLocationId = action.payload;
			if (action.payload !== undefined) state.selectedWaypointId = undefined;
		},
		setSelectedWaypointId(state, action: PayloadAction<string | undefined>) {
			state.selectedWaypointId = action.payload;
			if (action.payload !== undefined) state.selectedLocationId = undefined;
		},

		setIsPathSelected(state, action: PayloadAction<boolean>) {
			state.isPathSelected = action.payload;
		},
		clearSelectedUsers(state) {
			const previousDraft = state;
			const previousObject =
				previousDraft !== undefined ? original(previousDraft) : undefined;
			if (previousObject === undefined) return;
			if (previousObject.selectedUserIds.length > 0) state.selectedUserIds = [];
			// state.sidebarVisible = false;
			state.focusedUserId = undefined;
			// state.activeTrackingUser = undefined;
			// state.selectedLocation = undefined;
			state.selectedLocationId = undefined;
			state.selectedWaypointId = undefined;
			if (Object.keys(previousObject.expandedUsers).length > 0) state.expandedUsers = {};
		},
		clearDateFilters(state) {
			state.dateFilter = undefined;
		},
		/*createUserStates(state) {
			state.userStates = {};
		},
		updateUserStates(state, action: PayloadAction<number>) {
			state.updateTimestamp = action.payload;
		},*/
		setUserExpandedState(state, action: PayloadAction<{ userId: string; expanded: boolean }>) {
			state.expandedUsers[action.payload.userId] = action.payload.expanded;
		},
		sosDismissed(state, action: PayloadAction<boolean>) {
			state.sosDismissed = action.payload;
		},
		updatedMapView(state, action: PayloadAction<MapView>) {
			state.mapView = action.payload;
			updateMapViewStore(state.mapView);
		},
		setLiveTracking(state, action: PayloadAction<boolean>) {
			state.liveTracking = action.payload;
		},
		setShowGlobalDateFilter(state, action: PayloadAction<boolean>) {
			state.showGlobalDateFilter = action.payload;
		},
		setShowWorkspacePicker(state, action: PayloadAction<boolean>) {
			state.showWorkspacePicker = action.payload;
		},
		setTrackingFilters(state, action: PayloadAction<ITrackingFilters>) {
			state.filters = action.payload;
			UserDataSyncClient.setTrackingFilters(state.filters);
		},
		setTrackingSettings(state, action: PayloadAction<ITrackingSettings>) {
			state.settings = action.payload;
			UserDataSyncClient.setTrackingSettings(state.settings);
		},
		setMapStyle(state, action: PayloadAction<MapStyle>) {
			const settings = cloneDeep(state.settings!);
			settings.mapStyle = action.payload;
			state.settings = settings;
			UserDataSyncClient.setTrackingSettings(state.settings);
		},
		toggleMapStyle(state) {
			state.settings!.mapStyle = getNextMapStyle(state.settings?.mapStyle);
			UserDataSyncClient.setTrackingSettings(state.settings!);
		},
		setFilterShowAll(state, action: PayloadAction<boolean>) {
			if (action.payload) {
				state.filters = TRACKING_FILTER_SHOW_ALL;
			} else {
			}
			UserDataSyncClient.setTrackingFilters(state.filters);
		},
		setFilterShowActive(state, action: PayloadAction<boolean>) {
			state.filters!.showActive = action.payload;
			UserDataSyncClient.setTrackingFilters(state.filters!);
		},
		setFilterShowInactive(state, action: PayloadAction<boolean>) {
			state.filters!.showInactive = action.payload;
			UserDataSyncClient.setTrackingFilters(state.filters!);
		},
		setFilterShowUnassignedResources(state, action: PayloadAction<boolean>) {
			state.filters!.showUnassignedResources = action.payload;
			UserDataSyncClient.setTrackingFilters(state.filters!);
		},
		setFilterShowAssigned(state, action: PayloadAction<boolean>) {
			state.filters!.showAssigned = action.payload;
			UserDataSyncClient.setTrackingFilters(state.filters!);
		},
		setFilterOnlyShowLatest(state, action: PayloadAction<boolean>) {
			state.filters!.onlyShowLatest = action.payload;
			UserDataSyncClient.setTrackingFilters(state.filters!);
		},
		setSettingUseBetaMap(state, action: PayloadAction<boolean>) {
			state.settings!.useBetaMap = action.payload;
			UserDataSyncClient.setTrackingSettings(state.settings!);
		},
		setUseCustomMapUrl(state, action: PayloadAction<boolean>) {
			state.settings!.useCustomMapUrl = action.payload;
			UserDataSyncClient.setTrackingSettings(state.settings!);
		},
		setUseMetricSystem(state, action: PayloadAction<boolean>) {
			state.settings!.useMetric = action.payload;
			UserDataSyncClient.setTrackingSettings(state.settings!);
		},
		setDisableStale(state, action: PayloadAction<boolean>) {
			state.settings!.disableStale = action.payload;
			UserDataSyncClient.setTrackingSettings(state.settings!);
		},
		setCleanTracks(state, action: PayloadAction<boolean>) {
			state.settings!.cleanTracks = action.payload;
			UserDataSyncClient.setTrackingSettings(state.settings!);
		},
		setClusterByWorkspace(state, action: PayloadAction<boolean>) {
			state.settings!.clusterByWorkspace = action.payload;
			UserDataSyncClient.setTrackingSettings(state.settings!);
		},
		setHideMarkers(state, action: PayloadAction<boolean>) {
			state.settings!.hideMarkers = action.payload;
			UserDataSyncClient.setTrackingSettings(state.settings!);
		},
		setUseWorkspaceColor(state, action: PayloadAction<boolean>) {
			state.settings!.useWorkspaceColor = action.payload;
			UserDataSyncClient.setTrackingSettings(state.settings!);
		},
		setShowImageOverlays(state, action: PayloadAction<boolean>) {
			state.settings!.showImageOverlays = action.payload;
			UserDataSyncClient.setTrackingSettings(state.settings!);
		},
		setCustomMapUrl(state, action: PayloadAction<string>) {
			state.settings!.customMapUrl = action.payload;
			UserDataSyncClient.setTrackingSettings(state.settings!);
		},
		setResetFilters(state) {
			state.filters = TRACKING_FILTER_DEFAULTS;
			UserDataSyncClient.setTrackingFilters(state.filters);
		},
		setCloseAllFilters(state, action: PayloadAction<boolean>) {
			state.closeAllFilters = action.payload;
		},
		openedFilter(state) {
			state.sidebarVisible = false;
		},
		setPendingWaypointLocation(state, action: PayloadAction<IClientLocation | undefined>) {
			state.pendingWaypointLocation = action.payload;
		},
		setPendingShape(state, action: PayloadAction<Feature | undefined>) {
			state.pendingShape = action.payload;
		},
		setShareWaypointWithWorkspace(state, action: PayloadAction<boolean>) {
			if (state.settings?.shareWaypointSettings === undefined) {
				console.warn("Share waypoint settings not initialized");
				return;
			}
			state.settings.shareWaypointSettings.isShareWithWorkspace = action.payload;
		},
		setShareWaypointWorkspaceId(state, action: PayloadAction<string>) {
			if (state.settings?.shareWaypointSettings === undefined) {
				console.warn("Share waypoint settings not initialized");
				return;
			}
			state.settings.shareWaypointSettings.workspaceId = action.payload;
		},
		setShareWaypointAffiliation(
			state,
			action: PayloadAction<
				StyleSettings.AffiliationMap[keyof StyleSettings.AffiliationMap] | undefined
			>,
		) {
			if (state.settings?.shareWaypointSettings === undefined) {
				console.warn("Share waypoint settings not initialized");
				return;
			}
			state.settings.shareWaypointSettings.affiliation = action.payload;
		},
		setShareWaypointDimension(
			state,
			action: PayloadAction<
				StyleSettings.DimensionMap[keyof StyleSettings.DimensionMap] | undefined
			>,
		) {
			if (state.settings?.shareWaypointSettings === undefined) {
				console.warn("Share waypoint settings not initialized");
				return;
			}
			state.settings.shareWaypointSettings.dimension = action.payload;
		},
		setTotalMeasurement(state, action: PayloadAction<IMeasurement | undefined>) {
			state.totalMeasurement = action.payload;
		},
		setClearMeasurements(state, action: PayloadAction<boolean | undefined>) {
			state.clearMeasurements = action.payload;
			state.totalMeasurement = undefined;
		},
		setShareWaypointEcosystem(state, action: PayloadAction<WaypointEcosystem>) {
			if (state.settings?.shareWaypointSettings === undefined) {
				console.warn("Share waypoint settings not initialized");
				return;
			}
			state.settings!.shareWaypointSettings.ecosystem = action.payload;
		},
		/** REMOVE WITH FF: web_clusterUpdates **/
		setUnclusteredUserIds(state, action: PayloadAction<string[]>) {
			state.unclusteredUserIds = action.payload;
		},
		setClusterModels(state, action: PayloadAction<IClusterModel[]>) {
			state.clusterModels = action.payload;
		},
		/** */
		setFullBounds(state, action: PayloadAction<LngLatBounds>) {
			state.fullBounds = action.payload;
		},
		setShowMarkerNames(state, action: PayloadAction<boolean>) {
			state.showMarkerNames = action.payload;
		},
		setShowMarkerIcons(state, action: PayloadAction<boolean>) {
			state.showMarkerIcons = action.payload;
		},
		setMapMode(state, action: PayloadAction<MapMode>) {
			console.log(`setMapMode: ${action.payload}`);
			state.mode = action.payload;
			if (action.payload === MapMode.Select) {
				state.drawMode = undefined;
			}
		},
		setDrawMode(state, action: PayloadAction<DrawMode>) {
			state.drawMode = action.payload;
		},
		setEngagementTab(state, action: PayloadAction<EngagementSidebarTabs>) {
			state.selectedEngagementTab = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(setDateFilter, (state, action) => {
			state.dateFilter = action.payload;
		});
		builder.addCase(startGeolocating, (state) => {
			state.shareLocation = true;
		});
		builder.addCase(stopGeolocating, (state) => {
			state.shareLocation = false;
		});
		builder.addCase(emitUserAccountChangeFromServer, (state, action) => {
			if (action.payload.isArchived) {
				// user is archived, hide them
				// state.assetVisibilityOverrides[action.payload.id] = false;
			}
		});
		builder.addCase(trackingRouteActions.getLastKnown.fulfilled, (state, action) => {
			state.trackingIntervalsLoaded = true;
			state.updatedAt = new Date().getTime();
		});
		builder.addCase(trackingLocationActions.shareLocation.request, (state, action) => {
			state.geolocation = action.payload.data;
		});
		builder.addCase(waypointActions.saveWaypoint.fulfilled, (state, action) => {
			// state.selectedLocation = action.payload.data;
			state.selectedWaypointId = action.payload.data.id;
			state.pendingWaypointLocation = undefined;
		});
		builder.addCase(showMapModal, (state, action) => {
			if (action.payload === MapModalTypes.Markers) {
				state.selectedLocationId = undefined;
				state.selectedWaypointId = undefined;
			}
		});
		/*builder.addCase(waypointActions.apiRoutesSuccess, (state, action) => {
			action.payload.forEach((route) => {
				const userId = route.owner!.id;

				if (!(userId in state.waypoints)) {
					state.waypoints[userId] = {
						data: {},
						loaded: false,
					};
				}

				state.waypoints[userId].data[route.id] = route;
			});
		});*/
		builder.addCase(clearWorkspace, (state, action) => {
			const id = action.payload;
			// todo: delete the related data
		});
		builder.addCase(emitAssetAccountsDeleted, (state, action) => {
			// when an account is deleted, delete the related data
			const deletedAccountIds = action.payload.map((it) => it.id);
			// todo: delete the related data
		});
	},
});

function updateMapViewStore(view: MapView) {
	MapViewStore.write$(view).subscribe(
		(r) => {},
		(e) => {
			Sentry.captureException(e);
		},
	);
}

export const {
	apiRoutesError,
	apiLocationsSuccess,
	apiLocationsError,
	toggleEngagementSidebar,
	setEngagementSidebar,
	setUserList,
	setActiveTrackingUser,
	setActiveShape,
	setActiveUserWaypoint,
	fitUsers,
	resetDateFilter,
	setIsPathSelected,
	clearSelectedUsers,
	setUserExpandedState,
	sosDismissed,
	updatedMapView,
	setLiveTracking,
	setFocusedUserId,
	selectTrackingUserId,
	clearFocusedUser,
	setShowGlobalDateFilter,
	setShowWorkspacePicker,
	setWorkspaceFilter,
	setWorkspaceFilterOnly,
	setAllWorkspaceFilters,
	resetWorkspaceFilters,
	setShareLocationOnLongPress,
	setFilterShowAll,
	setFilterShowActive,
	setFilterShowInactive,
	setFilterShowUnassignedResources,
	setFilterShowAssigned,
	setFilterOnlyShowLatest,
	setSettingUseBetaMap,
	setCloseAllFilters,
	setTrackingFilters,
	setTrackingSettings,
	setWorkspaceFilters,
	setResetFilters,
	setUseCustomMapUrl,
	setCustomMapUrl,
	setPendingWaypointLocation,
	setPendingShape,
	setShareWaypointWithWorkspace,
	setShareWaypointWorkspaceId,
	setShareWaypointAffiliation,
	setShareWaypointDimension,
	setUseMetricSystem,
	setDisableStale,
	setCleanTracks,
	setClusterByWorkspace,
	setUseWorkspaceColor,
	setTotalMeasurement,
	setClearMeasurements,
	setHideMarkers,
	setShowImageOverlays,
	setShareWaypointEcosystem,
	toggleSosSession,
	/** REMOVE WITH FF: web_clusterUpdates **/
	setUnclusteredUserIds,
	setClusterModels,
	/** */
	setFullBounds,
	setMapStyle,
	toggleMapStyle,
	setShowMarkerNames,
	setShowMarkerIcons,
	setSelectedLocationId,
	setSelectedWaypointId,
	clearActiveUser,
	clearActiveShape,
	openedFilter,
	setMapMode,
	setDrawMode,
	setEngagementTab,
} = trackingSlice.actions;

export default trackingSlice.reducer;
