import { grpc, someGrpc } from "@somewear/grpc";
import { actionSetEpicHandlerBuilder } from "@somewear/model";
import { combineEpics } from "redux-observable";

import { workspaceSettingsActions } from "./workspaceSettings.slice";

const updateDefaultSettingsEpic = actionSetEpicHandlerBuilder(
	workspaceSettingsActions.updateDefaultSettings,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.updateWorkspaceDefaultSettings, payload.data),
);

const getDefaultSettingsEpic = actionSetEpicHandlerBuilder(
	workspaceSettingsActions.getDefaultSettings,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.getWorkspaceDefaultSettings, payload.data),
);

const applyDefaultSettingsEpic = actionSetEpicHandlerBuilder(
	workspaceSettingsActions.applyDefaultSettings,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.applyWorkspaceDefaultSettings, payload.data),
	(payload) => ({
		onFulfilled: `Default settings will be applied to ${payload.data.devicesCount} devices`,
		onPending: "Applying default settings...",
		onRejected: "Error applying default settings",
	}),
);

export const workspaceSettingsEpics = combineEpics<any, any, any>(
	applyDefaultSettingsEpic,
	updateDefaultSettingsEpic,
	getDefaultSettingsEpic,
);
