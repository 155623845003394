import { Button } from "@mui/material";
import { RenderIf, setupCss } from "@somewear/ui";
import type { RootState } from "@web/app/rootReducer";
import * as React from "react";
import { useSelector } from "react-redux";

import { Background } from "../setup/SetupCommon";

setupCss();

const GlobalErrorComponent: React.FunctionComponent = () => {
	const upgrade = useSelector((state: RootState) => state.app.upgrade);
	return (
		<Background>
			<div className="global-error">
				<div className="global-error-container">
					<div className="global-error-content">
						{/*<div className="global-error-image-container">
							<img alt="Error" className="global-error-image" src={iconSvg} />
						</div>*/}
						<RenderIf condition={Boolean(!upgrade)}>
							<div className="global-error-detail">
								<div className={`mb-4`}>Sorry, something went wrong.</div>
								<div>
									<Button
										color={`secondary`}
										disableElevation
										variant={`contained`}
										onClick={() => window.location.reload()}
									>
										Try Again
									</Button>
								</div>
							</div>
						</RenderIf>
						<RenderIf condition={Boolean(upgrade)}>
							<div className="global-error-detail">
								<div className={`mb-4`}>
									Sorry, something went wrong. You may be offline or a new version
									of the web app may be available.
								</div>
								<div>
									<Button
										color={`secondary`}
										disableElevation
										variant={`contained`}
										onClick={() => window.location.reload()}
									>
										Reload App
									</Button>
								</div>
							</div>
						</RenderIf>
					</div>
				</div>
			</div>
		</Background>
	);
};

export default GlobalErrorComponent;
