import type { Action } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";

import { rootEpic } from "./rootEpic";
import type { RootState } from "./rootReducer";
import rootReducer from "./rootReducer";

const epicMiddleware = createEpicMiddleware<Action<string>, Action<string>, RootState>();

/*
Cofigure the default middleware which includes immer (https://github.com/immerjs/immer) and allows
us to "mutate" the redux state in our reducer. We use redux-observable so we need to add the
epic middleware too and remove thunk
 */

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: false,
			immutableCheck: false,
			serializableCheck: false,
			actionCreatorCheck: false,
		}).concat(epicMiddleware),
	enhancers: [],
});

epicMiddleware.run(rootEpic);

/*
Enable hot module replacement
 */
if (import.meta.env.NODE_ENV === "development" && import.meta.hot) {
	import.meta.hot.accept("./rootReducer", () => {
		import("./rootReducer").then((module) => {
			store.replaceReducer(module.default);
		});
	});
}

export type AppDispatch = typeof store.dispatch;

export default store;
