import { IridiumAccount } from "@somewear/api";
import type {
	ButtonFunctionMap,
	RadioPowerModeMap,
} from "@somewear/api/src/proto/command_public_proto_pb";
import { RadioBandwidth, RadioSpreadFactor } from "@somewear/api/src/proto/command_public_proto_pb";
import {
	MeshDataRateIds,
	type MeshDataRateParams,
	type RadioFrequencyIds,
	TrackingIntervalIds,
	type ValueOf,
} from "@somewear/model";

export enum IntervalCategory {
	Somewear = "Somewear",
	ATAK = "ATAK",
}

export interface TrackingInterval {
	id: TrackingIntervalIds;
	logRate: number;
	shareRate: number;
	type: IntervalCategory;
	label: string;
	subLabel?: string;
}

export const trackingIntervals: TrackingInterval[] = [
	{
		id: TrackingIntervalIds.ATAK5,
		logRate: 5,
		shareRate: 5,
		type: IntervalCategory.ATAK,
		label: "5s",
	},
	{
		id: TrackingIntervalIds.ATAK15,
		logRate: 15,
		shareRate: 15,
		type: IntervalCategory.ATAK,
		label: "15s",
	},
	{
		id: TrackingIntervalIds.ATAK30,
		logRate: 30,
		shareRate: 30,
		type: IntervalCategory.ATAK,
		label: "30s",
	},
	{
		id: TrackingIntervalIds.ATAK45,
		logRate: 45,
		shareRate: 45,
		type: IntervalCategory.ATAK,
		label: "45s",
	},
	{
		id: TrackingIntervalIds.ATAK1Min,
		logRate: 60,
		shareRate: 60,
		type: IntervalCategory.ATAK,
		label: "1m",
	},
	{
		id: TrackingIntervalIds.ATAK2Min,
		logRate: 120,
		shareRate: 120,
		type: IntervalCategory.ATAK,
		label: "2m",
	},
	{
		id: TrackingIntervalIds.ATAK5Min,
		logRate: 300,
		shareRate: 300,
		type: IntervalCategory.ATAK,
		label: "5m",
	},
	{
		id: TrackingIntervalIds.ATAK10Min,
		logRate: 600,
		shareRate: 600,
		type: IntervalCategory.ATAK,
		label: "10m",
	},
	{
		id: TrackingIntervalIds.ATAK30Min,
		logRate: 1800,
		shareRate: 1800,
		type: IntervalCategory.ATAK,
		label: "30m",
	},
	{
		id: TrackingIntervalIds.ATAK1Hr,
		logRate: 3600,
		shareRate: 3600,
		type: IntervalCategory.ATAK,
		label: "1h",
	},
	{
		id: TrackingIntervalIds.OnFoot,
		logRate: 30,
		shareRate: 60,
		type: IntervalCategory.Somewear,
		label: "On-Foot",
		subLabel: "Logs: 30s | Shares: 1m",
	},
	{
		id: TrackingIntervalIds.Vehicle,
		logRate: 15,
		shareRate: 30,
		type: IntervalCategory.Somewear,
		label: "Vehicle",
		subLabel: "Logs: 15s | Shares: 30s",
	},
	{
		id: TrackingIntervalIds.Jump,
		logRate: 5,
		shareRate: 5,
		type: IntervalCategory.Somewear,
		label: "Jump",
		subLabel: "Logs: 5s | Shares: 5s",
	},
	{
		id: TrackingIntervalIds.OneMin,
		logRate: 60,
		shareRate: 60,
		type: IntervalCategory.Somewear,
		label: "Custom",
		subLabel: "Logs: 1m | Shares: 1m",
	},
	{
		id: TrackingIntervalIds.Custom1,
		logRate: 30,
		shareRate: 300,
		type: IntervalCategory.Somewear,
		label: "Custom",
		subLabel: "Logs: 30s | Shares: 5m",
	},
	{
		id: TrackingIntervalIds.Custom2,
		logRate: 60,
		shareRate: 600,
		type: IntervalCategory.Somewear,
		label: "Custom",
		subLabel: "Logs: 1m | Shares: 10m",
	},
];

export const lowSpeedRadioFrequencies = {
	LOWSPEEDUNKNOWN: 0,
	LOWSPEEDAMERICAS1: 925750000,
	LOWSPEEDAMERICAS2: 926500000,
	LOWSPEEDAMERICAS3: 927250000,
	LOWSPEEDEUROPE1: 869525000,
};

export const highSpeedRadioFrequencies = {
	HIGHSPEEDUNKNOWN: 0,
	HIGHSPEEDAMERICAS1: 905250000,
	HIGHSPEEDAMERICAS2: 908500000,
	HIGHSPEEDAMERICAS3: 911750000,
	HIGHSPEEDEUROPE1: 865500000,
};

export function iridiumStatusToString(
	status: IridiumAccount.StatusMap[keyof IridiumAccount.StatusMap] | undefined,
) {
	const defaultValue = "Unknown";
	if (status === undefined) return defaultValue;
	if (status === IridiumAccount.Status.ACTIVE) return "Active";
	if (status === IridiumAccount.Status.DEACTIVE) return "Inactive";
	if (status === IridiumAccount.Status.SUSPENDED) return "Suspended";

	return defaultValue;
}

export interface RadioFrequency {
	low: number;
	high: number;
	id: RadioFrequencyIds;
	label: string;
}

function formatFrequency(freq: number) {
	return `${freq / 1000000} MHz`;
}

export function displayFrequency(frequencyType: RadioFrequency) {
	return `${formatFrequency(frequencyType.low)}, ${formatFrequency(frequencyType.high)}`;
}

export interface IRadioPowerMode {
	label: string;
	id: string;
	value: ValueOf<RadioPowerModeMap>;
}

export interface IButtonFunction {
	label: string;
	id: string;
	value: ValueOf<ButtonFunctionMap>;
}

export interface IMeshDataRate {
	label: string;
	id: MeshDataRateIds;
	value: MeshDataRateParams;
}

export const meshDataRates: IMeshDataRate[] = [
	{
		label: "Low",
		id: MeshDataRateIds.Low,
		value: {
			spreadFactor: RadioSpreadFactor.RADIOSPREADFACTOR08,
			bandwidth: RadioBandwidth.RADIOBANDWIDTH250KHZ,
		},
	},
	{
		label: "High",
		id: MeshDataRateIds.High,
		value: {
			spreadFactor: RadioSpreadFactor.RADIOSPREADFACTOR05,
			bandwidth: RadioBandwidth.RADIOBANDWIDTH500KHZ,
		},
	},
];
