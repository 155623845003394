import type { Dictionary } from "@reduxjs/toolkit";
import type { IdentityTypeMap } from "@somewear/api";
import { IdentityType } from "@somewear/api";
import { selectIdentityEntities } from "@somewear/asset";
import { selectActiveIdentityId } from "@somewear/auth";
import type { IContactForDisplay } from "@somewear/model";
import { selectEcho } from "@somewear/model";
import { getColorForAsset, selectWorkspaceById } from "@somewear/workspace";
import { createSelector } from "reselect";

import type { ContactState } from "./contact.state";
import {
	getDisplayNameForContact,
	selectAllContacts,
	selectContactEntities,
} from "./contactsSlice";

export const selectUnarchivedContacts = createSelector(
	[selectAllContacts, selectIdentityEntities, selectActiveIdentityId],
	(contacts, identityDict, activeIdentityId) => {
		return contacts.mapNotNull((contact) => {
			const identity =
				contact.identityId === undefined ? undefined : identityDict[contact.identityId];
			return {
				...contact,
				color: getColorForAsset(contact, identity),
				displayName: getDisplayNameForContact(
					contact,
					contact.identityId === activeIdentityId,
				),
			} as IContactForDisplay;
		});
	},
);

export const selectUnarchivedContactEntities = createSelector(
	[selectContactEntities, selectActiveIdentityId],
	(contacts, activeIdentityId) => {
		const unarchivedContacts: Dictionary<IContactForDisplay> = {};
		Object.values(contacts).forEach((contact) => {
			if (!contact?.isArchived && contact?.id !== undefined) {
				unarchivedContacts[contact.id] = {
					...contact,
					displayName: getDisplayNameForContact(
						contact,
						contact.identityId === activeIdentityId,
					),
				} as IContactForDisplay;
			}
		});
		return unarchivedContacts;
	},
);

export const selectUnarchivedContactById = createSelector(
	[selectUnarchivedContactEntities, selectEcho<string, ContactState>],
	(entities, id) => {
		return entities[id];
	},
);

export const selectAllPublicTrackContacts = createSelector(
	[selectUnarchivedContacts],
	(contacts) => {
		return contacts.filter((contact) => {
			return !Boolean(contact.email) && !Boolean(contact.phone);
		});
	},
);

export const selectAllMemberContacts = createSelector([selectUnarchivedContacts], (accounts) => {
	return accounts.filter((account) => account.type === IdentityType.USER);
});

export const workspaceContactsSelector = createSelector([selectUnarchivedContacts], (contacts) => {
	return contacts
		.filter((contact) => contact.id !== "newUser")
		.filter((contact) => contact.workspace)
		.sort((a, b) => {
			const aName = a.displayName;
			const bName = b.displayName;
			if (aName < bName) return -1;
			if (aName > bName) return 1;
			return 0;
		});
});

export const selectPersonalContacts = createSelector([selectUnarchivedContacts], (contacts) => {
	return contacts.filter((contact) => !contact.workspace);
});

export const selectWorkspaceMemberContacts = createSelector(
	[workspaceContactsSelector],
	(contacts) => {
		return contacts
			.filter((contact) => !contact.isArchived)
			.filter((contact) => contact.displayName.length > 0)
			.filter((contact) => contact.type === IdentityType.USER);
	},
);

export const selectAllUserContacts = createSelector(
	[selectPersonalContacts, selectWorkspaceMemberContacts],
	(personalContacts, workspaceContacts) => {
		return personalContacts.concat(...workspaceContacts);
	},
);

export const selectWorkspaceMemberContactCount = createSelector(
	[selectWorkspaceMemberContacts],
	(contacts) => {
		return contacts.length;
	},
);

export const selectWorkspaceMemberContactCountForWorkspaceId = createSelector(
	[selectWorkspaceMemberContacts, selectWorkspaceById],
	(contacts, workspace) => {
		if (workspace === undefined) return 0;
		return contacts.filter((contact) => contact.workspaceId === workspace.id).length;
	},
);

export const selectContactTypeMap = createSelector([selectAllContacts], (assets) => {
	const assetTypeMap: Dictionary<IdentityTypeMap[keyof IdentityTypeMap]> = {};
	assets.forEach((asset) => {
		assetTypeMap[asset.id] = asset.type;
	});
	return assetTypeMap;
});

export const selectAssetCountsByWorkspaceId = createSelector([selectAllContacts], (assets) => {
	const assetCounts: Dictionary<number> = {};
	assets.forEach((asset) => {
		if (asset.workspaceId !== undefined) {
			if (assetCounts[asset.workspaceId] === undefined) assetCounts[asset.workspaceId] = 0;
			assetCounts[asset.workspaceId] = assetCounts[asset.workspaceId]! + 1;
		}
	});
	return assetCounts;
});

export const selectSelfAccounts = createSelector(
	[selectAllContacts, selectActiveIdentityId],
	(contacts, identityId) => {
		return contacts.filter((contact) => contact.identityId === identityId);
	},
);

export const selectAllSelfAccountIds = createSelector([selectSelfAccounts], (contacts) => {
	return contacts.mapNotNull((contact) => contact.id);
});

export const selectJoinedWorkspaceIds = createSelector([selectSelfAccounts], (accounts) => {
	return accounts.mapNotNull((account) => account.workspaceId);
});
