import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { PublicRecordResponse } from "@somewear/api";
import type { IAuthUser } from "@somewear/auth";
import { Api, AuthController } from "@somewear/auth";
import Loader from "@web/common/Loader";
import Paths from "@web/common/Paths";
import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { of, Subject } from "rxjs";
import { catchError } from "rxjs/operators";

interface IMatch {
	uuid: string;
}

export const PublicTracking: React.FunctionComponent = () => {
	const [loaded, setLoaded] = useState(false);
	const match = useRouteMatch();
	const history = useHistory();

	useEffect(() => {
		const subject$ = new Subject<IAuthUser | undefined>();
		subject$.subscribe((user) => {
			if (user !== undefined) {
				Api.getTrackingForUuid((match.params as IMatch).uuid)
					.pipe(
						catchError((error) => {
							const record = new PublicRecordResponse();
							record.setEnabled(false);
							return of(record);
						}),
					)
					.subscribe((publicRecord) => {
						if (!publicRecord.getEnabled()) {
							setLoaded(true);
						} else {
							history.push(Paths.Tracking);
						}
					});
			} else {
				// sign in anonymously
				console.log("signing in anonymously");
				AuthController.service.signInAnonymously$().subscribe(
					() => {},
					(error) => {
						console.error(error);
					},
				);
			}
		});
		const unregisterAuthObserver = AuthController.service.onAuthStateChanged(subject$);

		return function cleanup() {
			unregisterAuthObserver();
		};
	}, [match, setLoaded, history]);

	if (!loaded) {
		return (
			<div className="setup-background-content">
				<Loader fullScreen={true} />
			</div>
		);
	}

	const handleClose = () => {
		history.push(Paths.Tracking);
	};

	return (
		<div>
			<Dialog open={loaded} onClose={handleClose}>
				<DialogTitle id="alert-dialog-title">
					{"User has disabled public tracking"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						The user who provided this link is no longer sharing their location.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
