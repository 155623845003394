import { combineReducers } from "redux";

import { clusteringSlice } from "./store";

export const clusterReducerMap = {
	clustering: clusteringSlice.reducer,
};

const reducers = combineReducers(clusterReducerMap);

export type ClusterState = ReturnType<typeof reducers>;
