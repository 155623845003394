import type {
	GetOrganizationIntegrationsResponse,
	GetOrganizationMembersResponse,
	IdentityRecord,
	InviteOrganizationMembersResponse,
	RemoveOrganizationMembersResponse,
	UpdateOrganizationRoleRequest,
	UpdateOrganizationRoleResponse,
} from "@somewear/api";
import { grpc, someGrpc } from "@somewear/grpc";
import type {
	ActionSetEpic,
	IOrganizationAsset,
	IRequestPayloadAction,
	VoidableRequestPayload,
} from "@somewear/model";
import { createActionSetEpicHandler } from "@somewear/model";
import { combineEpics } from "redux-observable";

import { organizationMemberActions } from "./organizationMemberActions";

const getOrganizationMembersEpic: ActionSetEpic<void, GetOrganizationMembersResponse.AsObject> = (
	action$,
	state$,
) => {
	return createActionSetEpicHandler(action$, state$, organizationMemberActions.getMembers, () =>
		grpc.prepareRequest(someGrpc.fetchOrganizationMembers),
	);
};

const getOrganizationIntegrationsEpic: ActionSetEpic<
	void,
	GetOrganizationIntegrationsResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		organizationMemberActions.getIntegrations,
		() => grpc.prepareRequest(someGrpc.fetchOrganizationIntegrations),
	);
};

const assignOrganizationRoleEpic: ActionSetEpic<
	UpdateOrganizationRoleRequest.AsObject,
	UpdateOrganizationRoleResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		organizationMemberActions.assignRole,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.assignOrganizationRole, payload.data),
	);
};

const addOrganizationMembersEpic: ActionSetEpic<
	IdentityRecord.AsObject[],
	InviteOrganizationMembersResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		organizationMemberActions.addMembers,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.addOrganizationMembers, payload.data),
		(payload) => {
			return {
				onRejected: "Failed to add organization members",
				onFulfilled: "Successfully added organization members",
				onPending: "Adding organization members",
			};
		},
	);
};

const removeOrganizationMembersEpic: ActionSetEpic<
	IOrganizationAsset[],
	RemoveOrganizationMembersResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		organizationMemberActions.removeMembers,
		(payload) =>
			grpc.prepareRequestWithPayload(someGrpc.removeOrganizationMembers, payload.data),
	);
};

export const organizationMemberEpics = combineEpics<
	IRequestPayloadAction<VoidableRequestPayload>,
	IRequestPayloadAction<unknown>,
	any
>(
	assignOrganizationRoleEpic,
	getOrganizationMembersEpic,
	getOrganizationIntegrationsEpic,
	addOrganizationMembersEpic,
	removeOrganizationMembersEpic,
);
