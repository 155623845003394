import * as React from "react";
import styled from "styled-components";

interface IProps {
	className?: string;
}

const Icon = styled.svg`
	height: 16px;
	width: 16px;
`;

export const CheckCircleIconComponent: React.FunctionComponent<IProps> = (props) => {
	return (
		<Icon className={props.className ?? ""} viewBox="0 0 16 16">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4ZM6.4 9.73606L11.672 4.46406L12.8 5.60006L6.4 12.0001L3.2 8.80006L4.328 7.67206L6.4 9.73606Z"
			/>
		</Icon>
	);
};
