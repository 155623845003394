import { PERSONAL_WORKSPACE_ID } from "./workspace.model";
import type { IWorkspace } from "./workspaces.slice";

export namespace WorkspaceUtil {
	export const isTeam = (workspace?: IWorkspace | string): boolean => {
		if (workspace === undefined) return false;

		let workspaceId = undefined;
		if (typeof workspace === "string") {
			workspaceId = workspace;
		} else {
			workspaceId = workspace.id;
		}

		return workspaceId.isNotEmpty() && workspaceId !== PERSONAL_WORKSPACE_ID;
	};

	export const isPersonal = (workspace?: IWorkspace | string): boolean => {
		return !isTeam(workspace);
	};

	export const hasTeamWorkspace = (workspaces: IWorkspace[]): boolean => {
		return workspaces.find((workspace) => isTeam(workspace)) !== undefined;
	};
}
