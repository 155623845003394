import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction, createSlice } from "@reduxjs/toolkit";

import { UserSource } from "../UserSource";

export type IAuthState = {
	activeIdentityId?: string;
	activeOrganizationId?: string;
	activeUserAccountId?: string;
	activeWorkspaceId?: string;
};

const authSlice = createSlice({
	name: "auth",
	initialState: {} as IAuthState,
	reducers: {
		setActiveIdentityId(state, action: PayloadAction<string>) {
			state.activeIdentityId = action.payload;
		},
		setActiveOrganizationId(state, action: PayloadAction<string>) {
			UserSource.getInstance().updateActiveOrganization(action.payload);
			state.activeOrganizationId = action.payload;
			if (action.payload === "-1") {
				UserSource.getInstance().updateActiveWorkspaceId(action.payload);
				state.activeWorkspaceId = action.payload;
			}
		},
		setActiveUserAccountId(state, action: PayloadAction<string>) {
			state.activeUserAccountId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(setActiveWorkspaceId, (state, action) => {
			UserSource.getInstance().updateActiveWorkspaceId(action.payload);
			state.activeWorkspaceId = action.payload;
		});
	},
});

const authReducer = authSlice.reducer;

// set the active workspace, but do not clear state
export const setActiveWorkspaceId = createAction<string>("setActiveWorkspaceId");

export const { setActiveIdentityId, setActiveOrganizationId, setActiveUserAccountId } =
	authSlice.actions;

export default authSlice;
