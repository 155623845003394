import type { ReactNode } from "react";
import * as React from "react";
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import { useOnMount } from "../../hooks";

export function useIdleCallback(callback: () => void) {
	useEffect(() => {
		const idleCallbackId =
			"requestIdleCallback" in window
				? requestIdleCallback(callback)
				: setTimeout(callback, 1);

		return () => {
			if ("cancelIdleCallback" in window) {
				cancelIdleCallback(idleCallbackId as number);
			} else {
				clearTimeout(idleCallbackId);
			}
		};
	}, [callback]);
}

interface DeferGroupContextType {
	registerDefer: () => void;
	unregisterDefer: (isReady: boolean) => void;
	reportReady: () => void;
	isGroupReady: boolean;
}

export const DeferGroupContext = createContext<DeferGroupContextType | undefined>(undefined);

interface DeferGroupProviderProps {
	children: ReactNode;
}

export function DeferGroupProvider({ children }: DeferGroupProviderProps) {
	const [totalDeferComponents, setTotalDeferComponents] = useState(0);
	const [readyDeferComponents, setReadyDeferComponents] = useState(0);

	const registerDefer = useCallback(() => {
		setTotalDeferComponents((prevCount) => prevCount + 1);
	}, []);

	const unregisterDefer = useCallback((isReady: boolean) => {
		setTotalDeferComponents((prevCount) => prevCount - 1);
		if (isReady) {
			setReadyDeferComponents((prevCount) => prevCount - 1);
		}
	}, []);

	const reportReady = useCallback(() => {
		setReadyDeferComponents((prevCount) => prevCount + 1);
	}, []);

	const isGroupReady = useMemo(
		() => totalDeferComponents > 0 && readyDeferComponents === totalDeferComponents,
		[totalDeferComponents, readyDeferComponents],
	);

	const contextValue = useMemo(
		() => ({
			registerDefer,
			unregisterDefer,
			reportReady,
			isGroupReady,
		}),
		[registerDefer, unregisterDefer, reportReady, isGroupReady],
	);

	return <DeferGroupContext.Provider value={contextValue}>{children}</DeferGroupContext.Provider>;
}
interface DeferProps {
	placeholder?: ReactNode;
	children: ReactNode;
}

const Placeholder: React.FC = () => <div className="w-full h-8 bg-muted rounded animate-pulse" />;

export function Defer({ placeholder: customPlaceholder, children }: DeferProps) {
	const [ready, setReady] = useState(false);
	const readyRef = useRef(false);
	const groupContext = useContext(DeferGroupContext);

	const placeholder = customPlaceholder ?? <Placeholder />;

	useOnMount(() => {
		let isMounted = true;

		groupContext?.registerDefer();

		const onIdle = () => {
			if (isMounted) {
				setReady(true);
				readyRef.current = true;
				groupContext?.reportReady();
			}
		};

		const idleCallbackId =
			"requestIdleCallback" in window ? requestIdleCallback(onIdle) : setTimeout(onIdle, 1);

		return () => {
			isMounted = false;

			if ("cancelIdleCallback" in window) {
				cancelIdleCallback(idleCallbackId as number);
			} else {
				clearTimeout(idleCallbackId);
			}
			groupContext?.unregisterDefer(readyRef.current);
		};
	});

	const shouldRenderPlaceholder = groupContext ? !groupContext.isGroupReady || !ready : !ready;

	return shouldRenderPlaceholder ? <>{placeholder}</> : <>{children}</>;
}
