import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { IError, IResponsePayload } from "@somewear/model";

import type { NotificationState } from "./notification.state";

export enum SomewearNotificationStatus {
	success,
	error,
	pending,
	info,
}

export interface ISomewearNotification {
	requestId: string;
	status: SomewearNotificationStatus;
	message?: string;
	details?: string;
	autoRemove?: boolean;
	inline?: boolean;
}

export interface ISomewearSuccessNotification<T> extends ISomewearNotification {
	data?: T;
}

export interface ISomewearErrorNotification extends ISomewearNotification {
	error?: IError;
}

const adapter = createEntityAdapter<ISomewearNotification>({
	selectId: (entity) => entity.requestId,
});

// Rename the exports for readability in component usage
export const { selectAll: selectAllNotifications, selectById: selectNotificationByRequestId } =
	adapter.getSelectors((state: NotificationState) => state.notifications);

const buildSuccessNotification = <T = unknown>(
	payload: IResponsePayload<T>,
): ISomewearSuccessNotification<T> | undefined => {
	return {
		requestId: payload.requestId,
		status: SomewearNotificationStatus.success,
		message: payload.message,
		details: payload.details,
		autoRemove: payload.autoRemoveNotification ?? true,
		data: payload.data,
	};
};

const buildInfoNotification = <T = unknown>(
	payload: IResponsePayload<T>,
): ISomewearSuccessNotification<T> | undefined => {
	return {
		requestId: payload.requestId,
		status: SomewearNotificationStatus.info,
		message: payload.message,
		details: payload.details,
		autoRemove: payload.autoRemoveNotification ?? true,
		data: payload.data,
	};
};

export const isSuccessNotification = <T>(
	obj: ISomewearNotification | undefined,
): obj is ISomewearSuccessNotification<T> => obj?.status === SomewearNotificationStatus.success;

export const isErrorNotification = (
	obj: ISomewearNotification | undefined,
): obj is ISomewearErrorNotification => obj?.status === SomewearNotificationStatus.error;

const buildPendingNotification = <T = undefined>(
	payload: IResponsePayload<T>,
): ISomewearNotification | undefined => {
	return {
		requestId: payload.requestId,
		status: SomewearNotificationStatus.pending,
		message: payload.message,
		details: payload.details,
	};
};

const buildErrorNotification = <T = unknown>(
	payload: IResponsePayload<T>,
): ISomewearNotification | undefined => {
	return {
		requestId: payload.requestId,
		status: SomewearNotificationStatus.error,
		message: payload.message,
		details: payload.details,
	};
};

function upsertNotification(state: any, notification: ISomewearNotification | undefined) {
	if (notification?.requestId !== undefined) adapter.upsertOne(state, notification);
}

export const notificationsSlice = createSlice({
	name: "notifications",
	initialState: adapter.getInitialState(),
	reducers: {
		emitPendingNotification: (state, action: PayloadAction<IResponsePayload>) => {
			const notification = buildPendingNotification(action.payload);
			upsertNotification(state, notification);
		},
		emitErrorNotification: (state, action: PayloadAction<IResponsePayload>) => {
			const notification = buildErrorNotification(action.payload);
			upsertNotification(state, notification);
		},
		emitSuccessNotification: (state, action: PayloadAction<IResponsePayload>) => {
			const notification = buildSuccessNotification(action.payload);
			upsertNotification(state, notification);
		},
		emitInfoNotification: (state, action: PayloadAction<IResponsePayload>) => {
			const notification = buildInfoNotification(action.payload);
			upsertNotification(state, notification);
		},
		removeNotificationById: (state, action: PayloadAction<string>) => {
			adapter.removeOne(state, action.payload);
		},
		removeAllNotifications: (state) => {
			adapter.removeAll(state);
		},
	},
});
