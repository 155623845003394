import { createTheme } from "@mui/material/styles";

export const MuiTheme = createTheme({
	/*props: {
		// Name of the component ⚛️
		MuiButtonBase: {
			// The properties to apply
			disableRipple: true, // No more ripple, on the whole application 💣!,
		},
	},*/
	palette: {
		primary: {
			main: "#000000",
		},
		secondary: {
			main: "#415FFF", // hsl colors from var(--somewear-blue) not supported in mui
		},
		warning: {
			main: "#FE511A",
			light: "rgba(254, 81, 26, 0.18)",
			contrastText: "#FE511A",
		},
	},
	typography: {
		button: {
			textTransform: "none",
			fontWeight: 400,
			fontSize: "14px",
			minWidth: "148px",
			textAlign: "center",
		},
	},
	components: {
		// Name of the component ⚛️
		MuiButtonBase: {
			defaultProps: {
				// The props to apply
				disableRipple: true, // No more ripple, on the whole application 💣!
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: ".875rem",
					fontWeight: 500,
					color: "#000000",
					"&$selected": {
						background: "#F5F6F7",
					},
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				gutters: {
					paddingLeft: 0,
					paddingRight: 0,
				},
			},
		},
		MuiPopover: {
			styleOverrides: {
				paper: {
					borderRadius: ".125rem",
					boxShadow:
						"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
				},
			},
		},
	},
	/*overrides: {
		MuiMenuItem: {
			root: {
				fontSize: ".875rem",
				fontWeight: 500,
				color: "#000000",
				"&$selected": {
					background: "#F5F6F7",
				},
			},
		},
		MuiListItem: {
			gutters: {
				paddingLeft: 0,
				paddingRight: 0,
			},
		},
		MuiPopover: {
			paper: {
				borderRadius: ".125rem",
				boxShadow:
					"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
			},
		},
	},*/
});

// this the the code from the legacy theme
/*
export const MaterialUiTheme = createTheme({
	props: {
		// Name of the component ⚛️
		MuiButtonBase: {
			// The properties to apply
			disableRipple: true, // No more ripple, on the whole application 💣!,
		},
	},
	palette: {
		primary: {
			main: "#000000",
		},
		secondary: {
			main: SWL_COLOR_BLUE,
		},
		warning: {
			main: "#FE511A",
			light: "rgba(254, 81, 26, 0.18)",
			contrastText: "#FE511A",
		},
	},
	typography: {
		button: {
			textTransform: "none",
			fontWeight: 400,
			fontSize: "14px",
			minWidth: "148px",
			textAlign: "center",
		},
	},
	overrides: {
		MuiMenuItem: {
			root: {
				fontSize: ".875rem",
				fontWeight: 500,
				color: "#000000",
				"&$selected": {
					background: "#F5F6F7",
				},
			},
		},
		MuiListItem: {
			gutters: {
				paddingLeft: 0,
				paddingRight: 0,
			},
		},
		MuiPopover: {
			paper: {
				borderRadius: ".125rem",
				boxShadow:
					"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
			},
		},
	},
});*/
