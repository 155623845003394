// todo: move this to the map module

export namespace MapConstants {
	export const OLD_OPACITY = 1;
	export const USERS_PATH_SOURCE_ID = "swl-users-path-source";
	export const USERS_POINT_SOURCE_ID = "swl-users-point-source";
	export const WAYPOINT_SOURCE_ID = "swl-waypoint-source";
	export const HEAD_POINTS_SOURCE_ID = "swl-headpoints-source";
	export const MEASUREMENT_SOURCE_ID = "swl-measurement-source";

	export const HEAD_POINTS_LAYER_ID = "swl-headpoints-layer";
	export const CLUSTERS_LAYER_ID = "swl-clusters-circle-layer";
	export const CLUSTERS_COUNT_LAYER_ID = "swl-clusters-count-layer";

	export const POINTS_LAYER_ID = "swl-points-circle-layer";
	export const PATH_LAYER_ID = "swl-path-layer";

	export const WAYPOINT_LAYER_ID = "swl-waypoint-layer";
	export const WAYPOINT_SYMBOLS_LAYER_ID = "swl-waypoint-symbols-layer";
	export const WAYPOINT_TEXT_LAYER_ID = "swl-waypoint-text-layer";

	export const WAYPOINT_BACKGROUND_LAYER_ID = "swl-waypoint-background-layer";

	export const MEASUREMENT_LINE_LAYER_ID = "swl-measurement-line-layer";
	export const MEASUREMENT_TEXT_LAYER_ID = "swl-measurement-text-layer";
	export const MEASUREMENT_POINT_LAYER_ID = "swl-measurement-point-layer";
}
