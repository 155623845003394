import "react-phone-number-input/style.css";

import { Api, AuthController, loadAuthService } from "@somewear/auth";
import { someGrpc } from "@somewear/grpc";
import { Configuration, Sentry } from "@somewear/model";
import {
	commonCss,
	componentsCss,
	conversationCss,
	indexCss,
	mapCss,
	sidebarCss,
} from "@somewear/ui";
import { setGlobalError, setUpgrade } from "@web/app/appSlice";
import type { RootState } from "@web/app/rootReducer";
import store from "@web/app/store";
import type { DispatchProps } from "@web/common/Interfaces";
import Loader from "@web/common/Loader";
import { MutableComponent } from "@web/common/mutableComponent";
import Paths from "@web/common/Paths";
import GlobalErrorComponent from "@web/components/GlobalErrorComponent";
import { PublicTracking } from "@web/tracking/PublicTracking";
import { batch, connect } from "react-redux";
import { Route, Switch } from "react-router";

import Config from "../config/Config";
import Main from "./Main";

indexCss();
conversationCss();
componentsCss();
sidebarCss();
commonCss();
mapCss();

interface IProps {
	error: boolean;
}

interface IState {
	initialized: boolean;
}

class MainController extends MutableComponent<IProps & DispatchProps, IState> {
	//private didSetup = false;

	constructor(props: IProps & DispatchProps) {
		super(props);

		this.state = {
			initialized: false,
		};
		Configuration.init(Config);
		/*if (Config.somewear.featureOverrides) {
			props.dispatch(
				featuresSlice.actions.initFeaturesSlice(Config.somewear.featureOverrides.split(","))
			);
		}*/
		Sentry.init({
			dsn: Config.sentry.dsn,
			enabled: Config.sentry.enable,
			environment: Config.sentry.environment,
			integrations: [new Sentry.Integrations.Breadcrumbs({ console: false, dom: false })],
		});
		someGrpc.init(Config);
		Api.init(store);
		loadAuthService(Config).then((service) => {
			AuthController.init(service);
			this.setState({ initialized: true });
		});

		/*if (!this.didSetup) {
			try {
				firebase.messaging.usePublicVapidKey(Config.firebase.vapidKey);
			} catch (error) {
				console.log(error);
			}
		}
		this.didSetup = true;*/
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		if (error.name === "ChunkLoadError") {
			batch(() => {
				this.props.dispatch(setUpgrade(true));
				this.props.dispatch(setGlobalError());
			});
		} else {
			Sentry.withScope((scope) => {
				scope.setExtra("componentStack", errorInfo.componentStack);
				Sentry.captureException(error);
			});

			this.props.dispatch(setGlobalError());
		}
	}

	render(): React.ReactNode {
		if (this.props.error) {
			return <GlobalErrorComponent />;
		} else if (!this.state.initialized) {
			return <Loader fullScreen={true} />;
		} else {
			return (
				<Switch>
					<Route path={Paths.PublicTrackingMatch}>
						<PublicTracking />
					</Route>
					<Route>
						<Main />
					</Route>
				</Switch>
			);
		}
	}
}

function mapStateToProps(state: RootState): IProps {
	return { error: state.app.globalError };
}

export default connect(mapStateToProps)(MainController);
