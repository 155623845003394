import { combineReducers } from "redux";

import { biometricsSlice } from "./biometric.slice";

export const biometricReducerMap = {
	biometrics: biometricsSlice.reducer,
};

const reducers = combineReducers(biometricReducerMap);

export type BiometricState = ReturnType<typeof reducers>;
