import type {
	GetOrganizationIntegrationsResponse,
	GetOrganizationMembersResponse,
	IdentityRecord,
	InviteOrganizationMembersResponse,
	OrganizationMemberResponse,
	RemoveOrganizationMembersResponse,
	UpdateOrganizationRoleRequest,
	UpdateOrganizationRoleResponse,
} from "@somewear/api";
import type { IOrganizationAsset } from "@somewear/model";
import { createActionSet } from "@somewear/model";

export const organizationMemberActions = {
	assignRole: createActionSet<
		UpdateOrganizationRoleRequest.AsObject,
		UpdateOrganizationRoleResponse.AsObject
	>("organization/members/role/update"),
	addMembers: createActionSet<
		IdentityRecord.AsObject[],
		InviteOrganizationMembersResponse.AsObject
	>("organization/members/add"),
	getMembers: createActionSet<void, GetOrganizationMembersResponse.AsObject>(
		"organization/members/get",
	),
	getIntegrations: createActionSet<void, GetOrganizationIntegrationsResponse.AsObject>(
		"organization/integrations/get",
	),
	removeMembers: createActionSet<
		IOrganizationAsset[],
		RemoveOrganizationMembersResponse.AsObject
	>("organization/members/remove"),
	membershipChanged: createActionSet<void, OrganizationMemberResponse.AsObject>(
		"organization/members/changed",
	),
};
