import * as React from "react";
import styled from "styled-components";

type Props = {
	className?: string;
};

export const BetaCallout: React.FC<Props> = (props) => (
	<Border className={props.className}>
		<Label>beta</Label>
	</Border>
);

const Border = styled.div`
	display: block;
	padding: 5px 8px;

	background: rgba(35, 103, 246, 0.16);
	border: 1px solid #2367f6;
	border-radius: 3px;
	height: 26px;
`;

const Label = styled.div`
	display: block;
	font-family: var(--somewear-font);
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
	text-transform: uppercase;
	font-variant: small-caps;
	letter-spacing: 0.1em;
	color: #2367f6;
`;
