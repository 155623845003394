import type { MouseEventHandler } from "react";
import * as React from "react";

type IProps = {
	className?: string;
	onClick?: MouseEventHandler;
};

export const CloseIconComponent: React.FunctionComponent<IProps> = (props: IProps) => (
	<svg
		className={props.className}
		onClick={props.onClick}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 13 13"
	>
		<path d="M1 12.25L12.25 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M12.25 12.25L1 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);
