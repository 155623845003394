import type { IAuthService } from "@somewear/auth";
import { AbstractAuthController } from "@somewear/auth";

export class AuthController {
	static instance: AuthController;
	service: IAuthService;
	controller: AbstractAuthController;

	static init(service: IAuthService) {
		this.instance = new AuthController(service);
	}

	private constructor(service: IAuthService) {
		this.service = service;
		this.controller = new AbstractAuthController(service);
	}

	static get service() {
		return this.instance.service;
	}

	static get controller() {
		return this.instance.controller;
	}

	static get tokenString$() {
		return this.instance.controller.tokenString$;
	}

	static get service$() {
		return this.instance.controller.service$;
	}

	static get isServiceLoaded() {
		return this.instance.controller.isServiceLoaded;
	}
}
