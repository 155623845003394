import type { LocationResponse, RouteResponse, SosEventResponse } from "@somewear/api";
import { createActionSet } from "@somewear/model";

export const trackingLocationActions = {
	shareLocation: createActionSet<GeolocationPosition, LocationResponse.AsObject[]>(
		"tracking/locations/create",
	),
	shareWaypoint: createActionSet<GeolocationPosition, RouteResponse.AsObject>(
		"tracking/waypoints/create",
	),

	startSosSession: createActionSet<LocationResponse.AsObject, SosEventResponse.AsObject>(
		"tracking/sos/start",
	),
	resolveSosSession: createActionSet<void, SosEventResponse.AsObject>("tracking/sos/stop"),
};
