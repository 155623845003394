import * as React from "react";
import styled from "styled-components";

const Icon = styled.svg`
	fill: none;
`;

interface IProps {
	fill: string;
	width?: string;
	height?: string;
	className?: string;
}

export const AlertIconOutlinedComponent: React.FunctionComponent<IProps> = (props) => {
	return (
		<Icon
			className={props.className}
			viewBox="0 0 18 18"
			width={props.width ?? "20px"}
			height={props.height ?? "20px"}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.991 0C4.023 0 0 4.032 0 9C0 13.968 4.023 18 8.991 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 8.991 0ZM9.89995 4.5H8.09995V9.9H9.89995V4.5ZM9.89995 11.6999H8.09995V13.4999H9.89995V11.6999ZM1.80011 9.00011C1.80011 12.9781 5.02211 16.2001 9.00011 16.2001C12.9781 16.2001 16.2001 12.9781 16.2001 9.00011C16.2001 5.02211 12.9781 1.80011 9.00011 1.80011C5.02211 1.80011 1.80011 5.02211 1.80011 9.00011Z"
				fill={props.fill}
			/>
		</Icon>
	);
};
