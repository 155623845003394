import { combineReducers } from "redux";

import { apiKeysSlice } from "./apiKeys.slice";

export const apiKeysReducerMap = {
	apiKeys: apiKeysSlice.reducer,
};

const reducers = combineReducers(apiKeysReducerMap);

export type ApiKeysState = ReturnType<typeof reducers>;
