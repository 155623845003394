import type { Dictionary } from "@reduxjs/toolkit";
import type { Timestamp } from "@somewear/api";
import { AuthController } from "@somewear/auth";
import type { ITrackingFilters, ITrackingSettings, ReadTimestamps } from "@somewear/model";
import {
	Configuration,
	sanitizeObject,
	TRACKING_FILTER_DEFAULTS,
	TRACKING_SETTING_DEFAULTS,
} from "@somewear/model";
import { AbstractStore, StoreController, SyncController } from "@somewear/storage";
import CryptoJS from "crypto-js";
import Hashids from "hashids";
import { defaultsDeep } from "lodash";
import type { Observable } from "rxjs";
import { from } from "rxjs";
import { catchError, map } from "rxjs/operators";

const ENCRYPTION_SALT = "is-there-a-true-north";

interface UserData {
	timestamps: ReadTimestamps;
	filters: ITrackingFilters;
	trackingSettings: ITrackingSettings;
	workspaceFilters: Dictionary<boolean>;
}

class UserDataSyncStore extends AbstractStore<UserData> {
	constructor() {
		super(StoreController.USER_DATA_SYNC);
	}

	migrate() {}
	clean() {}
}

export class UserDataSyncClient {
	private static store: UserDataSyncStore;
	private static controller: SyncController<UserData>;

	private static getUserId(): string {
		const user = AuthController.service.getCurrentAuthUser();

		if (!user) {
			throw new Error("No logged in user");
		}

		return user.id;
	}

	private static getController(): SyncController<UserData> {
		if (!this.controller) {
			const { firebase } = Configuration.config;

			this.store = new UserDataSyncStore();
			this.controller = new SyncController<UserData>(
				this.store,
				firebase.enable,
				this.getUserId(),
				{
					apiKey: firebase.apiKey,
					authDomain: firebase.authDomain,
					databaseURL: firebase.databaseURL,
					projectId: firebase.projectId,
					storageBucket: firebase.storageBucket,
					messagingSenderId: firebase.messagingSenderId,
					appId: firebase.appId,
					vapidKey: firebase.vapidKey,
				},
			);
		}

		return this.controller;
	}

	private static getHashedId(): string {
		const hasher = new Hashids(ENCRYPTION_SALT);
		return hasher.encode(this.getUserId());
	}

	private static encryptCustomMapUrl(url: string): string {
		return CryptoJS.AES.encrypt(url, this.getHashedId()).toString();
	}

	private static decryptCustomMapUrl(encryptedUrl: string): string {
		return CryptoJS.AES.decrypt(encryptedUrl, this.getHashedId()).toString(CryptoJS.enc.Utf8);
	}

	static getReadTimestamps(): Observable<ReadTimestamps> {
		return from(this.getController().read("timestamps")).pipe(
			map((data) => data || { conversations: {}, workspaces: {} }),
			catchError((error) => {
				console.error(`Error fetching timestamps:`, error);
				return [{ conversations: {}, workspaces: {} }];
			}),
		);
	}

	static getTrackingFilters(): Observable<ITrackingFilters> {
		return from(this.getController().read("filters")).pipe(
			map((data) => data || TRACKING_FILTER_DEFAULTS),
			catchError((error) => {
				console.error(`Error fetching tracking filters:`, error);
				return [TRACKING_FILTER_DEFAULTS];
			}),
		);
	}

	static getTrackingSettings(): Observable<ITrackingSettings> {
		return from(this.getController().read("trackingSettings")).pipe(
			map((data) => {
				if (!data) return TRACKING_SETTING_DEFAULTS;

				const decryptedSettings = defaultsDeep(
					{},
					data,
					TRACKING_SETTING_DEFAULTS,
				) as ITrackingSettings;

				if (decryptedSettings.customMapUrl) {
					decryptedSettings.customMapUrl = this.decryptCustomMapUrl(
						decryptedSettings.customMapUrl,
					);
				}

				return decryptedSettings;
			}),
			catchError((error) => {
				console.error("Error fetching tracking settings:", error);
				return [TRACKING_SETTING_DEFAULTS];
			}),
		);
	}

	static getWorkspaceFilters(): Observable<Dictionary<boolean>> {
		return from(this.getController().read("workspaceFilters")).pipe(
			map((data) => data || {}),
			catchError((error) => {
				console.error(`Error fetching workspace filters:`, error);
				return [{}];
			}),
		);
	}

	static async setConversationTimestamp(
		id: string,
		timestamp: Timestamp.AsObject,
	): Promise<void> {
		await this.getController().write("timestamps", `conversations/${id}`, timestamp);
	}

	static async setWorkspaceTimestamp(id: string, timestamp: Timestamp.AsObject): Promise<void> {
		await this.getController().write("timestamps", `workspaces/${id}`, timestamp);
	}

	static async setTrackingFilters(filters: ITrackingFilters | undefined): Promise<void> {
		if (!filters) return;

		await this.getController().write("filters", "", filters);
	}

	static async setTrackingSettings(settings: ITrackingSettings): Promise<void> {
		const sanitizedSettings = sanitizeObject(settings);

		if (sanitizedSettings.customMapUrl) {
			sanitizedSettings.customMapUrl = this.encryptCustomMapUrl(
				sanitizedSettings.customMapUrl,
			);
		}

		await this.getController().write("trackingSettings", "", sanitizedSettings);
	}

	static async setWorkspaceFilters(filters: Record<string, boolean | undefined>): Promise<void> {
		await this.getController().write("workspaceFilters", "", filters);
	}
}
