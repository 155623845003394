import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { deviceActions } from "@somewear/device";
import type { IQueuedDeviceActivationChange } from "@somewear/model";

import type { SettingsState } from "./settings.state";

const adapter = createEntityAdapter<IQueuedDeviceActivationChange>({
	selectId: (change) => change.serial,
});

export const {
	selectAll: selectAllQueuedDeviceActivationChanges,
	selectById: selectQueuedDeviceActivationChangeBySerial,
	selectEntities: selectQueuedDeviceActivationChangeEntities,
} = adapter.getSelectors((state: SettingsState) => state.queuedDeviceActivationChanges);

export const queuedDeviceActivationChangeSlice = createSlice({
	name: "queuedDeviceActivations",
	initialState: adapter.getInitialState(),
	reducers: {
		queueActivationChange: (state, action: PayloadAction<IQueuedDeviceActivationChange>) => {
			adapter.upsertOne(state, action.payload);
		},
		removeAllChanges: (state) => {
			adapter.removeAll(state);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(deviceActions.applyQueuedActivationChanges.fulfilled, (state, action) => {
			const serials = action.payload.data;
			adapter.removeMany(state, serials);
		});
	},
});
