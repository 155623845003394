import type { Dictionary } from "@reduxjs/toolkit";
import { Timestamp } from "@somewear/api";
import { Message } from "google-protobuf";
import type { Moment } from "moment";
import moment from "moment/moment";

export const PageSize = 50;

function timestampToObject(input: Timestamp | Timestamp.AsObject): Timestamp.AsObject {
	return input instanceof Message ? input.toObject() : input;
}

export function timestampToDate(timestamp: Timestamp.AsObject): Date;
export function timestampToDate(timestamp: Timestamp): Date;

export function timestampToDate(input: Timestamp | Timestamp.AsObject) {
	const timestamp = timestampToObject(input);

	const seconds = timestamp.seconds ?? 0;
	const nanos = timestamp.nanos ?? 0;
	const mills = seconds * 1000 + nanos / 1000000;

	return new Date(mills);
}

export function timestampToMoment(timestamp: Timestamp.AsObject): Moment;
export function timestampToMoment(timestamp: Timestamp): Moment;

export function timestampToMoment(input: Timestamp | Timestamp.AsObject) {
	const timestamp = timestampToObject(input);

	const date = timestampToDate(timestamp);

	return moment(date);
}

export function timestampFromMoment(moment: moment.Moment): Timestamp {
	const timestamp = new Timestamp();
	timestamp.setSeconds(moment.unix());
	return timestamp;
}

export const shufff = (input: string, seed: number): string => {
	const strin = input.split("");
	let tem;
	let j;
	const tt = strin.length;
	for (let i = 0; i < tt; i++) {
		j = ((seed % (i + 1)) + i) % tt;
		tem = strin[i];
		strin[i] = strin[j];
		strin[j] = tem;
	}
	return strin.join("");
};

export const getDictionaryValue = <T>(dictionary: Dictionary<T>, key: string | undefined) => {
	if (key === undefined) return undefined;
	return dictionary[key];
};

export function sanitizeObject(obj: object) {
	return JSON.parse(JSON.stringify(obj));
}

export function nowToTimestamp(): Timestamp.AsObject {
	const timestamp = new Timestamp();
	const now = moment();
	timestamp.setSeconds(now.unix());
	return timestamp.toObject();
}

/**
 * A type guard that ensures a given entity is not null or undefined. Ensures that typings beneath
 * resolve to the entity and not (entity | null | undefined)
 */
export function isPopulated<T>(obj: T | null | undefined): obj is T {
	if (obj === null || obj === undefined) return false;
	return true;
}

/**
 * Namespacing utils that have conflicts with other libraries
 */
namespace SomewearUtils {
	/**
	 * Confirms if an entity is null and ensures typings beneath resolve to null.
	 *
	 * Conflicts with lodash
	 */
	export function isNull<T>(obj: T | null | undefined): obj is null {
		if (obj === null) return true;
		return false;
	}

	/**
	 * Confirms if an entity is undefined and ensures typings beneath resolve to undefined.
	 *
	 * Conflicts with lodash
	 */
	export function isUndefined<T>(obj: T | null | undefined): obj is undefined {
		if (obj === undefined) return true;
		return false;
	}
}

export { SomewearUtils as someUtils };
