import { createAction } from "@reduxjs/toolkit";
import type {
	ArchiveMembersResponse,
	GetAssetsResponse,
	InviteMembersRequest,
	InviteMembersResponse,
	UpdateWorkspaceAccount,
	UpdateWorkspaceAccountsResponse,
	UpdateWorkspaceRoleRequest,
	UpdateWorkspaceRoleResponse,
	UserResponse,
} from "@somewear/api";
import type { IWorkspaceAsset } from "@somewear/model";
import { createActionSet } from "@somewear/model";

export const assetActions = {
	add: createActionSet<InviteMembersRequest.AsObject, InviteMembersResponse.AsObject>(
		"workspace/members/add",
	),
	remove: createActionSet<string[], ArchiveMembersResponse.AsObject>("workspace/members/remove"),
	update: createActionSet<
		UpdateWorkspaceAccount.AsObject,
		UpdateWorkspaceAccountsResponse.AsObject
	>("workspace/members/update"),
	assignRole: createActionSet<
		UpdateWorkspaceRoleRequest.AsObject,
		UpdateWorkspaceRoleResponse.AsObject
	>("workspace/members/role/assign"),
	fetchAssets: createActionSet<void, GetAssetsResponse.AsObject>("workspace/assets/fetch"),
};

export const emitUserAccountChangeFromServer = createAction<IWorkspaceAsset>(
	"emitUserAccountChangeFromServer",
);

export const emitAddUserAccountFromServer = createAction<UserResponse.AsObject>(
	"emitAddUserAccountFromServer",
);

export const emitAssetAccountsDeleted = createAction<IWorkspaceAsset[]>("emitAssetAccountsDeleted");

export const emitActiveUserAccountDeleted = createAction<IWorkspaceAsset>(
	"emitActiveUserAccountDeleted",
);
