import type { GetOrganizationDevicesResponse } from "@somewear/api";
import { grpc, someGrpc } from "@somewear/grpc";
import type { ActionSetEpic } from "@somewear/model";
import { createActionSetEpicHandler } from "@somewear/model";
import { combineEpics } from "redux-observable";

import { organizationDeviceActions } from "./organizationDeviceActions";

const getOrganizationDevicesEpic: ActionSetEpic<void, GetOrganizationDevicesResponse.AsObject> = (
	action$,
	state$,
) => {
	return createActionSetEpicHandler(action$, state$, organizationDeviceActions.getDevices, () =>
		grpc.prepareRequest(someGrpc.fetchOrganizationDevices),
	);
};

export const organizationDeviceEpics = combineEpics(getOrganizationDevicesEpic);
