import type {
	CreateConversationRequest,
	CreateConversationResponse,
	DeleteConversationResponse,
	GetConversationsResponse,
} from "@somewear/api";
import { createActionSet } from "@somewear/model";

export const conversationActions = {
	fetch: createActionSet<void, GetConversationsResponse.AsObject>("conversations/fetch"),
	create: createActionSet<
		CreateConversationRequest.AsObject,
		CreateConversationResponse.AsObject
	>("conversations/create"),
	delete: createActionSet<string, DeleteConversationResponse.AsObject>("conversations/delete"),
};
