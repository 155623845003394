import { SosEventType } from "@somewear/api";
import { timestampToMoment } from "@somewear/model";
import moment from "moment";
import { createSelector } from "reselect";

import { selectAllSosEvents } from "./sos.slice";

/*export const sosEventsSelector = (state: RootState) => {
	return state.app.sosEvents;
};*/

export const selectActiveSosEvents = createSelector([selectAllSosEvents], (sosEvents) => {
	/*return Object.values(sosEvents)
		.mapNotNull((events) => _.maxBy(events, (event) => event.timestamp!.seconds))
		.filter((event) => {
			return (
				event.type === SosEventType.SOSEVENTTYPEALARM &&
				timestampToMoment(event.timestamp!).isAfter(moment().subtract(1, "day"))
			);
		});*/
	// the slice only stores the most recent event for an sos session
	return sosEvents.filter((event) => {
		return (
			event.type === SosEventType.SOSEVENTTYPEALARM &&
			timestampToMoment(event.timestamp!).isAfter(moment().subtract(1, "day"))
		);
	});
});

export const selectActiveSosUsers = createSelector([selectActiveSosEvents], (events) => {
	const set = new Set<string>();
	events.forEach((event) => set.add(event.userId));
	return set;
});

export const selectActiveSosSessions = createSelector([selectActiveSosEvents], (events) => {
	return events.map((event) => event.sessionId);
});
