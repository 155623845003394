import type { CoordinateDto } from "@somewear/api";
import { SubscriptionRequest, Timestamp } from "@somewear/api";
import type { IClientLocation } from "@somewear/model";
import { AssetState, Sentry } from "@somewear/model";
import { PERSONAL_WORKSPACE_NAME } from "@somewear/workspace";
import { ACTIVE_TRACKING_TIMEOUT } from "@web/tracking/trackingSlice";
import type { ArgumentArray } from "classnames";
import classNames from "classnames";
import type { Point } from "geojson";
import _ from "lodash";
import type { LngLatLike } from "mapbox-gl";
import * as mgrsFormatter from "mgrs";
import type { Moment } from "moment";
import moment from "moment";
import { createSelectorCreator, lruMemoize } from "reselect";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ArgumentArray) {
	return twMerge(classNames(inputs));
}

export function formatTimestamp(seconds: number) {
	return moment(seconds * 1000).format("MM/D/YY, HH:mm");
}

export function formatTime(seconds: number) {
	return moment(seconds * 1000).format("HH:mm");
}

export function addToWindow<T>(key: string, value: T) {
	type SomeWindow<T> = typeof window & { [key: string]: T };

	(window as SomeWindow<T>)[key] = value;
}

export function modifyState<T>(prev: T, next: Partial<T>): T {
	return Object.assign({}, prev, next);
}

function isPoint(coordinate: CoordinateDto.AsObject | Point | undefined): coordinate is Point {
	return (coordinate as Point)?.type === "Point";
}

export function toLngLat(coordinate: CoordinateDto.AsObject | undefined): LngLatLike | undefined;
export function toLngLat(coordinate: Point | undefined): LngLatLike | undefined;

export function toLngLat(
	coordinate: CoordinateDto.AsObject | Point | undefined,
): LngLatLike | undefined {
	if (coordinate === undefined) return undefined;
	if (isPoint(coordinate)) {
		// zero index is longitude, one index is latitude
		return [coordinate.coordinates[0], coordinate.coordinates[1]];
	} else {
		return [coordinate.longitude, coordinate.latitude];
	}
}

export function formatLocation(
	coordinate: CoordinateDto.AsObject | undefined,
	mgrs = false,
): string {
	if (coordinate === undefined) {
		Sentry.captureException("formatLocation: coordinate is undefined");
		return "";
	}

	if (coordinate.latitude === undefined) {
		Sentry.captureException("formatLocation: latitude is undefined");
		return "";
	}

	if (coordinate.longitude === undefined) {
		Sentry.captureException("formatLocation: longitude is undefined");
		return "";
	}

	if (mgrs) {
		return mgrsFormatter.forward([coordinate.longitude, coordinate.latitude]);
	} else {
		return coordinate.latitude.toFixed(6) + "°, " + coordinate.longitude.toFixed(6) + "°";
	}
}

export function formatClientLocation(
	coordinate: IClientLocation | undefined,
	mgrs = false,
): string {
	if (coordinate === undefined) {
		Sentry.captureException("formatClientLocation: coordinate is undefined");
		return "";
	}

	if (coordinate.lat === undefined) {
		Sentry.captureException("formatClientLocation: latitude is undefined");
		return "";
	}

	if (coordinate.lng === undefined) {
		Sentry.captureException("formatClientLocation: longitude is undefined");
		return "";
	}

	if (mgrs) {
		return mgrsFormatter.forward([coordinate.lng, coordinate.lat]);
	} else {
		return coordinate.lat.toFixed(6) + "°, " + coordinate.lng.toFixed(6) + "°";
	}
}

export function dateToMonthYear(date: Date): string {
	return moment(date).format("MMM D, YYYY");
}

export function dateToMonthDayYear(date: Date) {
	return moment(date).format("MM/D/YY");
}

export function timestampToMonthYear(timestamp: Timestamp.AsObject): string {
	return dateToMonthDayYear(Date.DateFromTimestamp(timestamp));
}

export function timestampToString(timestamp: Timestamp.AsObject, showTime = true): string {
	const time = showTime ? ", HH:mm" : "";
	return moment(Date.DateFromTimestamp(timestamp)).format(
		`MMM D${showTime ? "" : ","} YYYY${time}`,
	);
}

export function timestampToUniversalString(
	timestamp: Timestamp.AsObject,
	showTime?: boolean,
	showSeconds?: boolean,
): string {
	const time = showTime ? " | HH:mm" : "";
	const seconds = showSeconds ? ":ss" : "";
	return moment(Date.DateFromTimestamp(timestamp)).format(`YYYY-MM-DD${time}${seconds}`);
}

export const isGreaterThanZero = (input: number | undefined) => {
	return input !== undefined && input > 0;
};

export function createTimestamp(timestamp: Timestamp.AsObject): string {
	//check if message if older than a day or a year
	const messageDate = moment(Date.DateFromTimestamp(timestamp));
	if (messageDate.isSameOrAfter(moment().startOf("day"))) {
		return moment(Date.DateFromTimestamp(timestamp)).format("HH:mm");
	} else if (messageDate.isSameOrAfter(moment().startOf("week"))) {
		return moment(Date.DateFromTimestamp(timestamp)).format("dddd");
	} else if (messageDate.isSameOrAfter(moment().startOf("year"))) {
		return moment(Date.DateFromTimestamp(timestamp)).format("MMM D");
	} else {
		return moment(Date.DateFromTimestamp(timestamp)).format("MM/D/YY");
	}
}

export function createLocationTimestamp(
	timestamp: Timestamp.AsObject | undefined,
	showSeconds?: boolean,
) {
	if (timestamp === undefined) {
		Sentry.captureException("createLocationTimestamp: timestamp is undefined");
		return "";
	}

	const seconds = showSeconds ? ":ss" : "";
	const locationDate = moment(Date.DateFromTimestamp(timestamp));
	if (locationDate.isSameOrAfter(moment().startOf("day"))) {
		return "Today " + moment(Date.DateFromTimestamp(timestamp)).format(` HH:mm${seconds}`);
	} else if (locationDate.isSameOrAfter(moment().startOf("week"))) {
		return moment(Date.DateFromTimestamp(timestamp)).format(`dddd HH:mm${seconds}`);
	} else if (locationDate.isSameOrAfter(moment().startOf("year"))) {
		return moment(Date.DateFromTimestamp(timestamp)).format(`YYYY-MM-DD HH:mm${seconds}`);
	} else {
		return moment(Date.DateFromTimestamp(timestamp)).format(`YYYY-MM-DD HH:mm${seconds}`);
	}
}

export function createLocationTimestampCondensed(timestamp: Timestamp.AsObject) {
	const locationDate = moment(Date.DateFromTimestamp(timestamp));
	if (locationDate.isSameOrAfter(moment().startOf("day"))) {
		return "Today " + moment(Date.DateFromTimestamp(timestamp)).format("HH:mm");
	} else if (locationDate.isSameOrAfter(moment().startOf("week"))) {
		return moment(Date.DateFromTimestamp(timestamp)).format("ddd HH:mm");
	} else if (locationDate.isSameOrAfter(moment().startOf("year"))) {
		return moment(Date.DateFromTimestamp(timestamp)).format("MMM DD HH:mm");
	} else {
		return moment(Date.DateFromTimestamp(timestamp)).format("YYYY-MM-DD HH:mm");
	}
}

export function createSosTimestamp(timestamp?: Timestamp.AsObject): string {
	if (!timestamp) return "";
	const locationDate = moment(Date.DateFromTimestamp(timestamp));
	if (locationDate.isSameOrAfter(moment().startOf("day"))) {
		return moment(Date.DateFromTimestamp(timestamp)).format("HH:mm");
	} else {
		return moment(Date.DateFromTimestamp(timestamp)).format("MM/D/YY, HH:mm");
	}
}

export function createSeparatorTimestamp(timestamp: Timestamp.AsObject): string {
	//check if message if older than a day or a year
	const messageDate = moment(Date.DateFromTimestamp(timestamp));
	if (messageDate.isSameOrAfter(moment().startOf("day"))) {
		return moment(Date.DateFromTimestamp(timestamp)).format("[Today]");
	} else if (messageDate.isSameOrAfter(moment().startOf("year"))) {
		return moment(Date.DateFromTimestamp(timestamp)).format("dddd, MMMM Do");
	} else {
		return moment(Date.DateFromTimestamp(timestamp)).format("MMMM Do, YYYY");
	}
}

export function createReportedTimestamp(date: Moment): string {
	return date.format("MM/DD/YY | HH:mm:ss");
}

export function nowToTimestamp(): Timestamp.AsObject {
	const timestamp = new Timestamp();
	const now = moment();
	timestamp.setSeconds(now.unix());
	return timestamp.toObject();
}

export function nowTimestamp(): string {
	return createTimestamp(nowToTimestamp());
}

//TODO: figure how to make this an extension
export function isAnnualPlan(
	plan: SubscriptionRequest.PlanMap[keyof SubscriptionRequest.PlanMap],
): boolean {
	switch (plan) {
		case SubscriptionRequest.Plan.PLAN1Y:
		case SubscriptionRequest.Plan.PLAN2Y:
		case SubscriptionRequest.Plan.PLAN3Y:
		case SubscriptionRequest.Plan.PLAN4Y:
			return true;

		default:
			return false;
	}
}

interface ITimestamp {
	timestamp?: Timestamp.AsObject;
}

export function sortByTimestamp<T extends ITimestamp>(a: T, b: T): number {
	const aSeconds = a.timestamp!.seconds;
	const bSeconds = b.timestamp!.seconds;
	return aSeconds - bSeconds;
}

export function sortByTimestampDesc<T extends ITimestamp>(a: T, b: T): number {
	const aSeconds = a.timestamp!.seconds;
	const bSeconds = b.timestamp!.seconds;
	return bSeconds - aSeconds;
}

export function calculateUserState(timestamp: moment.Moment, forceActive?: boolean): AssetState {
	const now = moment();
	if (forceActive === true) {
		return AssetState.Active;
	} else if (timestamp.isAfter(now.subtract(ACTIVE_TRACKING_TIMEOUT, "minutes"))) {
		return AssetState.Active;
	} else if (timestamp.isAfter(now.subtract(1, "hour"))) {
		return AssetState.Stale;
	} else {
		return AssetState.Old;
	}
}

export function isUserLive(timestamp: moment.Moment): boolean {
	return timestamp.isAfter(moment().subtract(1, "hour"));
}

export function isAssetActive(timestamp: moment.Moment): boolean {
	return timestamp.isAfter(moment().subtract(ACTIVE_TRACKING_TIMEOUT, "minutes"));
}

export function getLineDistance(latlng1: mapboxgl.LngLat, latlng2: mapboxgl.LngLat): number {
	// Uses spherical law of cosines approximation.
	const R = 6371000;

	const rad = Math.PI / 180,
		lat1 = latlng1.lat * rad,
		lat2 = latlng2.lat * rad,
		a =
			Math.sin(lat1) * Math.sin(lat2) +
			Math.cos(lat1) * Math.cos(lat2) * Math.cos((latlng2.lng - latlng1.lng) * rad);

	return R * Math.acos(Math.min(a, 1));
}

export function toFahrenheit(celsius: number): number {
	return celsius * (9 / 5) + 32;
}

/*export const getDisplayNameForContact = (asset?: Contact) => {
	return asset !== undefined ? asset.displayName.replaceIfEmpty(asset.email) : "";
};

export const getDisplayNameForWorkspaceAsset = (asset?: IWorkspaceAsset) => {
	return asset !== undefined ? asset.fullname.replaceIfEmpty(asset.email) : "";
};

export const getDisplayNameForOrganizationAsset = (asset?: IOrganizationAsset) => {
	return asset !== undefined ? asset.fullName.replaceIfEmpty(asset.email) : "";
};*/

export const shufff = (input: string, seed: number): string => {
	const strin = input.split("");
	let tem;
	let j;
	const tt = strin.length;
	for (let i = 0; i < tt; i++) {
		j = ((seed % (i + 1)) + i) % tt;
		tem = strin[i];
		strin[i] = strin[j];
		strin[j] = tem;
	}
	return strin.join("");
};

export const hexToRgba = (hex: string, alpha: string): string => {
	const r = parseInt(hex.slice(1, 3), 16),
		g = parseInt(hex.slice(3, 5), 16),
		b = parseInt(hex.slice(5, 7), 16);

	if (alpha) {
		return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
	} else {
		return "rgb(" + r + ", " + g + ", " + b + ")";
	}
};

export const getLettersForAvatar = (input: string): string => {
	if (input.toLowerCase() === PERSONAL_WORKSPACE_NAME.toLowerCase()) return "";
	const words = input.trim().split(" ");
	if (words.length > 1) {
		return words[0].substring(0, 1).toUpperCase() + words[1].substring(0, 1).toUpperCase();
	} else if (words.length === 1) {
		return words[0].substring(0, 2);
	} else {
		console.warn("trying to get avatar for word without text");
		return "";
	}
};

export const createDeepEqualSelector = createSelectorCreator(lruMemoize, _.isEqual);

/**
 * A type guard that ensures a given entity is not null or undefined. Ensures that typings beneath
 * resolve to the entity and not (entity | null | undefined)
 */
export function isPopulated<T>(obj: T | null | undefined): obj is T {
	if (obj === null || obj === undefined) return false;
	return true;
}

export function generatePassword(length: number): string {
	const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	const lowercase = "abcdefghijklmnopqrstuvwxyz";
	const digits = "0123456789";
	const symbols = '!@#$%^&*()_+{}:"<>?[]/~';
	const allCharacters = uppercase + lowercase + digits + symbols;

	let password = "";

	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * allCharacters.length);
		password += allCharacters[randomIndex];
	}

	return password;
}

/**
 * Namespacing utils that have conflicts with other libraries
 */
namespace SomewearUtils {
	/**
	 * Confirms if an entity is null and ensures typings beneath resolve to null.
	 *
	 * Conflicts with lodash
	 */
	export function isNull<T>(obj: T | null | undefined): obj is null {
		if (obj === null) return true;
		return false;
	}

	/**
	 * Confirms if an entity is undefined and ensures typings beneath resolve to undefined.
	 *
	 * Conflicts with lodash
	 */
	export function isUndefined<T>(obj: T | null | undefined): obj is undefined {
		if (obj === undefined) return true;
		return false;
	}
}

export { SomewearUtils as someUtils };
