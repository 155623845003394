import { combineReducers } from "redux";

import { notificationsSlice } from "./notificationsSlice";

export const notificationReducerMap = {
	notifications: notificationsSlice.reducer,
};

const reducers = combineReducers(notificationReducerMap);

export type NotificationState = ReturnType<typeof reducers>;
