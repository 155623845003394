import { combineReducers } from "redux";

import { sensorsSlice } from "./sensors.slice";

export const sensorReducerMap = {
	sensors: sensorsSlice.reducer,
};

const reducers = combineReducers(sensorReducerMap);

export type SensorState = ReturnType<typeof reducers>;
