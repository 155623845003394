import type { OptionalBoolMap } from "@somewear/api";
import { OptionalBool } from "@somewear/api";

import type { ValueOf } from "../common/valueOf";

export type OptionalBoolValue = ValueOf<OptionalBoolMap>;

export function toOptionalBool(value: boolean | undefined): OptionalBoolValue {
	if (value === undefined) return OptionalBool.OPTIONALBOOLNONE;
	return value ? OptionalBool.OPTIONALBOOLTRUE : OptionalBool.OPTIONALBOOLFALSE;
}

export function optionalBoolEnabled(value?: OptionalBoolValue): boolean {
	if (!value) return false;

	return value === OptionalBool.OPTIONALBOOLTRUE;
}
