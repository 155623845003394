import { combineReducers } from "redux";

import authSlice from "./auth.slice";

export const authReducerMap = {
	auth: authSlice.reducer,
};

const reducers = combineReducers(authReducerMap);

export type AuthState = ReturnType<typeof reducers>;
