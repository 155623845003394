import { z } from "zod";

type LiteralWithAtLeastTwoValues<T> = [
	z.ZodLiteral<T[keyof T]>,
	z.ZodLiteral<T[keyof T]>,
	...z.ZodLiteral<T[keyof T]>[]
];

export function protoToZodEnum<T extends { [K in keyof T]: number }>(protoEnum: T) {
	const literals = (Object.values(protoEnum) as number[]).map((value) => z.literal(value));

	if (literals.length < 2) {
		throw new Error("Enum must have at least 2 values for union");
	}

	return z.union(literals as LiteralWithAtLeastTwoValues<T>);
}
