import { combineReducers } from "redux";

import { messagingSlice } from "./messagingSlice";

export const messagingReducerMap = {
	messaging: messagingSlice.reducer,
};

const reducers = combineReducers(messagingReducerMap);

export type MessagingState = ReturnType<typeof reducers>;
