import { combineReducers } from "redux";

import { organizationsSlice } from "./organizations.slice";

export const organizationReducerMap = {
	organizations: organizationsSlice.reducer,
};

const reducers = combineReducers(organizationReducerMap);

export type OrganizationState = ReturnType<typeof reducers>;
