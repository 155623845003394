import type { Action, Reducer } from "redux";

export type LoadingStateType = {
	[id: string]: boolean;
};

/*
Keeps track of loading/done states for api calls
https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
 */
export const loadingReducer: Reducer<LoadingStateType, Action<string>> = (state = {}, action) => {
	const { type } = action;
	const matches = /(.*)(request|success|error|fulfilled|rejected)/.exec(type.toLowerCase());

	if (!matches) return state;

	const [, requestName, requestState] = matches;

	return {
		...state,
		[requestName]: requestState.toLowerCase() === "request",
	};
};
