import { combineReducers } from "redux";

import { mapLayersSlice } from "./mapLayers.slice";

export const layerReducerMap = {
	mapLayers: mapLayersSlice.reducer,
};

const reducers = combineReducers(layerReducerMap);

export type LayerState = ReturnType<typeof reducers>;
