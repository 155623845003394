import type { EffectCallback } from "react";
import { useEffect } from "react";

/**
 * Convenience hook to run an effect only once on the first
 * render, without getting yelled at by React for not including
 * all dependencies.
 */
export function useOnMount(effect: EffectCallback) {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(effect, []);
}
