import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type RedirectState = {
	path: string;
};

const initialState: RedirectState = {
	path: "/",
};

export const redirectSlice = createSlice({
	name: "redirect",
	initialState,
	reducers: {
		setRedirectPath(state, action: PayloadAction<string>) {
			state.path = action.payload;
		},
	},
});

export const { setRedirectPath } = redirectSlice.actions;
