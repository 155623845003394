// Define the properties used in the Map Layer's "Uploading" component.
export interface UploadingFileProps {
	fileName: string;
	loading: boolean;
	errored: boolean;
}

export interface MapLayersFileDropComponentProps {
	state: number;
	onUploadFiles: (files: FileList | null) => void;
}

// Define the properties of the Map Layer's master "Controller" component.
export interface MapLayersControllerProps {
	state: number;
	uploadingFile: string;
}

// Define the values used by the MapLayersControllerProps "state" property.
export enum MapLayersControllerState {
	Default = 1,
	DraggingOver = 2,
	Dropping = 4,
	Uploading = 8,
	Uploaded = 16,
	Errored = 32,
}

export enum MapLayersFileDropComponentState {
	Default = 1,
	DraggingOver = 2,
	Dropping = 4,
}

export enum MapModalTypes {
	Biometrics = "Biometrics",
	Coordinates = "Coordinates",
	Shapes = "Shapes",
	Markers = "Markers",
	EditShape = "EditShape",
	EditAsset = "EditAsset",
	Measure = "Measure",
	Layers = "Layers",
}
