import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { emitAssetAccountsDeleted } from "@somewear/asset";
import type { ConversationOrWorkspace, IMessage } from "@somewear/model";
import { clearWorkspace } from "@somewear/workspace";
import _ from "lodash";

import { conversationActions } from "./conversation";
import type { MessagesState } from "./messages.state";
import type { MessageResponseWithState } from "./messaging";
import { emitMessageReceivedFromStream, messageActions } from "./messaging";

const adapter = createEntityAdapter<IMessage>({
	selectId: (entity) => entity.id,
	sortComparer: (a, b) => {
		const aSeconds = a.timestamp!.seconds;
		const bSeconds = b.timestamp!.seconds;
		return aSeconds - bSeconds;
	},
});

// Rename the exports for readability in component usage
export const { selectAll: selectAllMessages, selectById: selectMessageById } = adapter.getSelectors(
	(state: MessagesState) => state.messages,
);

export interface IGetMessagesResponse {
	conversationInfo: ConversationOrWorkspace;
	messages: IMessage[];
}

export const messagesSlice = createSlice({
	name: "messages",
	initialState: adapter.getInitialState(),
	reducers: {
		removeMessageById: (state, action) => {
			adapter.removeOne(state, action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(messageActions.get.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.messages);
		});
		builder.addCase(messageActions.send.pending, (state, action) => {
			const message = _.cloneDeep(action.payload.data) as MessageResponseWithState;
			message.id = action.payload.requestId;
			message.state = "pending";
			adapter.upsertOne(state, message);
		});
		builder.addCase(messageActions.send.fulfilled, (state, action) => {
			// remove the pending message
			adapter.removeOne(state, action.payload.requestId);
			const message = _.cloneDeep(action.payload.data) as MessageResponseWithState;
			message.state = "success";
			adapter.upsertOne(state, message);
		});
		builder.addCase(messageActions.send.rejected, (state, action) => {
			// remove the pending message
			const pendingMessage = adapter
				.getSelectors()
				.selectById(state, action.payload.requestId)! as MessageResponseWithState;
			const message = _.cloneDeep(pendingMessage) as MessageResponseWithState;
			message.state = "error";
			adapter.upsertOne(state, message);
		});
		builder.addCase(emitMessageReceivedFromStream, (state, action) => {
			adapter.upsertOne(state, action.payload);
		});
		/*builder.addCase(apiConversationsSuccess, (state, action) => {
			// add the message to the messages entity for that conversation

			const latestMessages: MessageResponse.AsObject[] = action.payload
				.map((conversation) => conversation.mostRecentMessage)
				.filter((message) => message !== undefined) as MessageResponse.AsObject[];

			adapter.upsertMany(state, latestMessages);
		});*/
		builder.addCase(conversationActions.create.request, (state, action) => {});
		builder.addCase(conversationActions.create.fulfilled, (state, action) => {
			const message = action.payload.data.message;
			if (message !== undefined) {
				adapter.upsertOne(state, message);
			}
		});
		builder.addCase(conversationActions.delete.request, (state, action) => {
			const conversationId = action.payload.data;
			const allMessages = adapter.getSelectors().selectAll(state);
			const messageIds = allMessages
				.filter((message) => message.conversationId === conversationId)
				.map((message) => message.id);
			adapter.removeMany(state, messageIds);
		});
		builder.addCase(conversationActions.fetch.fulfilled, (state, action) => {
			const messages = action.payload.data.conversationsList.mapNotNull(
				(conv) => conv.mostRecentMessage,
			);
			adapter.upsertMany(state, messages);
		});
		builder.addCase(clearWorkspace, (state, action) => {
			const deletedMessageIds = adapter
				.getSelectors()
				.selectAll(state)
				.filter((it) => it.workspaceId === action.payload)
				.map((it) => it.id);
			adapter.removeMany(state, deletedMessageIds);
		});
		builder.addCase(emitAssetAccountsDeleted, (state, action) => {
			const deletedAccountIds = action.payload.map((it) => it.id);
			const deletedMessageIds = adapter
				.getSelectors()
				.selectAll(state)
				.filter((it) => deletedAccountIds.includes(it.id))
				.map((it) => it.id);
			adapter.removeMany(state, deletedMessageIds);
		});
	},
});
