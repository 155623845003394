import type { IShape } from "@somewear/shapes";

import type { IKmlLink, IKmlNetworkLink, IKmlScreenOverlay, IKmlStyle } from "./kml.model";

interface LayerState {
	visible: boolean;
	loading: boolean;
}

// Define the Map Layer interface.
export interface MapLayerState extends LayerState {
	removed?: boolean;
}

export enum MapLayerType {
	Kml,
	Shape,
	Raster,
}

// Define the properties of a Map Layer.
export interface MapLayer {
	id: string;
	fileName: string;
	index: number;
	order: number;
	uploadDate?: string;
	state: MapLayerState;
	data?: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
	shape?: IShape;
	styles?: IKmlStyle[];
	overlays?: IKmlScreenOverlay[];
	links?: IKmlNetworkLink[];
	srcLink?: IKmlLink;
	fromPublic?: boolean;
	type: MapLayerType;
}

// Define the custom type of an array of Map Layers.
export type MapLayers = MapLayer[];

// Define the properties of the Map Layer's "Modal" container component.
export interface MapLayersModalProps {
	state: LayerState;
	onUploadFiles: (files: FileList | null) => void;
	type: MapLayerType;
}

export const getMapLayerKey = (layer: MapLayer): string => `${layer.id}-${layer.index}`;
