import type { StyleSettings } from "@somewear/api";

export interface DateRange {
	start: number;
	end: number;
	set: boolean;
}

export enum MapStyle {
	Satellite = "satellite-streets-v11",
	Outdoors = "outdoors-v11",
}

export interface ITrackingFilters {
	showActive: boolean;
	showInactive: boolean;
	showUnassignedResources: boolean;
	showAssigned: boolean;
	onlyShowLatest: boolean;
}

export enum WaypointEcosystem {
	SOMEWEAR,
	TAK,
}

interface IShareWaypointSettings {
	isShareWithWorkspace?: boolean;
	workspaceId?: string;
	affiliation?: StyleSettings.AffiliationMap[keyof StyleSettings.AffiliationMap];
	dimension?: StyleSettings.DimensionMap[keyof StyleSettings.DimensionMap];
	ecosystem?: WaypointEcosystem;
}

export interface ITrackingSettings {
	useCustomMapUrl: boolean;
	useMetric: boolean;
	showImageOverlays?: boolean;
	customMapUrl?: string;
	shareWaypointSettings?: IShareWaypointSettings;
	mapStyle?: MapStyle;
	useBetaMap?: boolean;
	useWorkspaceColor?: boolean;
	disableStale?: boolean;
	cleanTracks?: boolean;
	clusterByWorkspace?: boolean;
	hideMarkers?: boolean;
}

export const TRACKING_FILTER_DEFAULTS: ITrackingFilters = {
	showActive: true,
	showInactive: false,
	showUnassignedResources: false,
	showAssigned: false,
	onlyShowLatest: true,
};

export const TRACKING_SETTING_DEFAULTS: ITrackingSettings = {
	useCustomMapUrl: false,
	useMetric: false,
	showImageOverlays: true,
	shareWaypointSettings: {},
	mapStyle: MapStyle.Outdoors,
	useBetaMap: false,
	useWorkspaceColor: true,
	disableStale: false,
	cleanTracks: undefined,
	clusterByWorkspace: undefined,
};

export enum AssetState {
	Active = "active",
	Stale = "stale",
	Old = "old",
	Danger = "danger",
	Warning = "warning",
	Sos = "sos",
	Unassigned = "unassigned",
}
