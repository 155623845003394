import { combineReducers } from "redux";

import { waypointsSlice } from "./waypoints.slice";

export const waypointReducerMap = {
	waypoints: waypointsSlice.reducer,
};

const reducers = combineReducers(waypointReducerMap);

export type WaypointState = ReturnType<typeof reducers>;
