import type { IdentityTypeMap, LocationResponse } from "@somewear/api";
import { IdentityType } from "@somewear/api";
import type { ITrackingFilters } from "@somewear/model";
import { AssetState } from "@somewear/model";

export namespace AssetStateHelpers {
	export const getDefaultVisibility = (
		userState: AssetState,
		filters: ITrackingFilters,
		isLiveTracking: boolean,
		hasDevice: boolean,
		type: IdentityTypeMap[keyof IdentityTypeMap] | undefined,
		mostRecentLocation: LocationResponse.AsObject | undefined,
	): boolean => {
		const isActive = isUserActive(userState);

		let defaultVisibility = true;
		if (isLiveTracking && !isActive) {
			defaultVisibility = false;
		}
		if (!filters.showInactive && !isActive) {
			defaultVisibility = false;
		}

		if (!filters.showActive && isActive) {
			defaultVisibility = false;
		}
		if (
			!filters.showUnassignedResources &&
			!hasDevice && // currently not assigned to a device
			mostRecentLocation?.deviceId !== undefined && // last location had a device
			type === IdentityType.RESOURCE
		) {
			defaultVisibility = false;
		}

		if (!filters.showAssigned && !hasDevice && type === IdentityType.DEVICE) {
			defaultVisibility = false;
		}
		return defaultVisibility;
	};

	export const isUserVisible = (
		userId: string,
		userState: AssetState,
		hasDevice: boolean,
		trackingFilters: ITrackingFilters,
		isLiveTracking: boolean,
		hiddenUsers: string[],
		shownUsers: string[],
		type: IdentityTypeMap[keyof IdentityTypeMap] | undefined,
		mostRecentLocation: LocationResponse.AsObject | undefined,
	): boolean => {
		const defaultVisibility = getDefaultVisibility(
			userState,
			trackingFilters,
			isLiveTracking,
			hasDevice,
			type,
			mostRecentLocation,
		);
		const visibilityOverride = getVisibilityOverride(userId, hiddenUsers, shownUsers);

		let isVisible = defaultVisibility;
		if (defaultVisibility) {
			// if the override is undefined don't change the default
			isVisible = visibilityOverride === false ? false : defaultVisibility;
		} else {
			// if the override is undefined don't change the default
			isVisible = visibilityOverride === true ? true : defaultVisibility;
		}

		return isVisible;
	};

	export const isUserActive = (userState: AssetState): boolean => {
		return (
			userState === AssetState.Active ||
			userState === AssetState.Stale ||
			userState === AssetState.Warning ||
			userState === AssetState.Danger ||
			userState === AssetState.Sos
		);
	};

	export const getVisibilityOverride = (
		userId: string,
		hiddenUsers: string[],
		shownUsers: string[],
	): boolean | undefined => {
		let visibilityOverride: boolean | undefined = undefined;
		if (hiddenUsers.includes(userId)) {
			visibilityOverride = false;
		} else if (shownUsers.includes(userId)) {
			visibilityOverride = true;
		}
		return visibilityOverride;
	};
}
