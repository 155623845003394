import type { IDirectConversationEntity, IWorkspaceConversationEntity } from "./conversation.model";

export function isWorkspaceConversation(conversation?: {
	workspaceId?: string;
}): conversation is IWorkspaceConversationEntity {
	return Boolean(conversation?.workspaceId);
}

export function isDirectMessageConversation(conversation?: {
	conversationId?: string;
}): conversation is IDirectConversationEntity {
	return Boolean(conversation?.conversationId);
}

export function getConversationKey(entity: {
	workspaceId?: string;
	participantId?: string;
}): string {
	return isWorkspaceConversation(entity)
		? "w_" + entity.workspaceId
		: "c_" + entity.participantId;
}
