import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { LocationResponse } from "@somewear/api";
import { emitAssetAccountsDeleted } from "@somewear/asset";
import type { RootState } from "@web/app/rootReducer";
import { getLocationsFulfilled } from "@web/tracking/trackingActions";
import { apiLocationsSuccess } from "@web/tracking/trackingSlice";

import { trackingRouteActions } from "../routes/trackingRouteActions";
import { trackingLocationActions } from "./trackingLocationActions";

const adapter = createEntityAdapter<LocationResponse.AsObject>({
	selectId: (entity) => entity.id,
	sortComparer: (a, b) => (a.timestamp?.seconds ?? 0) - (b.timestamp?.seconds ?? 0),
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllLocations,
	selectById: selectLocationsById,
	selectEntities: selectLocationEntities,
} = adapter.getSelectors((state: RootState) => state.locations);

const slice = createSlice({
	name: "locations",
	initialState: adapter.getInitialState(),
	reducers: {
		_: (state) => {},
	},
	extraReducers: (builder) => {
		builder.addCase(trackingRouteActions.getLastKnown.fulfilled, (state, action) => {
			const locations = action.payload.data.mapNotNull((route) => route.location);
			adapter.upsertMany(state, locations);
		});
		builder.addCase(trackingRouteActions.getLive.fulfilled, (state, action) => {
			const locations = action.payload.data.mapNotNull((route) => route.location);
			adapter.upsertMany(state, locations);
		});
		builder.addCase(trackingRouteActions.getLocations.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data);
		});
		builder.addCase(trackingRouteActions.downloadLocations.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data);
		});
		builder.addCase(trackingLocationActions.shareLocation.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data);
		});
		builder.addCase(trackingRouteActions.getRoutes.fulfilled, (state, action) => {
			const locations = action.payload.data.mapNotNull((route) => {
				if (route.location === undefined) return undefined;
				return { ...route.location, userId: route.ownerId };
			});
			adapter.upsertMany(state, locations);
		});
		builder.addCase(apiLocationsSuccess, (state, action) => {
			adapter.upsertMany(state, action.payload);
		});
		builder.addCase(getLocationsFulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload);
		});
		builder.addCase(emitAssetAccountsDeleted, (state, action) => {
			// when an account is deleted, delete the related data
			const deletedAccountIds = action.payload.map((it) => it.id);
			const deletedItems = adapter
				.getSelectors()
				.selectAll(state)
				.filter((it) => deletedAccountIds.includes(it.userId));
			adapter.removeMany(
				state,
				deletedItems.map((it) => it.id),
			);
		});
	},
});

export default slice;
