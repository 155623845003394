import { combineReducers } from "redux";

import { identitiesSlice } from "./identitiesSlice";

export const identityReducerMap = {
	identities: identitiesSlice.reducer,
};

const reducers = combineReducers(identityReducerMap);

export type IdentityState = ReturnType<typeof reducers>;
