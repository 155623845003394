import * as React from "react";
import styled from "styled-components";

interface IProps {
	className?: string;
}

const Icon = styled.svg`
	animation: spin 2.2s infinite linear;
	fill: ${(props) => props.theme.colors.main};
	height: 16px;
	transform: rotate(0deg);
	transform-origin: center center;
	width: 12px;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export const AutoRenewIconComponent: React.FunctionComponent<IProps> = (props) => {
	return (
		<Icon className={props.className ?? ""} viewBox="0 0 12 16">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.81818 5.81818V3.63636C3.41091 3.63636 1.45455 5.59273 1.45455 8C1.45455 8.73454 1.63636 9.43273 1.96364 10.0364L0.901818 11.0982C0.334545 10.2036 0 9.14182 0 8C0 4.78545 2.60364 2.18182 5.81818 2.18182V0L8.72727 2.90909L5.81818 5.81818ZM9.67273 5.96381L10.7345 4.90199C11.3018 5.79653 11.6364 6.85835 11.6364 8.00017C11.6364 11.2147 9.03273 13.8184 5.81818 13.8184V16.0002L2.90909 13.0911L5.81818 10.182V12.3638C8.22545 12.3638 10.1818 10.4074 10.1818 8.00017C10.1818 7.26562 9.99273 6.57472 9.67273 5.96381Z"
			/>
		</Icon>
	);
};
