import { createAction } from "@reduxjs/toolkit";
import type { DeleteRouteRequest, GetWaypointsResponse, RouteResponse } from "@somewear/api";
import type { ISaveWaypointPayload } from "@somewear/model";
import { createActionSet } from "@somewear/model";

export const waypointActions = {
	fetch: createActionSet<void, GetWaypointsResponse.AsObject>("waypoints/fetch"),
	saveWaypoint: createActionSet<ISaveWaypointPayload, RouteResponse.AsObject>(
		"tracking/waypoints/save",
	),
	deleteWaypoint: createActionSet<string, DeleteRouteRequest.AsObject>(
		"tracking/waypoints/delete",
	),
	apiRoutesSuccess: createAction<RouteResponse.AsObject[]>("apiRoutesSuccess"),
};
