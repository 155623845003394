import jwt_decode from "jwt-decode";

import type { IAuthUser } from "../AuthUtil";

export interface IUser {
	uid: string;
	name: string;
	email?: string;
	username?: string;
	phone?: string;
	isAnonymous: boolean;
	hasWorkspace: boolean;
	hasOrganization: boolean;
}

export type TokenClaims = {
	apiClient: string;
	externalName: string;
	uid: string;
};

export type AuthRecordV1 = {
	token: string;
	uid: string;
};

export type AuthRecordV2 = {
	accessToken: string;
	refreshToken: string;
	accessExpiration: number;
	uid: string;
};

export type AuthRecord = AuthRecordV1 | AuthRecordV2;

export function isRecordV1(input: AuthRecord | undefined): input is AuthRecordV1 {
	if (input === undefined) return false;
	return (input as AuthRecordV1).token !== undefined;
}

export function isRecordV2(input: AuthRecord | undefined): input is AuthRecordV2 {
	if (input === undefined) return false;
	return (input as AuthRecordV2).accessToken !== undefined;
}

export type AuthModelV1 = {
	token: string;
	user: IAuthUser;
};

export type AuthModelV2 = {
	accessToken: string;
	refreshToken: string;
	accessExpiration: number;
	user: IAuthUser;
};

export type AuthModel = AuthModelV1 | AuthModelV2;

export function isModelV1(input: AuthModel | undefined): input is AuthModelV1 {
	if (input === undefined) return false;
	return (input as AuthModelV1).token !== undefined;
}

export function isModelV2(input: AuthModel | undefined): input is AuthModelV2 {
	if (input === undefined) return false;
	return (input as AuthModelV2).accessToken !== undefined;
}

export function convertRecordToModel(authModel: AuthRecord | undefined): AuthModel | undefined {
	if (isRecordV1(authModel)) {
		const decoded = jwt_decode<TokenClaims>(authModel.token);
		return {
			token: authModel.token,
			user: {
				id: decoded.uid,
				displayName: decoded.externalName,
				username: decoded.externalName,
				isAnonymous: false,
			},
		};
	} else if (isRecordV2(authModel)) {
		const decoded = jwt_decode<TokenClaims>(authModel.accessToken);
		return {
			accessToken: authModel.accessToken,
			refreshToken: authModel.refreshToken,
			accessExpiration: authModel.accessExpiration,
			user: {
				id: decoded.uid,
				displayName: decoded.externalName,
				username: decoded.externalName,
				isAnonymous: false,
			},
		};
	} else {
		return undefined;
	}
}
