import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { apiKeysActions } from "./apiKeys.actions";
import type { ApiKey } from "./apiKeys.model";
import type { ApiKeysState } from "./apiKeys.state";

const adapter = createEntityAdapter<ApiKey>({
	selectId: (event) => event.id,
});

export const {
	selectAll: selectAllOrganizationApiKeys,
	selectById: selectOrganizationApiKeysById,
	selectEntities: selectOrganizationApiKeysEntities,
} = adapter.getSelectors((state: ApiKeysState) => state.apiKeys);

export const apiKeysSlice = createSlice({
	name: "apiKeys",
	initialState: adapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(apiKeysActions.getOrganizationApiKeys.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.apiClientsList);
		});
		builder.addCase(apiKeysActions.createOrganizationApiKey.fulfilled, (state, action) => {
			const { apiClient } = action.payload.data;

			if (apiClient) {
				adapter.upsertOne(state, apiClient);
			}
		});
		builder.addCase(apiKeysActions.deleteOrganizationApiKey.fulfilled, (state, action) => {
			const deletedId = action.payload.requestData?.apiClientId;

			if (deletedId) {
				adapter.removeOne(state, deletedId);
			}
		});
	},
});
