import type { CreateBeamAuthRequest, IntegrationResponse } from "@somewear/api";
import { IdentityRecord } from "@somewear/api";

import type { IIdentity } from "./identity.model";
import type { IIntegrationIdentity } from "./integration.model";

export interface WebhookIntegrationRequest extends IntegrationResponse.Webhook.AsObject {
	name: string;
}

export interface FederatedTAKServerIntegrationRequest
	extends IntegrationResponse.FederatedTakServer.AsObject {
	name: string;
}

export interface TAKServerIntegrationRequest
	extends Pick<
		IntegrationResponse.TakServer.AsObject,
		| "clientCert"
		| "clientCertPassword"
		| "serverCert"
		| "serverCertPassword"
		| "udp"
		| "host"
		| "port"
	> {
	name: string;
	beamAuth?: CreateBeamAuthRequest.AsObject;
}

export type CreateIntegrationRequest =
	| WebhookIntegrationRequest
	| FederatedTAKServerIntegrationRequest;

export function isWebhookIntegrationRequest(
	request: CreateIntegrationRequest
): request is WebhookIntegrationRequest {
	return (request as WebhookIntegrationRequest).url !== undefined;
}

export function isFederatedTakServerIntegrationRequest(
	request: CreateIntegrationRequest
): request is FederatedTAKServerIntegrationRequest {
	return (request as FederatedTAKServerIntegrationRequest).serverCert !== undefined;
}

export function identityIsIntegration(identity: IIdentity): identity is IIntegrationIdentity {
	return identity.type === IdentityRecord.Type.INTEGRATION;
}
