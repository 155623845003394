import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { OrganizationRecord } from "@somewear/api";
import type { IOrganizationAsset } from "@somewear/model";
import _ from "lodash";

import { organizationActions } from "./organization.actions";
import type { OrganizationState } from "./organization.state";

export const getDisplayNameForOrganizationAsset = (asset?: IOrganizationAsset) => {
	return asset !== undefined ? asset.fullName.replaceIfEmpty(asset.email) : "";
};

export interface IOrganization extends OrganizationRecord.AsObject {
	hostedEditBillingUrl?: string;
}

const adapter = createEntityAdapter<IOrganization>({
	selectId: (org) => org.id,
});

// Rename the exports for readability in component usage
export const { selectAll: selectAllOrganizations, selectEntities: selectOrganizationEntities } =
	adapter.getSelectors((state: OrganizationState) => state.organizations);

export const workspaceResourceActions: object = {};

export const organizationsSlice = createSlice({
	name: "organizations",
	initialState: adapter.getInitialState(),
	reducers: {
		emitOrganizationsReceived(state, action: PayloadAction<OrganizationRecord.AsObject[]>) {
			adapter.upsertMany(state, action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(organizationActions.getBillingInfo.fulfilled, (state, action) => {
			const orgId = action.payload.requestId.split("|").last();
			const orig = adapter.getSelectors().selectById(state, orgId);
			if (orig === undefined) return;

			const copy = _.cloneDeep(orig);
			copy.hostedEditBillingUrl = action.payload.data.hostedEditBillingUrl;
			adapter.upsertOne(state, copy);
		});
	},
});
