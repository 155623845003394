import type { IConfig } from "./IConfig";

export class Configuration {
	private static instance: Configuration;
	private config: IConfig;

	static init(config: IConfig) {
		this.instance = new Configuration(config);
	}

	private constructor(config: IConfig) {
		this.config = config;
	}

	static get config() {
		return this.instance.config;
	}
}
