import type {
	ClaimOrganizationRequest,
	ConfirmOrganizationClaimCodeResponse,
	CreateOrganizationIntegrationRequest,
	CreateOrganizationIntegrationResponse,
	CreateOrganizationResourceResponse,
	CreateOrganizationTakServerRequest,
	CreateOrganizationWorkspaceRequest,
	CreateOrganizationWorkspaceResponse,
	GetDeviceUsageResponse,
	GetOrganizationBillingInfoResponse,
	GetOrganizationDataUsageForDevicesResponse,
	GetOrganizationIdentitiesResponse,
	GetOrganizationIntegrationAccountsResponse,
	GetOrganizationLicenseSummaryResponse,
	GetOrganizationWorkspacesResponse,
	IdentityRecord,
	InviteMembersResponse,
	OrganizationRecord,
	RemoveOrganizationIntegrationResponse,
	SendCotToTakServerResponse,
	UpdateOrganizationMemberPasswordRequest,
	UpdateOrganizationMemberPasswordResponse,
	UpdateWorkspaceAccessResponse,
} from "@somewear/api";
import type { CreateIntegrationRequest, IntegrationConfig } from "@somewear/model";
import { createActionSet } from "@somewear/model";

export const organizationActions = {
	getLicense: createActionSet<void, GetOrganizationLicenseSummaryResponse.AsObject>(
		"organization/license/get",
	),
	getWorkspaces: createActionSet<void, GetOrganizationWorkspacesResponse.AsObject>(
		"organization/workspaces/get",
	),
	getAssets: createActionSet<void, GetOrganizationIdentitiesResponse.AsObject>(
		"organization/identities/get",
	),
	createWorkspace: createActionSet<
		CreateOrganizationWorkspaceRequest.AsObject,
		CreateOrganizationWorkspaceResponse.AsObject
	>("organization/workspaces/create"),
	removeWorkspace: createActionSet<string, UpdateWorkspaceAccessResponse.AsObject>(
		"organization/workspaces/remove",
	),
	deleteWorkspace: createActionSet<string, UpdateWorkspaceAccessResponse.AsObject>(
		"organization/workspaces/delete",
	),
	updateMember: createActionSet<IdentityRecord.AsObject, any>("organization/identities/update"),
	joinWorkspace: createActionSet<string, InviteMembersResponse.AsObject>(
		"organization/workspace/join",
	),
	getDeviceUsage: createActionSet<string, GetDeviceUsageResponse.AsObject>(
		"organization/device/usage/get",
	),
	getAllDeviceUsage: createActionSet<void, GetOrganizationDataUsageForDevicesResponse.AsObject>(
		"organization/devices/usage/get",
	),
	getBillingInfo: createActionSet<undefined, GetOrganizationBillingInfoResponse.AsObject>(
		"organization/recurly/subscription/get",
	),
	createIntegration: createActionSet<
		CreateIntegrationRequest,
		CreateOrganizationIntegrationRequest.AsObject
	>("organization/integration/create"),
	createTakIntegration: createActionSet<
		CreateOrganizationTakServerRequest.AsObject,
		CreateOrganizationIntegrationResponse.AsObject
	>("organization/integration/create/tak"),
	removeIntegration: createActionSet<string, RemoveOrganizationIntegrationResponse.AsObject>(
		"organization/integration/remove",
	),
	configureIntegration: createActionSet<
		IntegrationConfig,
		GetOrganizationIntegrationAccountsResponse.AsObject
	>("organization/integration/configure"),
	getIntegrationAccounts: createActionSet<
		void,
		GetOrganizationIntegrationAccountsResponse.AsObject
	>("organization/integration/accounts/get"),
	createResource: createActionSet<string, CreateOrganizationResourceResponse.AsObject>(
		"organization/resources/create",
	),
	confirmCode: createActionSet<string, ConfirmOrganizationClaimCodeResponse.AsObject>(
		"organization/claim/confirm",
	),
	claim: createActionSet<ClaimOrganizationRequest.AsObject, OrganizationRecord.AsObject>(
		"organization/claim/redeem",
	),
	changeUserPassword: createActionSet<
		UpdateOrganizationMemberPasswordRequest.AsObject,
		UpdateOrganizationMemberPasswordResponse.AsObject
	>("organization/assets/password/update"),
	sendCotToTakServer: createActionSet<
		{ identityId: string; cot: string },
		SendCotToTakServerResponse.AsObject
	>("organization/integration/sendCot"),
};
