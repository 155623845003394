import { setActiveWorkspaceId } from "@somewear/auth";
import { setFilteredWorkspaceId, WORKSPACE_ID_ALL } from "@somewear/messaging";
import { organizationActions } from "@somewear/organization";
import { refreshDetailedAppData } from "@web/app/appSlice";
import { resetDateFilter, resetWorkspaceFilters } from "@web/tracking/trackingSlice";
import type { Action } from "redux";
import type { Epic } from "redux-observable";
import { combineEpics } from "redux-observable";
import { filter, mergeMap } from "rxjs/operators";

const joinOrganizationWorkspaceFulfilledEpic: Epic<Action> = (actions$) =>
	actions$.pipe(
		filter(organizationActions.joinWorkspace.fulfilled.match),
		mergeMap((response) => {
			return [
				setActiveWorkspaceId(response.payload.data.usersList.first().workspaceId),
				refreshDetailedAppData(),
				resetDateFilter(),
				resetWorkspaceFilters(),
				setFilteredWorkspaceId(WORKSPACE_ID_ALL),
			];
		}),
	);

export default combineEpics(joinOrganizationWorkspaceFulfilledEpic);
