import { combineReducers } from "redux";

import { redirectSlice } from "../app/redirectSlice";
import { errorReducer } from "./errorReducer";
import { loadingReducer } from "./loadingReducer";

export const modelReducerMap = {
	loading: loadingReducer,
	error: errorReducer,
	redirect: redirectSlice.reducer,
};

const modelReducers = combineReducers(modelReducerMap);

export type ModelState = ReturnType<typeof modelReducers>;
