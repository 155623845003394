import type { PayloadAction } from "@reduxjs/toolkit";
import type { Reducer } from "redux";

export interface IError {
	message: string;
}

export interface OptionalError {
	error?: IError;
}

export type ErrorStateType = {
	[id: string]: OptionalError | undefined;
};

/*
Keeps track of error states for api calls
https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
 */
export const errorReducer: Reducer<ErrorStateType, PayloadAction<OptionalError>> = (
	state = {},
	action
) => {
	const { type, payload } = action;
	const matches = /(.*)(Request|Error)/.exec(type);

	if (!matches) return state;

	const [, requestName, requestState] = matches;

	return {
		...state,
		[requestName]: requestState === "Error" ? payload : undefined,
	};
};
