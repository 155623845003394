import * as React from "react";

import logo from "../img/newLogoAndText.svg";

interface IText {
	text: string;
}

interface IBackground {
	children: React.ReactNode;
}

const Title: React.FunctionComponent<IText> = (text: IText) => (
	<div className="content-title">{text.text}</div>
);

const Header: React.FunctionComponent<IText> = (text: IText) => (
	<div className="content-header">{text.text}</div>
);

const Background: React.FunctionComponent<IBackground> = (props: IBackground) => (
	<div className="setup-background">
		<div className="setup-header">
			<img className="setup-header-logo" alt="logo" src={logo} />
		</div>
		<div className="setup-background-content">{props.children}</div>
	</div>
);

export { Title };
export { Header };
export { Background };
