import { combineReducers } from "redux";

import { contactsSlice } from "./contactsSlice";

export const contactReducerMap = {
	contacts: contactsSlice.reducer,
};

const reducers = combineReducers(contactReducerMap);

export type ContactState = ReturnType<typeof reducers>;
