import { createAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type {
	GetOrganizationIntegrationAccountsResponse,
	IntegrationResponse,
	UserResponse,
} from "@somewear/api";
import { IntegrationConnection } from "@somewear/api/src/proto/integration_proto_pb";
import type { IIdentity, IWorkspaceAsset } from "@somewear/model";
import { Configuration, identityIsIntegration, nowToTimestamp } from "@somewear/model";
import _ from "lodash";

import {
	assetActions,
	emitAddUserAccountFromServer,
	emitUserAccountChangeFromServer,
} from "../asset";
import { identityActions } from "./identity.actions";
import type { IdentityState } from "./identity.state";

export type IntegrationType =
	GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.IntegrationTypeMap[keyof GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.IntegrationTypeMap];

type IntegrationData = {
	webhook?: IntegrationResponse.Webhook.AsObject;
	takServer?: IntegrationResponse.TakServer.AsObject;
};

export const getDisplayNameForIdentity = (asset: IIdentity, isSelf: boolean) => {
	if (isSelf) return "Me";
	return asset !== undefined
		? asset.fullName.replaceIfEmpty(
				Configuration.config.firebase.enable
					? asset.email
					: (asset.username ?? asset.email),
			)
		: "";
};

const adapter = createEntityAdapter<IIdentity>({
	selectId: (identity) => identity.id,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllIdentities,
	selectById: selectIdentityById,
	selectEntities: selectIdentityEntities,
} = adapter.getSelectors((state: IdentityState) => state.identities);

export const identitiesSlice = createSlice({
	name: "identities",
	initialState: adapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(assetActions.fetchAssets.fulfilled, (state, action) => {
			const identities = action.payload.data.identitiesList.mapNotNull(
				(identity) => identity,
			);
			adapter.upsertMany(state, identities);
		});

		builder.addCase(emitIdentityChangeFromServer, (state, action) => {
			adapter.upsertOne(state, action.payload);
		});

		builder.addCase(identityActions.updateIdentityStyle.fulfilled, (state, action) => {
			const identityId = action.payload.data.id;
			const identity = state.entities[identityId];
			if (identity === undefined) return; // this is not the first we should be hearing of an identity
			const copy = _.cloneDeep(identity);
			copy.styleSettings = action.payload.data.styleSettings;
			adapter.upsertOne(state, copy);
		});

		builder.addCase(emitUserAccountChangeFromServer, (state, action) => {
			const account = action.payload;
			const identity = accountToIdentity(account);
			if (identity === undefined) return;

			adapter.upsertOne(state, identity);
		});
		builder.addCase(emitAddUserAccountFromServer, (state, action) => {
			const account = action.payload;
			const identity = accountToIdentity(account);
			if (identity === undefined) return;

			adapter.upsertOne(state, identity);
		});
		builder.addCase(emitBulkUpsertIdentity, (state, action) => {
			adapter.upsertMany(state, action.payload);
		});
		builder.addCase(identityActions.updateIntegrationConnectedState, (state, action) => {
			const identity = state.entities[action.payload.integrationIdentityId];
			if (identity === undefined || !identityIsIntegration(identity)) return;

			const currentTimestamp = nowToTimestamp();

			if (action.payload.status === IntegrationConnection.Status.CONNECTED) {
				identity.lastConnected = currentTimestamp;
			} else {
				identity.lastDisconnected = currentTimestamp;
			}

			adapter.upsertOne(state, identity);
		});
	},
});

const accountToIdentity = (account: UserResponse.AsObject | IWorkspaceAsset) => {
	// removed this as identity type is unreliable when part of a user account
	/*let type: IdentityRecord.TypeMap[keyof IdentityRecord.TypeMap] | undefined;
	switch (account.type) {
		case IdentityType.USER:
			type = IdentityRecord.Type.USER;
			break;
		case IdentityType.RESOURCE:
			type = IdentityRecord.Type.RESOURCE;
			break;
		case IdentityType.DEVICE:
			type = IdentityRecord.Type.DEVICE;
			break;
		case IdentityType.INTEGRATION:
			type = IdentityRecord.Type.INTEGRATION;
			break;
	}
	if (type === undefined) return undefined;*/
	return {
		id: account.identityId,
		fullName: account.fullname,
		// type: type,
	} as IIdentity;
};

export const emitIdentityChangeFromServer = createAction<IIdentity>("emitIdentityChangeFromServer");

export const emitBulkUpsertIdentity = createAction<IIdentity[]>("identity/upsert/bulk");
export const emitBulkRemoveIdentity = createAction<IIdentity[]>("identity/remove/bulk");
