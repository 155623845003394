import { combineReducers } from "redux";

import { queuedDeviceActivationChangeSlice } from "./queuedDeviceActivationChangeSlice";
import { settingsSlice } from "./settingsSlice";

export const settingsReducerMap = {
	settings: settingsSlice.reducer,
	queuedDeviceActivationChanges: queuedDeviceActivationChangeSlice.reducer,
};

const settingReducers = combineReducers(settingsReducerMap);

export type SettingsState = ReturnType<typeof settingReducers>;
